import React, { useContext } from 'react';
import { Box } from '@mui/material';
import WinManagerContext from '../../providers/winManagerProvider';

const WindowsManager = () => {
  const { linkComponents, currentURL } = useContext(WinManagerContext);
  return (
    <Box
      className="windows-manager"
      id="body"
      sx={{ height: 1 }}
    >
      {linkComponents.map((el) => {
        const Comp = el.component;
        return (
          <Box
            key={`windowed-component-of-${el.url}`}
            sx={{ display: el.url === currentURL ? 'block' : 'none', height: 1 }}
          >
            <Comp
              {...el.props}
              search={el.fullUrl}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default WindowsManager;
