import React, { useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { faComment as icon } from '@fortawesome/free-solid-svg-icons';
import { Overlay, Popover } from 'react-bootstrap';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

const StatusButton = ({
  disabled, onClick, label, status, onClose,
}) => {
  const ref = useRef();
  return (
    <>
      <DefaultCPButton
        icon={icon}
        onClick={onClick}
        label={label}
        disabled={disabled}
        ref={ref}
      />
      <Overlay
        show={status.mode === 1}
        placement="bottom"
        target={ref.current}
        onHide={onClose}
        rootClose
      >
        <Popover id="ApproveStatusPopover">
          {Object.keys(status.results).filter((key) => status.results[key].status).map((key) => (
            <Fragment key={key}>
              <Popover.Title>
                {status.results[key].repr}
              </Popover.Title>
              <Popover.Content>
                <ul>
                  {status.results[key].status.map((s, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={i} className="">
                      {s}
                    </li>
                  ))}
                </ul>
              </Popover.Content>
            </Fragment>
          ))}
        </Popover>
      </Overlay>
    </>
  );
};

StatusButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  status: PropTypes.shape({
    mode: PropTypes.oneOf([1, 2]),
    results: PropTypes.shape({}),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

StatusButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Статус',
};

export default StatusButton;
