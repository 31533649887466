import personCard from './personCard';
import financing from './financing';
import indicatorsStartYear from './indicatorsStartYear';
import changeToStaffList from './changeToStaffList';
import calcNightAndHoliday from './calcNightAndHoliday';
import calcTarifList from './calcTarifList';

export { personCard, financing };

const documents = {
  personCard,
  financing,
  indicatorsStartYear,
  changeToStaffList,
  calcNightAndHoliday,
  calcTarifList,
};

export const doc1c = new Proxy({}, {
  get(target, prop) {
    const doc = Object.keys(documents)
      .filter((d) => documents[d].backendName.toLowerCase() === prop.toLowerCase());
    if (doc.length === 1) return documents[doc[0]];
    throw new Error('Property {prop} not found in documents!');
  },
});

export default documents;
