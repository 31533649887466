const PeriodSelection = {
  Today: { name: 'Today', label: 'Сьогодні' },
  ThisWeek: { name: 'ThisWeek', label: 'Цей тиждень' },
  ThisMonth: { name: 'ThisMonth', label: 'Цей місяць' },
  ThisQuarter: { name: 'ThisQuarter', label: 'Цей квартал' },
  ThisYear: { name: 'ThisYear', label: 'Цей рік' },
  FromBeginningOfThisYear: { name: 'FromBeginningOfThisYear', label: 'З початку цього року' },
  Custom: { name: 'Custom', label: 'Обрати період' },
};

export default PeriodSelection;
