import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { modelType, modelName } from '../def';
import CsuGroup from './editor';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorHeader from '../../../newEditor/header';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import CatEditorCommandPanel from '../../../newEditor/commandPanels/catalog';

const CsuGroupEditor = ({
                          id,
                          search,
                          onClose,
                          onSave,
                        }) => {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
  });
  return (
      <>
        <EditorHeader
            onSaveNClose={actions.onSaveNExit}
            isChanged={changed}
            onClose={actions.onClose}
            header={data.repr}
        />
        <CatEditorCommandPanel
            id={id}
            changed={changed}
            onClose={onClose}
            permissions={permissions}
            actions={actions}
        />
        <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onErr}>

          <Card.Header>
            <CsuGroup data={data} actions={actions} permissions={permissions} />
          </Card.Header>
        </EditorWrapper>
      </>
  );
};

CsuGroupEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

CsuGroupEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export default CsuGroupEditor;
