import React from 'react';
import PropTypes from 'prop-types';
import CommandPanelButton from './commandPanelButton';

const GenerateButton = ({
  disabled, text, onClick, ...args
}) => (
  <CommandPanelButton
    onClick={onClick}
    {...args}
    text={text}
    disabled={disabled}
  />
);

GenerateButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

GenerateButton.defaultProps = {
  text: 'Сформувати',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default GenerateButton;
