import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { responsiblePerson as md } from '../../../../constants/meta/catalogs/responsiblePerson';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const ResponsiblePerson = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Surname.label}
            value={data[md.columns.Surname.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Surname.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Name1.label}
            value={data[md.columns.Name1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Name1.name]: value,
            })}
            maxLength={180}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Name2.label}
            value={data[md.columns.Name2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Name2.name]: value,
            })}
            maxLength={180}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Post.label}
            value={data[md.columns.Post.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Post.name]: value,
            })}
            maxLength={400}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Budget.label}
            value={data[md.columns.Budget.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Budget.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Owner.label}
            value={data[md.columns.Owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Owner.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
};

ResponsiblePerson.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ResponsiblePerson;
