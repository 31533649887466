import React from 'react';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import UseMenuHeader from '../hooks/useMenuHeader';

const CatalogsMenuItem = () => (
  <UseMenuHeader label="Довідники">
    <Boxes items={NavPanel.catalogs.boxes} />
  </UseMenuHeader>
);

export default CatalogsMenuItem;
