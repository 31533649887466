import Doctablerow from './doctablerow';
import Cattablerow from './cattablerow';
import Calctablerow from './calctablerow';
import Regtablerow from './regtablerow';

export {
  Doctablerow,
  Cattablerow,
  Regtablerow,
  Calctablerow,
};

const ListerRows = {
  Doctablerow,
  Cattablerow,
  Regtablerow,
  Calctablerow,
};

export default ListerRows;
