import {
  useMemo, useState, useCallback, useEffect, useRef,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useWinManager = () => {
  const [linkComponents, setLinkComponents] = useState([]);
  // Порядок окон для переключения (след, пред)
  const ws = useRef([]);
  const [listersTimeStamps, setListersTimeStamps] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const currentURL = useMemo(() => location.pathname, [location.pathname]);
  const refedCurrentUrl = useRef(null);
  useEffect(
    () => {
      refedCurrentUrl.current = currentURL;
    },
    [currentURL],
  );
  const oldUrl = useRef();

  const setCurrentURL = useCallback(
    (newUrl) => {
      if (newUrl !== refedCurrentUrl.current) navigate(newUrl, { replace: true });
    },
    [navigate],
  );
  const addComponentToWindowsManager = useCallback(
    (newComponent, newURL, url, newWindowTitle, newProps) => {
      const newComp = {
        title: newWindowTitle,
        component: newComponent,
        props: newProps,
        url: newURL,
        fullUrl: url,
      };
      setLinkComponents((oldComp) => [...oldComp, newComp]);
    },
    [],
  );
  useEffect(
    () => {
      const ou = oldUrl.current;
      if (currentURL !== oldUrl.current) {
        oldUrl.current = currentURL;
        ws.current = [
          currentURL,
          ...(ou ? [ou] : []),
          ...ws.current.filter((u) => !([currentURL, ou].includes(u))),
        ];
      }
    },
    [currentURL],
  );

  useEffect(
    () => {
      const knowComp = (linkComponents.map((lc) => lc.url));
      ws.current = [
        ...ws.current.filter((w) => knowComp.includes(w)),
        ...knowComp.filter((w) => !ws.current.includes(w)),
      ];
    },
    [linkComponents],
  );
  /**
   * Получает следующее или предыдущее окно
   * @type {function(number): string}
   */
  const getNewPath = useCallback(
    (stepWin = 0) => {
      const idx = linkComponents.reduce((R, r, i) => (
        refedCurrentUrl.current === r.url ? i : R), null);

      if (idx === null) return '';
      if (stepWin > 0) {
        return linkComponents.length > idx + stepWin
          ? linkComponents[idx + stepWin].url : linkComponents[0].url;
      }
      return idx + stepWin >= 0
        ? linkComponents[idx + stepWin].url : linkComponents[linkComponents.length - 1].url;
    },
    [linkComponents],
  );

  const switchWindow = useCallback(
    (switchURL) => {
      if (refedCurrentUrl.current !== switchURL) setCurrentURL(switchURL);
    },
    [setCurrentURL],
  );

  const { currentComponent, currentProps } = useMemo(
    () => linkComponents.filter((l) => l.url === refedCurrentUrl.current).reduce((R, r) => ({
      currentComponent: r.component,
      currentProps: r.props,
    }), {}),
    [linkComponents],
  );

  const dellComponentFromWindowsManager = useCallback(
    (url = null) => {
      const delUrl = (url === null ? refedCurrentUrl.current : url);
      // Переключаем окошки только в том случае если закрываем текущее окно
      if (refedCurrentUrl.current === delUrl) {
        switchWindow(
          ws.current.filter((w) => w !== delUrl).slice(0, 1).reduce((R, r) => r, '/'),
        );
      }
      setLinkComponents((o) => o.filter((lc) => lc.url !== delUrl));
    },
    [switchWindow],
  );

  const nextWindow = useCallback(
    () => {
      const newPath = getNewPath(1);
      switchWindow(newPath);
    },
    [getNewPath, switchWindow],
  );

  const prevWindow = useCallback(
    () => {
      const newPath = getNewPath(-1);
      switchWindow(newPath);
    },
    [getNewPath, switchWindow],
  );

  // Посылает сигнал на обновление данных компоненту, зарегесрированному по указанному url
  const sendUpdateSignal = useCallback(
    (url) => {
      setListersTimeStamps((old) => ({ ...old, [url]: new Date().valueOf() }));
    },
    [],
  );

  // Посылает сигнал на обновление данных компоненту, зарегесрированному по указанному url
  const setWindowTitle = useCallback(
    (url, title) => {
      setLinkComponents((old) => old.map((lk) => (lk.url === url ? ({ ...lk, title }) : lk)));
    },
    [],
  );

  const wmValue = useMemo(
    () => ({
      linkComponents,
      currentComponent,
      currentProps,
      currentURL,
      getNewPath,
      dellComponentFromWindowsManager,
      switchWindow,
      addComponentToWindowsManager,
      listersTimeStamps,
      nextWindow,
      prevWindow,
      sendUpdateSignal,
      setWindowTitle,
    }),
    [addComponentToWindowsManager, currentComponent, currentProps, currentURL,
      dellComponentFromWindowsManager, getNewPath, linkComponents, listersTimeStamps,
      nextWindow, prevWindow, sendUpdateSignal, setWindowTitle, switchWindow],
  );

  return wmValue;
};

export default useWinManager;
