/**
 * @const
 */
export const defaultColumns = {
  id: { label: 'id', name: 'id', visible: false },
  repr: { label: 'Представление', name: 'repr', visible: false },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  code: {
    label: 'Код', name: 'Код', visible: true, width: 'fit-content(150px)',
  },
  isDeleted: { label: 'Видалений', name: 'ПометкаУдаления', visible: false },
  children: { label: 'Нащадок', name: 'children', visible: false },
  isGroup: { label: 'Група', name: 'ЭтоГруппа', visible: false },
  parent: { label: 'Папка', name: '_Родитель', visible: false },
  hierarchyLevel: { label: 'уровень', name: '_HierarchyLevel', visible: false },
  orderNo: { label: 'порядковый номер', name: '_OrderNo', visible: false },

  attachedFiles: { label: 'Додані фали', name: 'ТаблицаПрикрепленныхФайлов', visible: false },
};

export const additionalAnalitics = {
  label: 'Додаткова аналітика',
  name: 'ДополнительнаяАналитика',
  columns: {
    property: { label: 'Розріз додактової аналітики', name: 'Свойство' },
    type: { label: 'Тип', name: 'Тип' },
    value: { label: 'Значення', name: 'Значение' },
  },
};

/**
 * @const
 */
export const directions = {
  descending: 'descending',
  ascending: 'ascending',
};

export const defaultOrder = [
  {
    column: defaultColumns.name.name,
    direction: directions.ascending,
  },
];

export const frontendPrefix = {};

/**
 * @const
 * @type {Set<string>}
 */
export const reqListerColumns = new Set([
  'id',
  'repr',
  'code',
  'name',
  'isDeleted',
  'isGroup',
  'parent',
  'hierarchyLevel',
  'orderNo',
  'children',
]);

/**
 * @const
 * @type {{foldersNItems: string, onlyItems: string}}
 */
export const hierarchyTypes = {
  foldersNItems: 'HierarchyFoldersAndItems',
  onlyItems: 'HierarchyOfItems',
};

/**
 * @const
 * @type {{items: string, folders: string, foldersNItems: string}}
 */
export const selectionMode = {
  items: 'ITEMS',
  folders: 'FOLDERS',
  foldersNItems: 'FOLDERSANDITEMS',
};

/**
 * @const
 * @property {string} equal
 * @property {string} greater
 * @property {string} greaterOrEqual
 * @property {string} less
 * @property {string} lessOrEqual
 * @property {string} notEqual
 * @property {string} inHierarchy
 * @property {string} inList
 * @property {string} inListByHierarchy
 * @property {string} filled
 * @property {string} beginsWith
 * @property {string} notInHierarchy
 * @property {string} notInList
 * @property {string} notInListByHierarchy
 * @property {string} notFilled
 * @property {string} notBeginsWith
 * @property {string} notLike
 * @property {string} notContains
 * @property {string} like
 * @property {string} contains
 */
export const comparisonTypes = {
  equal: 'EQUAL',
  greater: 'GREATER',
  greaterOrEqual: 'GREATEROREQUAL',
  less: 'LESS',
  lessOrEqual: 'LESSOREQUAL',
  notEqual: 'NOTEQUAL',
  inHierarchy: 'INHIERARCHY',
  inList: 'INLIST',
  inListByHierarchy: 'INLISTBYHIERARCHY',
  filled: 'FILLED',
  beginsWith: 'BEGINSWITH',
  notInHierarchy: 'NOTINHIERARCHY',
  notInList: 'NOTINLIST',
  notInListByHierarchy: 'NOTINLISTBYHIERARCHY',
  notFilled: 'NOTFILLED',
  notBeginsWith: 'NOTBEGINSWITH',
  notLike: 'NOTLIKE',
  notContains: 'NOTCONTAINS',
  like: 'LIKE',
  contains: 'CONTAINS',
};

/**
 * @const
 * @type {{Write: string, Posting: string, UndoPosting: string}}
 */
export const saveModes = {
  Write: 'Write',
  Posting: 'Posting',
  UndoPosting: 'UndoPosting',
};

export const emptyUid = '00000000-0000-0000-0000-000000000000';

export const periodTypes = {
  year: 'YEAR',
  halfYear: 'HALFYEAR',
  quarter: 'QUARTER',
  month: 'MONTH',
  day: 'DAY',
};

/**
 * @const
 * @property {defaultOrder} defaultOrder
 * @property {defaultColumns} defaultColumns
 * @property {comparisonTypes} comparisonTypes
 * @property {frontendPrefix} frontendPrefix
 * @property {hierarchyTypes} hierarchyTypes
 * @property {reqListerColumns} reqListerColumns
 * @property {selectionMode} selectionMode
 * @property {saveModes} saveModes
 */
const commonConstants = {
  defaultOrder,
  defaultColumns,
  comparisonTypes,
  frontendPrefix,
  hierarchyTypes,
  reqListerColumns,
  selectionMode,
  saveModes,
  periodTypes,
};

export default commonConstants;
