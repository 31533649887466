import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NavBar from '../../containers/dashboard/navBar';
import AppRouter from '../routes';
import WindowsManager from '../windowsManager';
import ManagerLink from '../windowsManager/managerLink';

const MainBlock = styled.div`
    display: flex;
    min-height: 100vh;
    height: 100vh;
    overflow: hidden;
  `;

const BodyBlock = styled.div`
    //margin-bottom: 30px;
    width: 100%;
    background: white;
    padding: 0 32px;
    position: relative;
    overflow: hidden;
    @media (min-height: 800px){
      padding: 10px 32px;
    }
  `;

const FooterBlock = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30px;
    border-top: 1px solid rgb(175, 187, 204);
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    & > .LinksManager > div {
      height: 30px;
    }
  `;

const ContentPlaceholder = styled.div.attrs(({ footHeight }) => ({
  style: { height: `calc(100% - ${footHeight}px)` },
}))`
    width: 100%;
    background: white;
    overflow: auto;
  `;
const Layout = ({ handleLogout }) => {
  const ref = useRef();
  const [footHeight, setFootHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setFootHeight(ref.current.getBoundingClientRect().height);
    }
  }, []);

  return (
    <MainBlock className="main_block">
      <NavBar handleLogout={handleLogout} />
      <BodyBlock>
        <ContentPlaceholder className="ContainerForBox" id="root-container" footHeight={footHeight}>
          <AppRouter />
          <WindowsManager />
        </ContentPlaceholder>
        <FooterBlock ref={ref}>
          <ManagerLink />
        </FooterBlock>
      </BodyBlock>
    </MainBlock>
  );
};

Layout.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default Layout;
