import styled from 'styled-components';

const InputBordered = styled.input`
  box-sizing: border-box;
  box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.15);
  border-right: none;
  border-radius: 2px;
  padding: 7px 8px;
  font-size: 12px;
  width: 150px; /*150px - если есть кнопка закрыть, 170рх - если кнопка закрыть еще не появилась*/
  outline:none;
  border: 1px solid rgba(142, 179, 223, .5);
  &:focus{
    border: 1px solid rgb(142, 179, 223);
  }
  &:disabled{
    border: 1px solid #E6E6E6;
    background: #ffffff;
  }
`;

export default InputBordered;
