import { fieldTypes } from './fieldTypes';
import { groupTypes } from './groupTypes';
import { orderTypes } from './orderTypes';
import { comparisonTypes } from './comparisonTypes';
import { viewModes } from './viewModes';

export const DataComposition = {
  fieldTypes,
  groupTypes,
  orderTypes,
  comparisonTypes,
  viewModes,
};
