/* eslint-disable no-confusing-arrow */
import React, {
  useState, useCallback, useContext,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  StyledLink,
  StyledList,
  StyledUl,
} from '../../components/componentStyles';
import NavPanel from '../../constants/meta/navBar/index';
import { HideDashboard } from '../../components/button/hideDashboard';
import InfoDashboard from '../../components/button/ButtonInfo';
import { OutputIcon } from '../../assets/icons';
import LogOutModal from '../../components/layout/logOutModal';
import { AppContext } from '../../providers/authProvider';

const StyledListHeader = styled.li`
  color: white;
  padding: 10px 12px;
  font-size: 12.5px;
  display: flex;
  align-items: flex-start;
  padding: ${(props) => props.isVisible ? '' : '10px'}
  >span {
    display: ${(props) => props.isVisible || 'none'}
  }
  >button{
    transform: ${(props) => props.isVisible || 'rotate(180deg)'}
  }
`;

const StyledImg = styled.div`
  margin-right: 4px;
`;
const ContainerNav = styled.div/* Sidebar Container */` 
  background: linear-gradient(180deg,#070a37 0%,#3a6da8 100%);
  border-right: 1px solid #7faee4;
  min-height: 100vh;
  position: relative;
  width: ${(props) => props.isVisible ? '217px' : '50px'}
  transition: all .3s;
  overflow:hidden;
`;
const FixedContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1;
`;
const ContainerLoginInfo = styled.div`
 display: flex;
 padding: 5px 12px;
 height: 100%;
 align-items: flex-end;
 align-self: flex-end;
 display: ${(props) => props.isVisible && ''};
 flex-wrap: ${(props) => props.isVisible || 'wrap'} ;
 align-content: ${(props) => props.isVisible || 'flex-end'};
 padding: ${(props) => props.isVisible || '6px'};
`;

const ButtonOutput = styled.button`
  outline: none;
  display: flex;
  margin-left: 10px;
  cursor: pointer;
  padding: 6px 8px;
  border: none;
  font-family: Roboto, sans-serif;
  line-height: 20px;
  font-size: 12px;
  color: #4281C9;
  background: linear-gradient(180deg, #E5EDF7 0%, #BDD3EC 100%);
  border-radius: 4px;
  // eslint-disable-next-line no-confusing-arrow
  margin-left: ${(props) => props.isVisible ? '' : '0px'}
  margin-top: ${(props) => props.isVisible || '10px'}
   &:hover{
     background: #bdd3ec;
  };
`;
const TextButtonOutput = styled.span`
    margin-left: 6px;
    font-size: 14px;
    // eslint-disable-next-line no-confusing-arrow
    display: ${(props) => props.isVisible ? '' : 'none'}
  `;

const NavBar = ({ handleLogout }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [modalOpened, setModalOpened] = useState(false);

  const toggleVisibe = useCallback(
    () => setIsVisible((v) => !v),
    [],
  );

  const {
    sessionOptions,
  } = useContext(AppContext);

  const {
    user, fo, budget, username, caption,
  } = sessionOptions;

  const navPanel = NavPanel;

  const onCloseModal = useCallback(
    () => setModalOpened(false),
    [],
  );

  return (
    <ContainerNav isVisible={isVisible}>
      <FixedContainer>
        <StyledUl
          isVisible={isVisible}
        >
          <StyledListHeader isVisible={isVisible}>
            <HideDashboard
              title={isVisible ? 'Згорнути' : 'Розгорнути'}
              onClick={toggleVisibe}
            />
            {isVisible && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  {`ЄІСУБ-cloud@${caption}`}
                </span>
                <span>
                  {budget.repr}
                </span>
                <span>
                  {`${username || user.repr}`}
                </span>
                <span>
                  {`${fo.repr} `}
                </span>
              </div>
            )}
          </StyledListHeader>
          {
            Object.values(navPanel).filter((el) => !el.visible)
              .map((el) => (
                <StyledList
                  key={el.name}
                >
                  <StyledLink
                    to={el.link}
                    isVisible={isVisible}
                    backgroundColor={el.backgroundColor}
                  >
                    <StyledImg>
                      {el.icon}
                    </StyledImg>
                    <span>{el.label}</span>
                  </StyledLink>
                </StyledList>
              ))
          }
        </StyledUl>
        <ContainerLoginInfo
          isVisible={isVisible}
        >
          <InfoDashboard />
          <ButtonOutput
            title="Вийти"
            isVisible={isVisible}
            onClick={() => setModalOpened(true)}
          >
            <OutputIcon />
            <TextButtonOutput
              isVisible={isVisible}
            >
              Вийти
            </TextButtonOutput>
          </ButtonOutput>
        </ContainerLoginInfo>
      </FixedContainer>
      {modalOpened && (
        <LogOutModal
          closeModal={onCloseModal}
          handleLogout={handleLogout}
        />
      )}

    </ContainerNav>
  );
};

NavBar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};
export default NavBar;
