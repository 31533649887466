import React, {
  useState, useRef, memo, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  StyledTextArea, PrintableCell,
} from './styles';
import DivResizerX from './xresize';

const Cell = ({
  name, column, colSpan, rowSpan, content, styleId, decryption, tableName,
  active, editMode, onActivateCell, onResizeColumn, onReportDetails,
  fixedLeft, fixedTop, tableTop, tableLeft,
}) => {
  const [cellContent, setCellContent] = useState(content);
  const cellRef = useRef();
  const coords = useMemo(
    () => {
      if ((fixedTop || fixedLeft) && cellRef.current) {
        const c = cellRef.current.getBoundingClientRect();
        return ({
          top: fixedTop ? c.top - tableTop - 1 : null,
          left: fixedLeft ? c.left - tableLeft - 1 : null,
        });
      }
      return { top: null, left: null };
    },
    [fixedLeft, fixedTop, tableLeft, tableTop],
  );

  const fixedClassName = `${fixedTop ? 'FixedToTop' : ''} ${fixedLeft ? 'FixedToLeft' : ''}`;

  const onResize = useCallback(
    (e, delta) => onResizeColumn(e, tableName, column, colSpan, delta),
    [colSpan, column, onResizeColumn, tableName],
  );

  return (
    <PrintableCell
      ref={cellRef}
      rowSpan={(rowSpan === 0) ? null : rowSpan}
      colSpan={(colSpan === 0) ? null : colSpan}
      decryption={decryption}
      className={`col${column} CellStyle${styleId} ${active ? 'ActiveCell' : ''} ${fixedClassName} `}
      onClick={(e) => onActivateCell(e, name)}
      top={coords.top}
      left={coords.left}
      onDoubleClick={() => {
        if (decryption) onReportDetails(decryption);
      }}
    >
      {/* <div className="report-text-div"> */}
      {active && editMode ? (
        <StyledTextArea
          value={cellContent}
          onChange={(e) => setCellContent(e.target.value)}
        />
      ) : content }
      {/* </div> */}
      {active && (
        <DivResizerX
          onResize={onResize}
        />
      )}
    </PrintableCell>
  );
};
Cell.propTypes = {
  colSpan: PropTypes.number,
  rowSpan: PropTypes.number,
  column: PropTypes.number.isRequired,
  content: PropTypes.string,
  styleId: PropTypes.number,
  decryption: PropTypes.string,
  name: PropTypes.string,
  tableName: PropTypes.number.isRequired,
  active: PropTypes.bool,
  editMode: PropTypes.bool,
  onActivateCell: PropTypes.func.isRequired,
  onResizeColumn: PropTypes.func.isRequired,
  onReportDetails: PropTypes.func.isRequired,
  fixedLeft: PropTypes.bool,
  fixedTop: PropTypes.bool,
  tableTop: PropTypes.number,
  tableLeft: PropTypes.number,
};

Cell.defaultProps = {
  colSpan: null,
  rowSpan: null,
  content: '',
  styleId: 0,
  decryption: '',
  name: '',
  active: false,
  editMode: false,
  fixedLeft: false,
  fixedTop: false,
  tableTop: 0,
  tableLeft: 0,
};

export default memo(Cell);
