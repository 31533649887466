import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import tarif, { tarif as md } from '../../../../constants/meta/catalogs/tarif';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const Tarif = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
              label={md.columns.tarif.label}
              value={data[md.columns.tarif.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.tarif.name]: value,
              })}
              readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
              label={md.columns.note.label}
              value={data[md.columns.note.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.note.name]: value,
              })}
              readOnly={readOnly}
              rows={3}
          />
        </Col>
      </Row>
    </Container>
  );
};

Tarif.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Tarif;
