import React from 'react';
import PropTypes from 'prop-types';

import ArrowDownIcon from '../../assets/icons/arrowDown';
import Button from '../styledAssigmButton/assigmButton';

const SwipeBottomButton = ({ text, onClick, ...args }) => (
  <Button onClick={onClick} {...args} title="Перемістити низжче">
    <ArrowDownIcon />
  </Button>
);

SwipeBottomButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

SwipeBottomButton.defaultProps = {
  text: 'Перемістити низжче',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default SwipeBottomButton;
