import React from 'react';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import UseMenuHeader from '../hooks/useMenuHeader';

const AnalysisMenuItem = () => (
  <UseMenuHeader label="Аналіз структури заробітної плати">
    <Boxes items={NavPanel.analysis.boxes} />
  </UseMenuHeader>
);

export default AnalysisMenuItem;
