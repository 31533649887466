import React, { useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { modelType, modelName } from './def';
import TableEditor from '../../newTableEditor';
import {
  ResultSegment,
  ContainerDiv,
} from '../../../components/Form/styledForm';
import ReportContainer from '../reportContainer';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import { useMD } from '../../newLister/hooks/md';

const ReportEditor = ({
  isProcessing, portalOpened, actions, data, sessionOptions,
}) => {
  const md = useMD(modelType, modelName);
  // const readOnly = !sessionOptions.is_admin;
  const readOnly = false;

  // useEffect(() => {
  //   actions.changeField('isNewTypeSign', true);
  //   actions.changeField('isShowCredit', true);
  // }, [actions]);

  const csuFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: data[md.columns.csu.name] }],
    [data],
  );

  const SettingsButton = (
    <>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.budget.name]}
            label={md.columns.budget.label}
            modelType="cat"
            modelName="budget"
            onChange={(e, value) => {
              actions.changeField([md.columns.csu.name], {});
              actions.changeField([md.columns.budget.name], value);
            }}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.csu.name]}
            label={md.columns.csu.label}
            modelType="cat"
            modelName="csu"
            onChange={(e, value) => actions.changeField([md.columns.csu.name], value)}
            readOnly
          />
        </Col>
      </Row>
      <Row>

        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.kbpClassifier.name]}
            label={[md.columns.kbpClassifier.label]}
            modelType="cat"
            modelName="kbpClassifier"
            onChange={(e, value) => actions.changeField([md.columns.kbpClassifier.name], value)}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.units.name]}
            label={[md.columns.units.label]}
            modelType="cat"
            modelName="units"
            filter={csuFilter}
            onChange={(e, value) => actions.changeField([md.columns.units.name], value)}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      actions={actions}
    >
      <ContainerDiv>
        <Col>
          <EditorControls.DateInput
            label={md.columns.endDate.label}
            value={data[md.columns.endDate.name]}
            onChange={(e, value) => actions.changeField({
              [md.columns.endDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </ContainerDiv>

      <ResultSegment>
        <TableEditor
          data={data}
          actions={actions}
        />
      </ResultSegment>
    </ReportContainer>
  );
};

ReportEditor.propTypes = {
  isProcessing: PropTypes.bool,
  portalOpened: PropTypes.bool,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  isProcessing: false,
};

// ReportEditor.displayName = `Report${md.name}Editor`;

export default ReportEditor;
