import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  owner: {
    label: 'Фінансовий орган',
    name: 'Владелец',
    visible: false,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  flAutoFillName: { label: 'Автоматичне заповнення найменування ', name: 'флАтоматЗаполненияНаим', visible: false },
  flAutoFillFullName: {
    label: 'Автоматичне заповнення повного найменування ',
    name: 'флАтоматЗаполненияПолнНаим',
    visible: false,
  },
  Steward: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  Classifier: { label: 'Фонд', name: 'Классификатор', visible: true },
  DateCl: { label: 'станом на', name: 'ДатаКл', visible: false },
  ElemFinInstitution: {
    label: 'Елемент фiн органу',
    name: 'ЭлементФинОргана',
    visible: true,
  },
  DateEl: { label: 'станом на', name: 'ДатаЭл', visible: true },
  CodeFond: { label: 'Код Фонд', name: 'КодФонд', visible: true },
};

export const elementFond = {
  label: 'Деталізація фондів',
  name: 'elementFond',
  backendName: 'ЭлементыФонд',
  columns,
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  frontend: 'cat/elementFond',
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default elementFond;
