import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  KoefPay: { label: 'Коефіцієнт погодинної оплати', name: 'КоэффициентПочасовойОплаты', visible: false },
  NoVisible: {
    name: 'флНеПоказыватьВСпискеВыбора',
    label: 'Не показувати у списку вибору документа',
    visible: false,
  },
};

const footer = { ...columns };
/**
 * @const
 */
export const branches = {
  label: 'Галузі',
  name: 'branches',
  backendName: 'Отрасли',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/branches',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default branches;
