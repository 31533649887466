import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DefaultCPButton = forwardRef(({
  disabled, onClick, label, icon, variant, children, showLabel, ...restProps
}, ref) => {
  const id = useMemo(
    // eslint-disable-next-line no-bitwise
    () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
    [],
  );
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip id={id}>
          {label}
        </Tooltip>
      )}
    >
      <Button disabled={disabled} onClick={onClick} variant={variant} ref={ref} {...restProps}>
        {children}
        {!children && icon && (
          <>
            <FontAwesomeIcon icon={icon} className={showLabel ? 'mr-1' : ''} />
            <span className="d-none ">
              {label}
            </span>
          </>
        )}
        {((!children && !icon) || showLabel) && label}
      </Button>
    </OverlayTrigger>

  );
});

DefaultCPButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.shape({}),
  variant: PropTypes.oneOf([
    'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link',
    'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning',
    'outline-info', 'outline-dark', 'outline-light',
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  showLabel: PropTypes.bool,
};

DefaultCPButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: '',
  icon: null,
  variant: 'primary',
  children: null,
  showLabel: false,
};

export default DefaultCPButton;
