import styled from 'styled-components';
import PropTypes from 'prop-types';

const Input = styled.input`
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  border-radius: 2px;
  padding: 7px 8px;
  font-size: 12px;
  width: 150px; /*150px - если есть кнопка закрыть, 170рх - если кнопка закрыть еще не появилась*/
  outline:none;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid red; /* DELETE THIS AFTER TESTS */
  &:disabled{
    background: #ffffff;
  }
`;

Input.propTypes = {
  disabled: PropTypes.bool,
};

export default Input;
