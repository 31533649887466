import styled from 'styled-components';

const Button = styled.button`
  background: linear-gradient(0deg, #bfd4ef, #d1e6fb, #dfeefd, #c7daf2);
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  padding: 5px 8px;
  margin-right: 5px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  filter: ${({ disabled }) => disabled ? 'grayscale(1) opacity(.6)' : 'unset'};
  &:hover {
    background: #aac6e6;
  }
  :focus {
    outline: none;
  }
`;

export default Button;
