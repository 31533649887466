import base from './base';

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
  isApproved: { label: 'Тільки читання', name: 'ЗаблокироватьИзменения', visible: false },
  units: { label: 'Підрозділ', name: 'Подразделение', visible: false },
  branches: { label: 'Галузь', name: 'Отрасль', visible: false },
  kbp: { label: 'КБП', name: 'КлассификаторКБП', visible: true },
  signVar: { label: 'Варіант підпису', name: 'Подпись', visible: false },
  formAvailable: { label: 'ЭтаФормаДоступность', name: 'ЭтаФормаДоступность', visible: false },
  kfkReadOnly: { label: 'ЭлементыКлассификаторКФКТолькоПросмотр', name: 'ЭлементыКлассификаторКФКТолькоПросмотр', visible: false },
  kbpReadOnly: { label: 'ЭлементыКлассификаторКБПТолькоПросмотр', name: 'ЭлементыКлассификаторКБПТолькоПросмотр', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Показатели',
    label: 'Показники',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      indicator: { label: 'Показник', name: 'Показатель' },
      clas: { label: 'Класи', name: 'Классы' },
      sum: { label: 'Значення', name: 'Значение' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Введення показників на початок навчального року',
  name: 'indicatorsStartYear',
  backendName: 'ВводПоказателейНаНачалоУчебногоГода',
  columns,
  frontend: 'doc/indicatorsStartYear',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'CSU',
      'FI',
      'kbp',
      'author',
      'budget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
