import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  Surname: { label: 'Прізвище', name: 'Фамилия', visible: true },
  Name1: { label: 'Ім\'я', name: 'Имя', visible: true },
  Name2: {
    name: 'Отчество',
    label: 'По-батькові ',
    visible: false,
  },
  Post: { label: 'Посада', name: 'Посада', visible: true },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  Owner: { label: 'Установа', name: 'Владелец', visible: false },
};

const footer = { ...columns };
/**
 * @const
 */
export const responsiblePerson = {
  label: 'Посадові особи',
  name: 'responsiblePerson',
  backendName: 'ДолжностнЛица',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/responsiblePerson',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default responsiblePerson;
