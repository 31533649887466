import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  Notes: { label: 'Примітка', name: 'Примечание', visible: true },
  Key: { label: 'Ключ', name: 'Ключ' },
  FI: { label: 'ФинансовыйОрган', name: 'Фінансовий орган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
};

/**
 * @const
 */
export const reportSettings = {
  label: 'Налаштування звітів',
  name: 'reportSettings',
  backendName: 'НастрокийОтчетов',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  frontend: 'cat/reportSettings',

  // Remove duplicates
  listColumns: [...new Set([
    ...reqListerColumns,
    'Notes',
  ])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  foldersOnTop: true,
};

export default reportSettings;
