import styled from 'styled-components';

const BoxMenuPlaceholder = styled.div`
    width:270px;
    min-width: ${(props) => props.showNav && '50%'};
    // min-height: 235px;
    height: fit-content;
    padding: 12px 16px;
    margin: 1em 0;
    border: 1px solid #BACCE2;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 5%;
    font-family: Roboto, sans-serif;
    position: relative;
    &:hover{
      border: 1px solid #37a0a7;
      box-shadow: 0px 1px 30px -4px rgba(58,140,194,1);
    }
    @-moz-document url-prefix()
    {
      display: inline-block;
      vertical-align: top;
    }
`;

export default BoxMenuPlaceholder;
