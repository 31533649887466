import React from 'react';
import PropTypes from 'prop-types';
import { faPaperclip as icon } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

const FilesButton = ({ onClick, label, disabled }) => (
  <DefaultCPButton
    icon={icon}
    onClick={onClick}
    label={label}
    disabled={disabled}
  />
);

FilesButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

FilesButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Переглянути додані файли',
};

export default FilesButton;
