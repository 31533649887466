import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import WinManagerContext from '../../../providers/winManagerProvider';

const StyledBoxLink = styled(({ line, ...r }) => <Link {...r} />)`
    display: block;
    font-size: 14px;
    color: #252525;
    text-decoration: none;
    margin: 5px 0;
    border-bottom: ${(props) => props.line && '1px dotted lightblue'};
   
    :focus {outline:none;}
`;

const BoxLink = ({
  to, children, line,
}) => {
  const { switchWindow } = useContext(WinManagerContext);
  return (
    <StyledBoxLink
      to={`/${to}/`}
      line={line}
      onClick={() => {
        switchWindow(`/${to}/`);
      }}
    >
      {children}
    </StyledBoxLink>
  );
};

BoxLink.propTypes = {
  to: PropTypes.string.isRequired,
  line: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
BoxLink.defaultProps = {
  line: false,
};

export default BoxLink;
