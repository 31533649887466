import base from './base';

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  csu: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  isApproved: {
    label: 'Документ затверджено', name: 'ДокументУтвержден', visible: true, type: 'bool',
  },
  stamp: { label: 'Квітанція', name: 'Квитанция', visible: false },
  unit: { label: 'Підрозділ', name: 'Подразделение', visible: true },
  fond: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  kbp: { label: 'КБП', name: 'КлассификаторКБП', visible: true },
  changeDate: {
    label: 'Зміни на', name: 'ИзмененияНа', visible: true, type: 'date',
  },
  detCh: { label: 'Деталізація змін', name: 'ДетализацияИзменений', visible: true },
  recount: {
    label: 'Перерахунок', name: 'Перерасчет', visible: true, type: 'bool',
  },
  branches: { label: 'Галузь', name: 'Отрасль', visible: true },
  baseDoc: { label: 'Підстава', name: 'ОснованиеПриказа', visible: true },
  FI: { label: 'Финансовый орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  dateChIn: {
    label: 'Дата ост.змін', name: 'ДатаПоследнегоИзм', visible: true, type: 'date',
  },
  basisDocs: { label: 'Підстава для змін', name: 'ОснованияДок', visible: true },
  baseDocYear: { label: 'Підстави на підвищення з.п.', name: 'ОснованиеПриказаГод', visible: true },
  flNoC: { label: 'Зняти нарахування', name: 'флСнятьНачисления', visible: true },
  detChAvailable: { label: 'ЭлементыДетализацияИзмененийДоступность', name: 'ЭлементыДетализацияИзмененийДоступность', visible: true },
  canDeleteisApproved: { label: 'УтверждениеДоступность', name: 'УтверждениеДоступность', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Начисления',
    label: 'Нарахування',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      stateUnits: { label: 'Штатна одиниця', name: 'ШтатнаяЕдиница' },
      category: { label: 'Категорія', name: 'Категория' },
      post: { label: 'Посада', name: 'Должность' },
      num: { label: 'Кільк. посад', name: 'Кво' },
      numStateBase: { label: 'Вид нарахування', name: 'ВидРасчета' },
      ssize: { label: 'Значення нарах.', name: 'Размер' },
      res: { label: 'Результат', name: 'Результат' },
      numMonth: { label: 'Кільк.міс.розрах.', name: 'КвоМесРасч' },
      numMonthState: { label: 'Не врах.кільк.міс.шт.', name: 'КвоМесШт' },
      resY: { label: 'Сумма за період', name: 'РезультатГод' },
      baseDoc: { label: 'Підстава', name: 'Основание' },
      stateSolary: { label: 'Посадовий оклад', name: 'ЭтоДолжностнойОклад' },
      numHour: { label: 'Кільк.посад (погодинно)', name: 'КвоПоЧас' },
      madeMonth: { label: 'Відпрацьовані місяця', name: 'ОтработаноМес' },
      eraseSolary: { label: 'Зняти нарахування', name: 'флСнятьНачисления' },
      fond: { label: 'Фонд', name: 'ЭлементФонд' },
      kbp: { label: 'КБП', name: 'КлассификаторКБП' },
    },
  },
  generalYear: {
    name: 'НачисленияГод',
    label: 'Нарахування на рік',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      stateUnits: { label: 'Штатна одиниця', name: 'ШтатнаяЕдиница' },
      category: { label: 'Категорія', name: 'Категория' },
      post: { label: 'Посада', name: 'Должность' },
      num: { label: 'Кільк. посад', name: 'Кво' },
      numStateBase: { label: 'Вид нарахування', name: 'ВидРасчета' },
      ssize: { label: 'Значення нарах.', name: 'Размер' },
      res: { label: 'Результат', name: 'Результат' },
      baseDoc: { label: 'Підстава', name: 'Основание' },
      stateSolary: { label: 'Посадовий оклад', name: 'ЭтоДолжностнойОклад' },
      numHour: { label: 'Кільк.посад (погодинно)', name: 'КвоПоЧас' },
      numYear: { label: 'Кільк.на початок року', name: 'КвоНачГода' },

    },
  },
  base: {
    name: 'Основания',
    label: 'Підстави',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      baseDoc: { label: 'Підстава', name: 'Основание' },
      numMonth: { label: 'Кільк. міс.', name: 'КвоМес' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Розрахувати тарифікаційні списки',
  name: 'calcTarifList',
  backendName: 'РассчитатьТарификационныеСписки',
  columns,
  frontend: 'doc/calcTarifList',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'recount',
      'changeDate',
      'branches',
      'csu',
      'unit',
      'kbp',
      'fond',
      'baseDoc',
      'dateChIn',
      'note',
      'isApproved',
      'author',
      'budget',
      'FI',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
