/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Form} from 'react-bootstrap';
import {getDisplayName} from '../../../api/utils';

export const EditorControlPropTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,

};

export const EditorControlDefaultProps = {
  label: null,
  required: false,
  errors: null,
  description: null,
};

const withEditorControl = (WrappedControl) => {
  const EditorInput = ({
                         label, required, errors, description, ...restProps
                       }) => {
    return (
      <Form.Group>
        {label && (
          <Form.Label>
            {label}
            {required && (<span className={"text-danger"}>*</span>)}
          </Form.Label>
        )}
        <WrappedControl
          {...restProps}
          errored={errors && !!errors.length}
          required={required}
          errText={String(errors)}
        />
        {!!errors && (
          <Form.Control.Feedback type="invalid">
            {String(errors)}
          </Form.Control.Feedback>
        )}
        {description && (
          <Form.Text className="text-muted">
            {description}
          </Form.Text>
        )}
      </Form.Group>
    );
  };

  EditorInput.displayName = `${getDisplayName(WrappedControl)} wrapped by withEditorControl`;

  EditorInput.propTypes = EditorControlPropTypes;

  EditorInput.defaultProps = EditorControlDefaultProps;

  return EditorInput;
};

export default withEditorControl;
