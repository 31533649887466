import {
  useCallback, useEffect, useMemo, useRef, useState, useContext,
} from 'react';
import { useMD } from '../../newLister/hooks/md';
import api from '../../../api/req';
import { saveModes } from '../../../constants/meta/common';
import WinManagerContext from '../../../providers/winManagerProvider';
import { AppContext } from '../../../providers/authProvider';

// *  modelType: {string},   тип модели
// *  modelName: {string} - название модели,
// *  id: {string} - ИД,
// *  reason: {string} - Основание (при вводе на основании),
// *  isGroup: {boolean} - Это группа (при создании группы),
// *  copyFrom: {string} - Ид копируемого элемента,
// *  onSaveCallBack: (function) - callback при сохранении документа,
// *  onCloseCallBack: (function) - callback при закрытии документа,
// *  defaults: {{}} - Значения по умолчанию  для нового объекта,
// *  readOnlyGetter: {function(@param data {})} - функция,
// *                  которая вычисляет значение аттрибута readOnly,
// * }}

/**
 * @param editorParams Параметры hook редактора
*  @param editorParams.modelType {string} тип модели
*  @param editorParams.modelName {string} - название модели,
*  @param editorParams.id {string} - ИД,
*  @param editorParams.reason {string} - Основание (при вводе на основании),
*  @param editorParams.isGroup {boolean} - Это группа (при создании группы),
*  @param editorParams.copyFrom {string} - Ид копируемого элемента,
*  @param editorParams.onSaveCallBack (function) - callback при сохранении документа,
*  @param editorParams.onCloseCallBack (function) - callback при закрытии документа,
*  @param editorParams.defaults {{}} - Значения по умолчанию  для нового объекта,
*  @param editorParams.readOnlyGetter {function(@param data {})} - функция,
*                  которая вычисляет значение аттрибута readOnly,
* }}
 *
 * @returns {{
 *  data: {},
 *  err: string,
 *  loading: boolean
 *  changed: boolean
 *  actions: {
 *    onReload: (function(): void),
 *    onChange: (function(@param partData {}): void),
 *    onSaveWithoutExit: (function(): void),
 *    onSaveNExit: (function(): void),
 *    onExecuteNExit: (function(): void),
 *    onExecute: (function(): void),
 *    onUnexecute: (function(): void),
 *    onGoToOldVersion: (function(): void),
 *    onSaveNGoToOldVersion: (function(): void),
 *    onUndo: (function(): void),
 *    onRedo: (function(): void),
 *    onClose: (function(): void),
 *    onSRWithoutContext: (function(): void),
 *    onSR:  (function(@param method: string, @param message {}, @param changeDate: bool): void),
 *    onErr: (function(): void),
 *    onLoading: (function(): void),
 *  }
 *  permissions: {
 *    canSave: boolean,
 *    canUndo: boolean,
 *    canRedo: boolean,
 *    canClose: boolean,
 *    canExecute: boolean,
 *    canUnexecute: boolean,
 *    canChange: boolean,
 *  }
 * }}
 */
const useEditor = (editorParams) => {
  const defaultParams = useMemo(
    () => ({
      id: null,
      reason: '',
      isGroup: false,
      copyFrom: '', // id документа, который копируется
      onSaveCallBack: null, // callback при сохранении документа
      onCloseCallBack: null, // callback при выходе
      defaults: {}, // Значения по умолчанию  для нового объекта
      readOnlyGetter: null, // функция, которая вычисляет значение аттрибута readOnly
    }),
    [],
  );

  const {
    modelType,
    modelName,
    id,
    reason, // id_документа основания
    isGroup, // это группа
    copyFrom, // id документа, который копируется
    onSaveCallBack, // callback при сохранении документа
    onCloseCallBack, // callback при выходе
    defaults, // Значения по умолчанию  для нового объекта
    readOnlyGetter, // функция, которая вычисляет значение аттрибута readOnly
  } = useMemo(
    () => ({ ...defaultParams, ...editorParams }),
    [defaultParams, editorParams],
  );

  const { sendUpdateSignal, dellComponentFromWindowsManager } = useContext(WinManagerContext);

  const meta = useMD(modelType, modelName);

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const currentData = useRef(null);
  const [data, setData] = useState({
    current: {},
    history: {
      data: [], pointer: null,
    },
  });

  currentData.current = data.current;
  const appContest = useContext(AppContext);

  const [changed, setChanged] = useState(false);

  const onCloseEditor = (idEditor) => {
    if (onCloseCallBack) return onCloseCallBack(idEditor);

    return dellComponentFromWindowsManager(`/${modelType}/${modelName}/${id}/`);
  };

  const load = useCallback(
    (itemId, initParams) => {
      const loader = async () => {
        const r = await api.post$(`${modelType}/${meta.backendName}/${itemId}`, initParams, appContest);
        if (!r.ok) {
          let e;
          try {
            e = await r.text();
          } catch {
            e = `${r.status} ${r.statusText}`;
          }
          throw new Error(e);
        }
        return r.json();
      };
      setLoading(true);
      setErr(null);
      loader()
        .then((d) => {
          setChanged(false);
          setData({
            current: d,
            history: {
              data: [d],
              pointer: null,
            },
          });
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [meta.backendName, modelType],
  );

  const SRWithoutContext = useCallback(
    async (method, message) => {
      setLoading(true);
      const r = await api.post$(`${modelType}/req/${meta.backendName}/${method}/`, message, appContest);
      if (!r.ok) {
        let e;
        try {
          e = await r.text();
        } catch {
          e = `${r.status} ${r.statusText}`;
        }
        setLoading(false);
        throw new Error(e);
      }
      setLoading(false);
      return r.json();
    },
    [meta.backendName, modelType],
  );

  const SR = useCallback(
    async (method, message = {}, changeData = true) => {
      setLoading(true);
      const r = await api.post$(`${modelType}/${meta.backendName}/SR/`, {
        item: currentData.current,
        method,
        ...message,
      }, appContest);
      if (r.ok) {
        const d = await r.json();
        setErr(null);
        setLoading(false);
        if (changeData) {
          setData({
            current: d,
            history: {
              data: [],
              pointer: null,
            },
          });
          setChanged(true);
        }
        return d;
      }
      let e;
      try {
        e = await r.text();
      } catch {
        e = `${r.status} ${r.statusText}`;
      }
      setLoading(false);
      setErr(e);
      return null;
    },
    [meta.backendName, modelType],
  );

  const locked = useRef(false);
  const doLockingNow = useRef(false);

  const lock = useCallback(
    async () => {
      if (locked.current) return true;
      if (doLockingNow.current) return false;
      doLockingNow.current = true;
      // Блокируются только справочники и документы
      if (!['doc', 'cat'].includes(modelType)) {
        locked.current = true;
        doLockingNow.current = false;
      } else if (id === 'create') {
        locked.current = true;
        doLockingNow.current = false;
      } else if (id === 'createGroup') {
        locked.current = true;
        doLockingNow.current = false;
      } else {
        setLoading(true);
        setErr(null);
        const r = await api.post$(`lock/on/${id}`, null, appContest);
        doLockingNow.current = false;
        if (r.status === 202) {
          locked.current = true;
        } else if (r.status === 409) {
          const r2 = await api.post$(`/lock/status_lock/${id}`, null, appContest);
          if (!r2.ok) {
            setLoading(false);
            throw new Error(`Помилка при отриманні інформації про блокування ${r2.status} ${r2.statusText}`);
          } else {
            const lockInfo = await r2.json();
            setLoading(false);
            const at = new Date(lockInfo.At).toLocaleDateString(
              'uk',
              {
                day: '2-digit', month: 'short', year: '2-digit', hour: '2-digit', minute: '2-digit',
              },
            );
            throw new Error(`Даний об'єкт заблоковано користувачом "${lockInfo.User}" у  ${at}. Спробуйте повторити операцію пізніше`);
          }
        } else {
          throw new Error(`Помилка при встановленні блокування ${r.status} ${r.statusText}`);
        }
        setLoading(false);
      }
      return locked.current;
    },
    [id, modelType],
  );

  const unlock = useCallback(
    async () => {
      if (!locked.current) return null;
      if (!['doc', 'cat'].includes(modelType)) {
        locked.current = false;
      } else if (id === 'create') {
        locked.current = false;
      } else if (id === 'createGroup') {
        locked.current = false;
      } else {
        setErr(null);
        const r = await api.post$(`lock/off/${id}`, null, appContest);
        if (r.status === 202) {
          locked.current = false;
        } else if (r.status === 409) {
          const r2 = await api.post$(`/lock/status_lock/${id}`, null, appContest);
          if (!r2.ok) {
            throw new Error(`Помилка при отриманні інформації про блокування ${r2.status} ${r2.statusText}`);
          } else {
            const lockInfo = await r.json();
            const at = new Date(lockInfo.At).toLocaleDateString(
              'uk',
              {
                day: '2-digit', month: 'short', year: '2-digit', hour: '2-digit', minute: '2-digit',
              },
            );
            throw new Error(`Даний об'єкт заблоковано користувачом "${lockInfo.User}" у  ${at}. Спробуйте повторити операцію пізніше`);
          }
        } else {
          throw new Error(`Помилка при встановленні блокування ${r.status} ${r.statusText}`);
        }
      }
      return null;
    },
    [id, modelType],
  );

  useEffect(
    () => () => unlock(),
    [unlock],
  );

  const onChange = useCallback(
    /**
     *
     * @param partOfData {{}, function }
     */
    async (partOfData) => {
      let l = false;
      try {
        l = await lock();
      } catch (e) {
        setErr(e.message);
      }
      if (l) {
        setChanged(true);
        setData(({ current, history }) => {
          let newCurrent = current;
          if (typeof partOfData === 'function') {
            newCurrent = partOfData(newCurrent);
          } else {
            newCurrent = { ...current, ...partOfData };
          }
          const hasChange = Object.keys(newCurrent)
            .reduce((Ch, k) => Ch || partOfData[k] !== current[k], false);
          const newHData = history.pointer === null
            ? [...history.data, newCurrent]
            : [...history.data.slice(0, history.pointer + 1), newCurrent];

          return ({
            current: newCurrent,
            history: hasChange ? {
              data: newHData,
              pointer: null,
            } : history,
          });
        });
      }
    },
    [lock],
  );

  // Завантажити з серверу відразу усі додані до документа файли
  const loadAttachedFiles = useCallback(async (files = []) => {
    const dataFile = {
      id,
      modelType,
      modelName: meta.backendName,
      files,
    };
    setLoading(true);

    const r = await api.post$(`${modelType}/${meta.backendName}/${id}/attachedFiles`, dataFile, appContest);

    if (r.ok) {
      r.json().then((d) => {
        setLoading(false);
        setErr(null);
        d.map((R) => {
          const content = atob(R.content);

          const byteNumbers = new Array(content.length);
          for (let i = 0; i < content.length; i += 1) {
            byteNumbers[i] = content.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray]);

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = R.filename;
          a.click();
        });
      });
      return null;
    }
    let e;
    try {
      e = await r.text();
    } catch {
      e = `${r.status} ${r.statusText}`;
    }
    setLoading(false);
    setErr(e);
    return null;
  }, [id, meta.backendName, modelType]);

  // Отримати список усіх доданих до документа файлів (без самих файлів)
  const listAttachedFiles = useCallback(async () => {
    setLoading(true);

    const r = await api.post$(`${modelType}/${meta.backendName}/${id}/listFiles`, null, appContest);

    if (r.ok) {
      const listFiles = await r.json();
      setLoading(false);
      setChanged(true);

      onChange({ [meta.columns.attachedFiles.name]: listFiles });
      return listFiles;
    }

    let e;
    try {
      e = await r.text();
    } catch {
      e = `${r.status} ${r.statusText}`;
    }
    setLoading(false);
    setErr(e);
    return null;
  }, [id, meta.columns.attachedFiles.name, meta.backendName, modelType, onChange]);

  // Відправити на сервер додані до документа файли
  const upLoadAttachedFiles = useCallback((files) => {
    files.filter((f) => !f.done).forEach((file) => {
      const reader = new FileReader();
      setLoading(true);
      reader.onloadend = async () => {
        const dataFile = {
          id,
          modelType,
          modelName: meta.backendName,
          fileName: file.file.name,
          task: file.task,
          file: reader.result,
        };

        const r = await api.post$(`${modelType}/${meta.backendName}/${id}/addedFiles/`, dataFile, appContest);

        if (r.ok) {
          // const res = await r.json();
          // files[res.task].done = res.done;

          const listFiles = await listAttachedFiles();
          setErr(null);
          setLoading(false);
          return listFiles;
        }
      };

      if (file.file) {
        reader.readAsDataURL(file.file);
      }
    });
  }, [id, listAttachedFiles, meta.backendName, modelType]);

  const deleteAttachedFile = useCallback(async (fileName, fileNameFtp) => {
    setLoading(true);

    const dataFile = {
      id,
      modelType,
      modelName: meta.backendName,
      fileName,
      fileNameFtp,
    };

    const r = await api.post$(`${modelType}/${meta.backendName}/${id}/deleteFiles/`, dataFile, appContest);
    if (r.ok) {
      const listFiles = await listAttachedFiles();
      setErr(null);
      return listFiles;
    }

    let e;
    try {
      e = await r.text();
    } catch {
      e = `${r.status} ${r.statusText}`;
    }
    setLoading(false);
    setErr(e);
    return null;
  }, [id, listAttachedFiles, meta.backendName, modelType]);

  //= =======

  const save = useCallback(
    (item, savemode = 'SAVE', callback = null) => {
      const saver = async () => {
        await unlock();
        const r = await api.put$(`${modelType}/${meta.backendName}/${id}`, { item, savemode }, appContest);
        if (!r.ok) {
          if (r.status === 422) {
            const d = await r.json();
            // eslint-disable-next-line no-underscore-dangle
            if ('_Error' in d) throw new Error(d._Error);
          }
          let e;
          try {
            e = await r.text();
          } catch {
            e = `${r.status} ${r.statusText}`;
          }
          throw new Error(e);
        }
        return r.json();
      };
      setLoading(true);
      setErr(null);
      saver()
        .then((d) => {
          setChanged(false);
          sendUpdateSignal(meta.frontend);
          setData({
            current: d,
            history: {
              data: [d],
              pointer: null,
            },
          });// TODO: Преобразовать к md
          if (callback) callback(d.id);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [id, meta.backendName, meta.frontend, modelType, unlock],
  );

  const undo = useCallback(
    () => {
      setData(({ current, history }) => {
        if (history.pointer === null) {
          if (history.data.length > 1) {
            return ({
              current: history.data[history.data.length - 2],
              history: {
                data: history.data,
                pointer: history.data.length - 2,
              },
            });
          }
        } else if (history.pointer > 0) {
          return ({
            current: history.data[history.pointer - 1],
            history: {
              data: history.data,
              pointer: history.pointer - 1,
            },
          });
        }
        return {
          current, history,
        };
      });
    },
    [],
  );

  const goToOldVersion = useCallback(
    () => {
      const getOldVersionLink = async () => {
        await unlock();
        const url = `${modelType}/${meta.backendName}/${id}/getURL`;
        const r = await api.get$(url, null, appContest);
        if (!r.ok) {
          let e;
          try {
            e = await r.text();
          } catch {
            e = `${r.status} ${r.statusText}`;
          }
          throw new Error(e);
        }
        return r.json();
      };
      setLoading(true);
      setErr(null);
      getOldVersionLink()
        .then((d) => {
          let e;
          try {
            window.open(d.oldVersionRef, '_blank').focus();
            onCloseEditor(id);
          } catch {
            e = 'В адресній строці необхідно дозволити відкривання нових вкладок';
          }
          throw new Error(e);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [id, meta.backendName, modelType, onCloseEditor, unlock],
  );

  const redo = useCallback(
    () => {
      setData(({ current, history }) => {
        if (history.pointer !== null) {
          if (history.data.length > history.pointer + 1) {
            return ({
              current: history.data[history.pointer + 1],
              history: {
                data: history.data,
                pointer: history.data.length > history.pointer + 2
                  ? history.pointer + 1
                  : null,
              },
            });
          }
        }
        return {
          current, history,
        };
      });
    },
    [],
  );

  const onSaveWithoutExit = useCallback(
    () => save(data.current, saveModes.Write, onSaveCallBack),
    [data, onSaveCallBack, save],
  );

  const onSaveNExit = useCallback(
    () => save(data.current, saveModes.Write, onCloseEditor),
    [data, onCloseEditor, save],
  );

  const onExecuteNExit = useCallback(
    () => save(data.current, saveModes.Posting, onCloseEditor),
    [data, onCloseEditor, save],
  );

  const onExecute = useCallback(
    () => save(data.current, saveModes.Posting, onSaveCallBack),
    [data, onSaveCallBack, save],
  );

  const onUnexecute = useCallback(
    () => save(data.current, saveModes.UndoPosting, onSaveCallBack),
    [data, onSaveCallBack, save],
  );

  const onGoToOldVersion = useCallback(
    () => goToOldVersion(),
    [goToOldVersion],
  );

  const onSaveNGoToOldVersion = useCallback(
    () => save(data.current, saveModes.Write, goToOldVersion),
    [data, goToOldVersion, save],
  );

  const initParams = useMemo(
    () => {
      const oneCDefaults = Object.keys(defaults)
        .filter((jsCol) => jsCol in meta.columns)
        .reduce((R, jsCol) => ({ ...R, [meta.columns[jsCol].name]: defaults[jsCol] }), {});

      // Параметры допустимы, только если это создание нового и то только один
      if (id !== 'create') return {};
      if (reason) return { defaults: oneCDefaults, reason };
      if (isGroup) return { defaults: oneCDefaults, isGroup };
      if (copyFrom) return { defaults: oneCDefaults, copyFrom };
      return { defaults: oneCDefaults };
    },
    [copyFrom, defaults, id, isGroup, meta.columns, reason],
  );

  const onReload = useCallback(
    () => {
      if (id) load(id, initParams);
    },
    [id, initParams, load],
  );

  useEffect(
    () => {
      if (['doc', 'cat'].includes(modelType)) onReload();
    },
    [modelType, onReload],
  );

  const readOnly = readOnlyGetter ? readOnlyGetter(data.current) : false;
  const permissions = useMemo(
    () => ({
      canSave: !readOnly && !loading,
      canUndo: !readOnly && data.history.pointer !== 0 && data.history.data.length > 1,
      canRedo: !readOnly && data.history.pointer !== null
          && data.history.pointer < data.history.data.length,
      canClose: !!onCloseEditor,
      canExecute: !readOnly && modelType === 'doc' && !loading,
      canUnexecute: !readOnly && modelType === 'doc' && !loading,
      canChange: !readOnly,
    }),
    [data.history.data.length, data.history.pointer, loading, modelType, onCloseEditor, readOnly],
  );

  const onClose = useCallback(
    () => onCloseEditor(),
    [onCloseEditor],
  );

  const actions = useMemo(
    () => ({
      onReload,
      onChange,
      onSaveWithoutExit,
      onSaveNExit,
      onExecuteNExit,
      onExecute,
      onUnexecute,
      onGoToOldVersion,
      onSaveNGoToOldVersion,
      onRedo: redo,
      onUndo: undo,
      onClose,
      onSRWithoutContext: SRWithoutContext,
      onSR: SR,
      onErr: setErr,
      onLoading: setLoading,
      onLoadAttachedFiles: loadAttachedFiles,
      onListAttachedFiles: listAttachedFiles,
      onDeleteAttachedFile: deleteAttachedFile,
      onUpLoadAttachedFiles: upLoadAttachedFiles,
    }),
    [SR, SRWithoutContext, deleteAttachedFile,
      listAttachedFiles, loadAttachedFiles, onChange, onClose,
      onExecute, onExecuteNExit, onReload, onSaveNExit,
      onSaveWithoutExit, onUnexecute, onGoToOldVersion,
      onSaveNGoToOldVersion, redo, undo, upLoadAttachedFiles],
  );

  return {
    data: data.current,
    loading,
    err,
    changed,
    permissions,
    actions,
  };
};

export default useEditor;
