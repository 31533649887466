import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const getDirection = (direction) => {
  switch (direction) {
  case 'left':
    return 'rotate(180deg)';
  case 'right':
    return 'rotate(0deg)';
  case 'up':
    return 'rotate(90deg)';
  case 'down':
    return 'rotate(270deg)';
  default:
    throw new Error(`Unknow direction ${direction}`);
  }
};

const Arrow = styled.div`
  transform: ${({ direction }) => getDirection(direction)};
  padding: 7px 5px;
  display: flex;
  width: fit-content;
`;

const UniArrows = ({ direction }) => (
  <Arrow direction={direction}>
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 0L16 6L8 12V8H0V4H8V0Z" fill="#4281C9" />
    </svg>
  </Arrow>
);

UniArrows.propTypes = {
  direction: PropTypes.oneOf([
    'left',
    'right',
    'up',
    'down',
  ]).isRequired,
};

export default UniArrows;
