import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

import ArrowUpIcon from '../../assets/icons/arrowUp';

const SwipeTopButton = ({ text, onClick, ...args }) => (
  <Button onClick={onClick} {...args} title="Перемістити вище">
    <ArrowUpIcon />
  </Button>
);

SwipeTopButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

SwipeTopButton.defaultProps = {
  text: 'Перемістити вище',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default SwipeTopButton;
