import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Cell from './cell';
import { PrintableRow } from './styles';

const Row = ({
  cells, tableName,
  activeCell, editMode, onActivateCell, onResizeColumn,
  fixedLeft, fixedTop, onReportDetails,
  tableTop, tableLeft,
}) => (
  <PrintableRow>
    {cells.map((cell) => (
      <Cell
        key={cell.name}
        name={cell.name}
        column={cell.col ? cell.col : 0}
        colSpan={cell.colspan ? cell.colspan : 0}
        rowSpan={cell.rowspan ? cell.rowspan : 0}
        content={cell.content ? cell.content : ''}
        styleId={cell.styleId ? cell.styleId : 0}
        decryption={cell.decryption ? cell.decryption : ''}
        tableName={tableName}
        active={(cell.col ? cell.col : 0) === activeCell}
        editMode={(cell.col ? cell.col : 0) === activeCell && editMode}
        onActivateCell={onActivateCell}
        onResizeColumn={onResizeColumn}
        fixedTop={fixedTop}
        fixedLeft={(cell.col ? cell.col : 0) <= fixedLeft}
        onReportDetails={onReportDetails}
        tableTop={tableTop}
        tableLeft={tableLeft}
      />
    ))}
  </PrintableRow>
);

Row.propTypes = {
  cells: PropTypes.instanceOf([]).isRequired,
  tableName: PropTypes.number.isRequired,
  activeCell: PropTypes.number,
  editMode: PropTypes.bool.isRequired,
  onActivateCell: PropTypes.func.isRequired,
  onResizeColumn: PropTypes.func.isRequired,
  fixedLeft: PropTypes.number.isRequired,
  fixedTop: PropTypes.bool.isRequired,
  onReportDetails: PropTypes.func.isRequired,
  tableTop: PropTypes.number,
  tableLeft: PropTypes.number,
};

Row.defaultProps = {
  activeCell: null,
  tableTop: 0,
  tableLeft: 0,
};

export default memo(Row);
