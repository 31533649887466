import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { postClassifier as md } from '../../../../constants/meta/catalogs/postClassifier';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const PostClassifier = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
        <Row>
            <Col>
                <EditorControls.TextInput
                    label={md.columns.FullName.label}
                    value={data[md.columns.FullName.name]}
                    onChange={(e, value) => actions.onChange({
                        [md.columns.FullName.name]: value,
                    })}
                    readOnly={readOnly}
                    rows={3}
                />
            </Col>
        </Row>
        <Row>
           <Col>
             <EditorControls.TextInput
                    label={md.columns.note.label}
                    value={data[md.columns.note.name]}
                    onChange={(e, value) => actions.onChange({
                        [md.columns.note.name]: value,
                    })}
                    readOnly={readOnly}
                    rows={3}
             />
           </Col>
        </Row>
        <Row>
         <Col>
            <EditorControls.StringInput
              label={md.columns.kpCode.label}
              value={data[md.columns.kpCode.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.kpCode.name]: value,
              })}
              maxLength={10}
              readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
};

PostClassifier.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PostClassifier;
