import React, { useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { modelType, modelName } from './def';
import TableEditor from '../../newTableEditor';
import {
  ResultSegment,
  ContainerDiv,
} from '../../../components/Form/styledForm';
import ReportContainer from '../reportContainer';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import { useMD } from '../../newLister/hooks/md';

const ReportEditor = ({
  isProcessing, portalOpened, actions, data, sessionOptions,
}) => {
  const md = useMD(modelType, modelName);
  // const readOnly = !sessionOptions.is_admin;
  const readOnly = false;

  // useEffect(() => {
  //   actions.changeField('isNewTypeSign', true);
  //   actions.changeField('isShowCredit', true);
  // }, [actions]);

  const csuFilter = [{ fieldName: 'Владелец', value: data[md.columns.csu.name] }];

  const repFormFilter = [{ fieldName: 'Владелец', value: data[md.columns.branches.name] }];

  const SettingsButton = (
    <>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.budget.name]}
            label={md.columns.budget.label}
            modelType="cat"
            modelName="budget"
            onChange={(e, value) => {
              actions.changeField([md.columns.csu.name], {});
              actions.changeField([md.columns.budget.name], value);
            }}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.csu.name]}
            label={md.columns.csu.label}
            modelType="cat"
            modelName="csu"
            onChange={async (e, value) => {
              await actions.changeField([md.columns.csu.name], value);
              actions.processServerReq('CSU_ON_CHANGE');
            }}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            value={data[md.columns.dDate.name]}
            label={md.columns.dDate.label}
            onChange={(e, value) => actions.changeField([md.columns.dDate.name], value)}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`changesDet-${data.id}`}
            label={md.columns.changesDet.label}
            value={data[md.columns.changesDet.name]}
            onChange={(e, value) => actions.changeField([md.columns.changesDet.name], value)}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.fond.name]}
            label={md.columns.fond.label}
            modelType="cat"
            modelName="fondClassifier"
            onChange={async (e, value) => {
              await actions.changeField([md.columns.fond.name], value);
              actions.processServerReq('FOND_ON_CHANGE');
            }}
            readOnly={readOnly}
            canErase
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.kbp.name]}
            label={md.columns.kbp.label}
            modelType="cat"
            modelName="kbpClassifier"
            onChange={(e, value) => actions.changeField([md.columns.kbp.name], value)}
            readOnly={readOnly}
            canErase
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.branches.name]}
            label={md.columns.branches.label}
            modelType="cat"
            modelName="branches"
            onChange={(e, value) => actions.changeField([md.columns.branches.name], value)}
            readOnly={readOnly}
            canErase
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.repForm.name]}
            label={md.columns.repForm.label}
            modelType="cat"
            modelName="repForm"
            onChange={async (e, value) => {
              await actions.changeField([md.columns.repForm.name], value);
              actions.processServerReq('RAPFORM_ON_CHANGE');
            }}
            readOnly={readOnly}
            filter={repFormFilter}
            noHierarchy
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.baseDoc.name]}
            label={md.columns.baseDoc.label}
            modelType="cat"
            modelName="baseDoc"
            onChange={async (e, value) => {
              await actions.changeField([md.columns.baseDoc.name], value);
              actions.processServerReq('BASEDOC_ON_CHANGE');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.signatureVariants.name]}
            label={md.columns.signatureVariants.label}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, value) => actions.changeField([md.columns.signatureVariants.name], value)}
            readOnly={readOnly}
            filter={csuFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`flState-${data.id}`}
            label={md.columns.flState.label}
            value={data[md.columns.flState.name]}
            onChange={(e, value) => actions.changeField([md.columns.flState.name], value)}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`onlyZero-${data.id}`}
            label={md.columns.onlyZero.label}
            value={data[md.columns.onlyZero.name]}
            onChange={(e, value) => actions.changeField([md.columns.onlyZero.name], value)}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.colRepeat.label}
            value={data[md.columns.colRepeat.name]}
            onChange={(e, value) => actions.changeField([md.columns.colRepeat.name], value)}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.row1.label}
            value={data[md.columns.row1.name]}
            onChange={(e, value) => actions.changeField([md.columns.row1.name], value)}
            readOnly={readOnly}
            rows={3}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.row2.label}
            value={data[md.columns.row2.name]}
            onChange={(e, value) => actions.changeField([md.columns.row2.name], value)}
            readOnly={readOnly}
            rows={3}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.row3.label}
            value={data[md.columns.row3.name]}
            onChange={(e, value) => actions.changeField([md.columns.row3.name], value)}
            readOnly={readOnly}
            rows={3}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.row4.label}
            value={data[md.columns.row4.name]}
            onChange={(e, value) => actions.changeField([md.columns.row4.name], value)}
            readOnly={readOnly}
            rows={3}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.orderText.label}
            value={data[md.columns.orderText.name]}
            onChange={(e, value) => actions.changeField([md.columns.orderText.name], value)}
            readOnly={readOnly}
            rows={3}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      actions={actions}
    >
      <ResultSegment>
        <TableEditor
          data={data}
          actions={actions}
        />
      </ResultSegment>
    </ReportContainer>
  );
};

ReportEditor.propTypes = {
  isProcessing: PropTypes.bool,
  portalOpened: PropTypes.bool,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  isProcessing: false,
};

// ReportEditor.displayName = `Report${md.name}Editor`;

export default ReportEditor;
