import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { SearchInput } from '../styledInputs';

export const ContainerHeader = styled.div`
    // margin-bottom: 20px;
`;

export const StyleInnerHeader = styled.h3`
    font-family: Roboto, sans-serif;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    color: #008F21;
    margin-bottom: 20px;
`;

// const FILTER_KEYS = ['label'];

const StyledMenuHeader = ({ inputValue, setInputValue, children }) => (
  <ContainerHeader>
    <StyleInnerHeader>{children}</StyleInnerHeader>
    <SearchInput
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
  </ContainerHeader>
);

StyledMenuHeader.propTypes = {
  children: PropTypes.node,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func.isRequired,
};

StyledMenuHeader.defaultProps = {
  children: null,
  inputValue: '',
};

export default StyledMenuHeader;
