import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/calcTarifList';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

const TPHeader = ({
  activeCol,
  highlights,
  onToggleHighlght,
}) => (
  <TableHeaderRow noGutters >
    <Col
      xl={12}
      sm={12}
      className="px-3  d-flex flex-column justify-content-end"
    >
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'stateUnits'}
            highlighted={highlights.includes('stateUnits')}
            onToggleHighlght={() => onToggleHighlght('stateUnits')}
          >
            {meta.columns.stateUnits.label}
          </TableHeaderCell>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'post'}
              highlighted={highlights.includes('post')}
              onToggleHighlght={() => onToggleHighlght('post')}
          >
            {meta.columns.post.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'category'}
            highlighted={highlights.includes('category')}
            onToggleHighlght={() => onToggleHighlght('category')}
          >
            {meta.columns.category.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'fond'}
              highlighted={highlights.includes('fond')}
              onToggleHighlght={() => onToggleHighlght('fond')}
          >
            {meta.columns.fond.label}
          </TableHeaderCell>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'num'}
              highlighted={highlights.includes('num')}
              onToggleHighlght={() => onToggleHighlght('num')}
          >
            {meta.columns.num.label}
          </TableHeaderCell>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'numHour'}
              highlighted={highlights.includes('numHour')}
              onToggleHighlght={() => onToggleHighlght('numHour')}
          >
            {meta.columns.numHour.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kbp'}
              highlighted={highlights.includes('kbp')}
              onToggleHighlght={() => onToggleHighlght('kbp')}
          >
            {meta.columns.kbp.label}
          </TableHeaderCell>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'numStateBase'}
              highlighted={highlights.includes('numStateBase')}
              onToggleHighlght={() => onToggleHighlght('numStateBase')}
          >
            {meta.columns.numStateBase.label}
          </TableHeaderCell>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'stateSolary'}
              highlighted={highlights.includes('stateSolary')}
              onToggleHighlght={() => onToggleHighlght('stateSolary')}
          >
            {meta.columns.stateSolary.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'ssize'}
              highlighted={highlights.includes('ssize')}
              onToggleHighlght={() => onToggleHighlght('ssize')}
          >
            {meta.columns.ssize.label}
          </TableHeaderCell>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'numMonth'}
              highlighted={highlights.includes('numMonth')}
              onToggleHighlght={() => onToggleHighlght('numMonth')}
          >
            {meta.columns.numMonth.label}
          </TableHeaderCell>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'madeMonth'}
              highlighted={highlights.includes('madeMonth')}
              onToggleHighlght={() => onToggleHighlght('madeMonth')}
          >
            {meta.columns.madeMonth.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'res'}
              highlighted={highlights.includes('res')}
              onToggleHighlght={() => onToggleHighlght('res')}
          >
            {meta.columns.res.label}
          </TableHeaderCell>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'baseDoc'}
              highlighted={highlights.includes('baseDoc')}
              onToggleHighlght={() => onToggleHighlght('baseDoc')}
          >
            {meta.columns.baseDoc.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
