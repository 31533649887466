import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  csu: { label: 'Розпорядник', name: 'Распорядитель' },
  dDate: { label: 'станом на:', name: 'НаДату' },
  repForm: { label: 'Форма звіту', name: 'ФормаОтчета' },
  branches: { label: 'Галузь', name: 'Отрасль' },
  baseDoc: { label: 'Підстава', name: 'ОснованиеПриказа' },
  signatureVariants: { label: 'Варіант підпису', name: 'Подпись' },
  fond: { label: 'Фонд', name: 'ЭлементФонд' },
  nameVar: { label: 'Наименование текущего варианта', name: 'НаименованиеТекущегоВарианта' },
  row1: { label: 'Рядок 1', name: 'ТекстШапки1' },
  row2: { label: 'Рядок 2', name: 'ТекстШапки2' },
  row3: { label: 'Рядок 3', name: 'ТекстШапки3' },
  units: { label: 'Структурні підрозділи', name: 'Подразделения' },
  in1000: { label: 'У тис.грн.', name: 'фл1000' },
  isRound: { label: 'Заокруглити', name: 'флОкр' },
  row4: { label: 'Рядок 4', name: 'ТекстПодвала' },
  m1: { label: '1-й місяць', name: 'Мес1' },
  m2: { label: '2-й місяць', name: 'Мес2' },
  m3: { label: '3-й місяць', name: 'Мес3' },
  m4: { label: '4-й місяць', name: 'Мес4' },
  m5: { label: '5-й місяць', name: 'Мес5' },
  m6: { label: '6-й місяць', name: 'Мес6' },
  m7: { label: '7-й місяць', name: 'Мес7' },
  m8: { label: '8-й місяць', name: 'Мес8' },
  m9: { label: '9-й місяць', name: 'Мес9' },
  m10: { label: '10-й місяць', name: 'Мес10' },
  m11: { label: '11-й місяць', name: 'Мес11' },
  m12: { label: '12-й місяць', name: 'Мес12' },
  flEnd: { label: 'Кількість шт.од.на кінець періоду', name: 'флКолКон' },
  year: { label: 'Рік', name: 'текГод' },
  baseDocFolder: { label: 'Заповнити з папки', name: 'ПапкаОснований' },
  koefYear: { label: 'Коэф год', name: 'КоэфГод' },
  kbp: { label: 'КБП', name: 'КБП' },
  csu2: { label: 'Установа', name: 'Распорядитель2' },
};

const definition = {
  label: 'Штатний розпис ОХОРОНА ЗДОРОВ\'Я',
  name: 'staffList',
  columns,
  frontend: 'rep/staffList',
  backendName: 'ШтатнаяРосписьГод',
  resultColumns: baseResultColumns,
};

export default definition;
