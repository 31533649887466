import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { category as md } from '../../../../constants/meta/catalogs/category';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { selectionMode } from '../../../../constants/meta/common';

const Category = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.Sort.label}
            value={data[md.columns.Sort.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Sort.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`NoVisible-${data.id}`}
            label={md.columns.NoVisible.label}
            value={data[md.columns.NoVisible.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NoVisible.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="category"
            readOnly={readOnly}
            choiceSettings={selectionMode.folders}
          />
        </Col>
      </Row>
    </Container>
  );
};

Category.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Category;
