import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import { referencePropType } from '../../newEditor/propTypes';

// const FI_PROP_NAME = 'ФинансовыйОрган';
const FI_PROP_NAME = 'Распорядитель';
const BUDGET_PROP_NAME = 'Бюджет';
const AUTHOR_PROP_NAME = 'Автор';

const BudgetFOUserEditorRow = ({
  data, onChange, readOnly,
}) => {
  const budget = data[BUDGET_PROP_NAME];
  // eslint-disable-next-line no-undef
  const FOFilter = useMemo(
    () => [{ fieldName: BUDGET_PROP_NAME, value: budget }],
    [budget],
  );

  return (
    <Row sm={3}>
      <Col>
        <EditorControls.ItemPicker
          value={data[BUDGET_PROP_NAME]}
          label="Бюджет"
          modelType="cat"
          modelName="budget"
          onChange={(e, value) => onChange({
            [FI_PROP_NAME]: {},
            [BUDGET_PROP_NAME]: value,
          })}
          readOnly={readOnly}
        />
      </Col>
      <Col>
        <EditorControls.ItemPicker
          value={data[FI_PROP_NAME]}
          label="Розпорядник"
          modelType="cat"
          modelName="csu"
          filter={FOFilter}
          onChange={(e, value) => onChange({
            [FI_PROP_NAME]: value,
          })}
          readOnly={readOnly}
        />
      </Col>
      <Col>
        <EditorControls.ItemPicker
          value={data[AUTHOR_PROP_NAME]}
          label="Автор"
          modelType="cat"
          modelName="users"
          onChange={(e, value) => onChange({
            [AUTHOR_PROP_NAME]: value,
          })}
          readOnly={readOnly}
        />
      </Col>
    </Row>
  );
};

BudgetFOUserEditorRow.propTypes = {
  data: PropTypes.shape({
    [FI_PROP_NAME]: referencePropType,
    [BUDGET_PROP_NAME]: referencePropType,
    [AUTHOR_PROP_NAME]: referencePropType,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

BudgetFOUserEditorRow.defaultProps = {
  readOnly: false,
};
export default BudgetFOUserEditorRow;
