import { useMemo } from 'react';

const DEFAULT_PREDICATE = 'default_';
/**
 * Возвращает параметры запроса в виде объекта
 * @param search
 * @returns {{
 *  reason: string | null Документ основание,
 *  isGroup: string | null Это группа,
 *  copyFrom: string | null Документ из которого копировать,
 *  defaults: {} Значения по умолчанию для нового элемента
 *  }}
 */
const useInitParams = (search) => useMemo(
  () => {
    const c = new URLSearchParams(search);
    const defaults = Array.from(c.keys())
      .filter((k) => k.startsWith(DEFAULT_PREDICATE))
      .reduce((R, k) => ({ ...R, [k.replace(DEFAULT_PREDICATE, '')]: c.get(k) }), {});
    return {
      ...(c.get('reason') ? { reason: c.get('reason') } : {}),
      isGroup: c.get('g'),
      copyFrom: c.get('copyFrom'),
      defaults,
    };
  },
  [search],
);

export default useInitParams;
