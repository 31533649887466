import Seniority from './seniority';
import RepTypeCharges from './repTypeCharges';
import Applicate from './applicate';
import ApplicateYearNew from './applicateYearNew';
import IndicatorsStartYear from './indicatorsStartYear';
import StaffList from './staffList';
import NumberApprovedDocs from './numberApprovedDocs';
import StateData from './stateData';

const repComponents = {
  seniority: {
    editor: Seniority,
  },
  repTypeCharges: {
    editor: RepTypeCharges,
  },
  applicate: {
    editor: Applicate,
  },
  applicateYearNew: {
    editor: ApplicateYearNew,
  },
  indicatorsStartYear: {
    editor: IndicatorsStartYear,
  },
  staffList: {
    editor: StaffList,
  },
  numberApprovedDocs: {
    editor: NumberApprovedDocs,
  },
  stateData: {
    editor: StateData,
  },
};

export {
  repComponents,
  Seniority,
  RepTypeCharges,
  Applicate,
  ApplicateYearNew,
  IndicatorsStartYear,
  StaffList,
  NumberApprovedDocs,
  StateData,
};

export default {
  repComponents,
  Seniority,
  RepTypeCharges,
  Applicate,
  ApplicateYearNew,
  IndicatorsStartYear,
  StaffList,
  NumberApprovedDocs,
  StateData,
};
