import React from 'react';

const OpenedFolder = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="#fffead" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 5.5V2.5H9L10 4.5H17.5V16.5" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.5 16.5L16.5 7.5H9L8 5.5H2.5L3.5 16.5H17.5Z" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default OpenedFolder;
