import React from 'react';
import PropTypes from 'prop-types';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';

const ExecuteNExitButton = ({ disabled, onClick, label }) => (
  <DefaultCPButton
    onClick={onClick}
    label={label}
    disabled={disabled}
    variant="primary"
    icon={faCheckDouble}
    showLabel
  />
);

ExecuteNExitButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

ExecuteNExitButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Провести та закрити',
};

export default ExecuteNExitButton;
