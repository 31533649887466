import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  Sort: { label: 'Код для сортування', name: 'Сорт', visible: true },
  NoVisible: {
    name: 'флНеПоказыватьВСпискеВыбора',
    label: 'Не показувати у списку вибору документа',
    visible: false,
  },
};

const footer = { ...columns };
/**
 * @const
 */
export const category = {
  label: 'Категорії',
  name: 'category',
  backendName: 'Категории',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/category',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'Sort',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default category;
