import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { repForm as md } from '../../../../constants/meta/catalogs/repForm';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';


const RepForm = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
              label={md.columns.str1.label}
              value={data[md.columns.str1.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.str1.name]: value,
              })}
              maxLength={350}
              readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
            <Col>
                <EditorControls.StringInput
                    label={md.columns.str2.label}
                    value={data[md.columns.str2.name]}
                    onChange={(e, value) => actions.onChange({
                        [md.columns.str2.name]: value,
                    })}
                    maxLength={350}
                    readOnly={readOnly}
                />
            </Col>
      </Row>
      <Row>
            <Col>
                <EditorControls.CheckboxInput
                    controlId={`flDecl-${data.id}`}
                    label={md.columns.flDecl.label}
                    value={data[md.columns.flDecl.name]}
                    onChange={(e, value) => actions.onChange({
                        [md.columns.flDecl.name]: value,
                    })}
                    readOnly={readOnly}
                />
            </Col>
      </Row>
      <Row>
            <Col>
                <EditorControls.CheckboxInput
                    controlId={`flVis-${data.id}`}
                    label={md.columns.flVis.label}
                    value={data[md.columns.flVis.name]}
                    onChange={(e, value) => actions.onChange({
                        [md.columns.flVis.name]: value,
                    })}
                    readOnly={readOnly}
                />
            </Col>
      </Row>
      <Row>
            <Col>
                <EditorControls.CheckboxInput
                    controlId={`noRep-${data.id}`}
                    label={md.columns.noRep.label}
                    value={data[md.columns.noRep.name]}
                    onChange={(e, value) => actions.onChange({
                        [md.columns.noRep.name]: value,
                    })}
                    readOnly={readOnly}
                />
            </Col>
      </Row>
      <Row>
            <Col>
                <EditorControls.CheckboxInput
                    controlId={`regProtect-${data.id}`}
                    label={md.columns.regProtect.label}
                    value={data[md.columns.regProtect.name]}
                    onChange={(e, value) => actions.onChange({
                        [md.columns.regProtect.name]: value,
                    })}
                    readOnly={readOnly}
                />
            </Col>
      </Row>
    </Container>
  );
};

RepForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default RepForm;
