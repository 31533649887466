import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  csu: { label: 'Розпорядник', name: 'Распорядитель' },
  dDate: { label: 'станом на:', name: 'НаДату' },
  repForm: { label: 'Форма звіту', name: 'ФормаОтчета' },
  changesDet: { label: 'Деталізація змін', name: 'ДетализацияИзменений' },
  branches: { label: 'Галузь', name: 'Отрасль' },
  stateUnits: { label: 'Штатна одиниця', name: 'ШтатнаяЕдиница' },
  baseDoc: { label: 'Підстава', name: 'ОснованиеПриказа' },
  signatureVariants: { label: 'Варіант підпису', name: 'Подпись' },
  fond: { label: 'Фонд', name: 'ЭлементФонд' },
  nameVar: { label: 'Наименование текущего варианта', name: 'НаименованиеТекущегоВарианта' },
  row1: { label: 'Рядок 1', name: 'ТекстШапки1' },
  row2: { label: 'Рядок 2', name: 'ТекстШапки2' },
  row3: { label: 'Рядок 3', name: 'ТекстШапки3' },
  units: { label: 'Структурні підрозділи', name: 'Подразделения' },
  in1000: { label: 'У тис.грн.', name: 'фл1000' },
  isRound: { label: 'Заокруглити', name: 'флОкр' },
  row4: { label: 'Рядок 4', name: 'ТекстПодвала' },
  repSettings: { label: 'Варіанти налаштування звітів', name: 'НастрокийОтчетов' },
  flState: { label: 'За формою штатного розпису (галузь "Управління")', name: 'флШтРосп' },
  orderText: { label: 'Текст наказу', name: 'ТекстПриказа' },
  colRepeat: { label: 'Повторювати при друку колонки', name: 'ПовторятьПриПечатиКолонки' },
  onlyZero: { label: 'Не виводити у звіт колонки без даних', name: 'ТолькоНеНулевыеКолонки' },
  kbp: { label: 'КБП', name: 'КБП' },
};

const definition = {
  label: 'Звіт за місяць',
  name: 'applicate',
  columns,
  frontend: 'rep/applicate',
  backendName: 'Приложения',
  resultColumns: baseResultColumns,
};

export default definition;
