import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { firstIndicators as md } from '../../../../constants/meta/catalogs/firstIndicators';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const FirstIndicators = ({ data, actions, permissions }) => {
    const readOnly = !permissions.canChange;

    return (
        <Container fluid>
            <Row>
                <Col>
                    <EditorControls.StringInput
                        label={md.columns.name.label}
                        value={data[md.columns.name.name]}
                        onChange={(e, value) => actions.onChange({
                            [md.columns.name.name]: value,
                        })}
                        readOnly={readOnly}
                        maxLength={150}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <EditorControls.StringInput
                        label={md.columns.sort.label}
                        value={data[md.columns.sort.name]}
                        onChange={(e, value) => actions.onChange({
                            [md.columns.sort.name]: value,
                        })}
                        readOnly={readOnly}
                        maxLength={150}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <EditorControls.NumberInput
                        label={md.columns.typeSort.label}
                        value={data[md.columns.typeSort.name]}
                        onChange={(e, value) => actions.onChange({
                            [md.columns.typeSort.name]: value,
                        })}
                        readOnly={readOnly}
                    />
                </Col>
            </Row>
        </Container>
    );
};

FirstIndicators.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string,
        repr: PropTypes.string,
        [md.columns.code.name]: PropTypes.string,
        [md.columns.name.name]: PropTypes.string,
    }).isRequired,
    actions: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        onSR: PropTypes.func.isRequired,
    }).isRequired,
    permissions: PropTypes.shape({
        canChange: PropTypes.bool.isRequired,
    }).isRequired,
};

export default FirstIndicators;
