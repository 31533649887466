import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  author: { label: 'Автор', name: 'Автор', visible: false },
  dCreate: { label: 'Дата создания', name: 'ДатаСоздания', visible: false },
  authorCh: { label: 'Автор изменения', name: 'АвторИзменения', visible: false },
  dCh: { label: 'Дата изменения', name: 'ДатаИзменения', visible: false },
  comment: { label: 'Комментарий', name: 'Комментарий', visible: false },
  dBorn: { label: 'ДатаРождения', name: 'ДатаРождения', visible: false },
  INN: { label: 'ІПН', name: 'ИНН', visible: false },
  post: { label: 'Должность', name: 'Должность', visible: false },
  sex: { label: 'Стать', name: 'Пол', visible: false },
  placeBorn: { label: 'МестоРождения', name: 'МестоРождения', visible: false },
  idPerson: { label: 'УдостоверениеЛичности', name: 'УдостоверениеЛичности', visible: false },
  codePD: { label: 'КодПоДРФО', name: 'КодПоДРФО', visible: false },
  note: { label: 'Примечание', name: 'Примечание', visible: false },
  mainFace: { label: 'ОсновноеИзображение', name: 'ОсновноеИзображение', visible: false },
  bankCard: { label: 'НомерБанковскойКарточки', name: 'НомерБанковскойКарточки', visible: false },
  grInd: { label: 'ГруппаФизическихЛиц', name: 'ГруппаФизическихЛиц', visible: false },
  experience: { label: 'СтажДоПриемаНаРаботу', name: 'СтажДоПриемаНаРаботу', visible: false },
  educationI: { label: 'Освіта', name: 'ОбразованиеФЛ', visible: false },
  familyStatus: { label: 'Сімейний статус', name: 'СемейноеПоложение', visible: false },
  inscriptionMain: { label: 'НадписьСтажОсновной', name: 'НадписьСтажОсновной', visible: false },
  inscriptionAll: { label: 'НадписьСтажОбщий', name: 'НадписьСтажОбщий', visible: false },
  inscription308: { label: 'НадписьСтаж308', name: 'НадписьСтаж308', visible: false },
  code: { label: 'ІПН', name: 'Код', visible: true },
  isWeb: { label: 'ЭтоВэб', name: 'ЭтоВэб', visible: false },
  canEdit: { label: 'ЗапретРезактироватьПользователю', name: 'ЗапретРезактироватьПользователю', visible: false },
  countYear: { label: 'років:', name: 'расчетГод', visible: false },
  countMonth: { label: 'місяців:', name: 'расчетМесяц', visible: false },
  countDay: { label: 'днів:', name: 'расчетДень', visible: false },
  countDate: { label: 'Стаж на:', name: 'расчетДата', visible: false },
};

const tables = {
  familySt: {
    label: 'Склад сімї фіз. особи',
    name: 'СоставСемьи',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      stageF: { label: 'СтепеньРодства', name: 'СтепеньРодства' },
      fiz: { label: 'Физическое лицо', name: 'ФизЛицо' },
      yBorn: { label: 'Год рождения родственника', name: 'ГодРождения' },
      tuni: { label: 'На иждивении', name: 'Иждивенец' },
    },
  },
  educationF: {
    label: 'Освіта фіз.особи',
    name: 'Образование',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      eType: { label: 'ВидОбразования', name: 'ВидОбразования' },
      instit: { label: 'УчебноеЗаведение', name: 'УчебноеЗаведение' },
      spec: { label: 'Специальность по образованию', name: 'Специальность' },
      dipl: { label: 'Диплом', name: 'Диплом' },
      yEnd: { label: 'ГодОкончания', name: 'ГодОкончания' },
      qual: { label: 'Квалификация', name: 'Квалификация' },
      facult: { label: 'Факультет', name: 'Факультет' },
      degree: { label: 'УченаяСтепень', name: 'УченаяСтепень' },
      fEducation: { label: 'ФормаОбучения', name: 'ФормаОбучения' },
    },
  },
  employment: {
    label: 'Стаж для вислуги років',
    name: 'ТрудоваяДеятельность',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      org: { label: 'Місце роботи', name: 'Организация' },
      dStart: { label: 'Дата початку', name: 'ДатаНачала' },
      dEnd: { label: 'Дата кінця', name: 'ДатаОкончания' },
      post: { label: 'Посада', name: 'Должность' },
      emplType: { label: 'Вид стажу', name: 'ВидСтажа' },
    },
  },
  employmentMain: {
    label: 'Стаж для вислуги років',
    name: 'ТрудоваяДеятельностьОсновной',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      org: { label: 'Місце роботи', name: 'Организация' },
      dStart: { label: 'Дата початку', name: 'ДатаНачала' },
      dEnd: { label: 'Дата кінця', name: 'ДатаОкончания' },
      post: { label: 'Посада', name: 'Должность' },
      emplType: { label: 'Вид стажу', name: 'ВидСтажа' },
    },
  },
  employmentAll: {
    label: 'Загальний стаж',
    name: 'ТрудоваяДеятельностьОбщий',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      org: { label: 'Місце роботи', name: 'Организация' },
      dStart: { label: 'Дата початку', name: 'ДатаНачала' },
      dEnd: { label: 'Дата кінця', name: 'ДатаОкончания' },
      post: { label: 'Посада', name: 'Должность' },
      emplType: { label: 'Вид стажу', name: 'ВидСтажа' },
    },
  },
  employment308: {
    label: 'Стаж 308/519, інший',
    name: 'ТрудоваяДеятельность308',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      org: { label: 'Місце роботи', name: 'Организация' },
      dStart: { label: 'Дата початку', name: 'ДатаНачала' },
      dEnd: { label: 'Дата кінця', name: 'ДатаОкончания' },
      post: { label: 'Посада', name: 'Должность' },
      emplType: { label: 'Вид стажу', name: 'ВидСтажа' },
    },
  },
  languageI: {
    label: 'Знання мов',
    name: 'ЗнаниеЯзыков',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      lang: { label: 'Мова', name: 'Язык' },
      langStr: { label: 'Ступінь знання мови', name: 'СтепеньЗнанияЯзыка' },
    },
  },
  professionI: {
    label: 'Професії',
    name: 'Профессии',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      prof: { label: 'Професія', name: 'Профессия' },
    },
  },
  experienceI: {
    label: 'Стажи',
    name: 'Стажи',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      expType: { label: 'Вид стажу', name: 'ВидСтажа' },
      dStart: { label: 'Дата початку відліку', name: 'ДатаОтсчета' },
      mSize: { label: 'Розмір місяців', name: 'РазмерМесяцев' },
      dSize: { label: 'Розмір днів', name: 'РазмерДней' },
    },
  },
  experienceMain: {
    label: 'Загальний стаж',
    name: 'ОбщийСтаж',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      org: { label: 'Попереднє місце роботи', name: 'Организация' },
      dStart: { label: 'Дата початку', name: 'ДатаНачала' },
      dFin: { label: 'Дота закінчення', name: 'ДатаОкончания' },
      post: { label: 'Посада на попередньому місці роботи', name: 'Должность' },
    },
  },
  rasp: {
    label: 'Распорядители',
    name: 'Распорядители',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      rasp: { label: 'Роспорядник', name: 'Распорядитель' },
    },
  },
  postI: {
    label: 'Посади, які потребують підтвердження кваліфікації',
    name: 'Должности',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      post: { label: 'Посада', name: 'Должность' },
      dateTo: { label: 'Підтверджено до', name: 'ДатаДействия' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

const footer = { ...columns };
/**
 * @const
 */
export const individuals = {
  label: 'Фізичні особи',
  name: 'individuals',
  backendName: 'ФизическиеЛица',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables,
  footer,
  frontend: 'cat/individuals',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default individuals;
