import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: false },
  owner: {
    label: 'Розпорядник',
    name: 'Владелец',
    visible: true,
  },
};

const tables = {
  variantOfSignature: {
    label: 'Варіанти підписів',
    name: 'ВариантыПодписей',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      Official: { label: 'Посадова особа', name: 'ДолжностноеЛицо' },
      SignatureType: { label: 'Вид підпису', name: 'ВидПодписи' },
    },
  },
};

const footer = { ...columns };
/**
 * @const
 */

export const signatureVariants = {
  label: 'Варіанти підписів',
  name: 'signatureVariants',
  backendName: 'ВариантыПодписейФинансовогоОргана',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables,
  footer,
  frontend: 'cat/signatureVariants',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default signatureVariants;
