import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/catalogs/individuals';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.educationF;

const TPHeader = ({
  activeCol,
  highlights,
  onToggleHighlght,
}) => (
  <TableHeaderRow noGutters >
    <Col
      xl={12}
      sm={12}
      className="px-3  d-flex flex-column justify-content-end"
    >
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'eType'}
            highlighted={highlights.includes('eType')}
            onToggleHighlght={() => onToggleHighlght('eType')}
          >
            {meta.columns.eType.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'instit'}
            highlighted={highlights.includes('instit')}
            onToggleHighlght={() => onToggleHighlght('instit')}
          >
            {meta.columns.instit.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'spec'}
              highlighted={highlights.includes('spec')}
              onToggleHighlght={() => onToggleHighlght('spec')}
          >
            {meta.columns.spec.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'dipl'}
              highlighted={highlights.includes('dipl')}
              onToggleHighlght={() => onToggleHighlght('dipl')}
          >
            {meta.columns.dipl.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'yEnd'}
              highlighted={highlights.includes('yEnd')}
              onToggleHighlght={() => onToggleHighlght('yEnd')}
          >
            {meta.columns.yEnd.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'qual'}
              highlighted={highlights.includes('qual')}
              onToggleHighlght={() => onToggleHighlght('qual')}
          >
            {meta.columns.qual.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'facult'}
              highlighted={highlights.includes('facult')}
              onToggleHighlght={() => onToggleHighlght('facult')}
          >
            {meta.columns.facult.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'degree'}
              highlighted={highlights.includes('degree')}
              onToggleHighlght={() => onToggleHighlght('degree')}
          >
            {meta.columns.degree.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'fEducation'}
              highlighted={highlights.includes('fEducation')}
              onToggleHighlght={() => onToggleHighlght('fEducation')}
          >
            {meta.columns.fEducation.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
