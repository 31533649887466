/**
 * @param r {Promise}
 * @param r.status {integer}
 * @return {Promise<any>}
 */
const successOrFailJSONParse = async (r) => {
  if (r.status < 300) {
    return r.json();
  }
  throw new Error('Error has been occurred');
};

/**
 * @desc Function for request token & session options
 * @function
 * @param srv {string}
 * @param auth {object}
 * @param auth.login {string}
 * @param auth.pwd {string}
 * @return {Promise}
 */

export const tryLogin = async ({
  srv, login, pwd, backEnd,
}) => fetch(`${backEnd}api/auth/`, {
  method: 'POST',
  mode: 'cors',
  credentials: 'include',
  headers: { 'Content-Type': 'application/json; charset=utf-8' },
  body: JSON.stringify({ login, pwd, srv }),
});

export const sessionCheck = (token, backEnd) => fetch(`${backEnd}api/req/auth/login/`, {
  method: 'POST',
  mode: 'cors',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Bearer ${token}`,
  },
}).then(successOrFailJSONParse);

export const tryRefresh = async ({
  backEnd, refreshToken,
}) => fetch(`${backEnd}api/refresh/`, {
  method: 'POST',
  mode: 'cors',
  credentials: 'include',
  headers: { 'Content-Type': 'application/json; charset=utf-8' },
  body: JSON.stringify({ token: refreshToken }),
});

export const tryLogout = async ({
  backEnd, accessToken,
}) => fetch(`${backEnd}api/logout/`, {
  method: 'POST',
  mode: 'cors',
  credentials: 'include',
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json; charset=utf-8',
  },
});
