import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { csuGroup as md } from '../../../../constants/meta/catalogs/csuGroup';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const CsuGroup = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
      <Container fluid>
        <Row>
          <Col>
            <EditorControls.StringInput
                label={md.columns.code.label}
                value={data[md.columns.code.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.code.name]: value,
                })}
                maxLength={9}
                readOnly
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.StringInput
                label={md.columns.name.label}
                value={data[md.columns.name.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.name.name]: value,
                })}
                maxLength={150}
                readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.ItemPicker
                label={md.columns.owner.label}
                value={data[md.columns.owner.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.owner.name]: value,
                })}
                modelType="cat"
                modelName="csu"
                readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.ItemPicker
                label={md.columns.parent.label}
                value={data[md.columns.parent.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.parent.name]: value,
                })}
                modelType="cat"
                modelName="csuGroup"
                readOnly={readOnly}
            />
          </Col>
        </Row>
      </Container>
  );
};

CsuGroup.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default CsuGroup;
