import React, { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

const StringInput = forwardRef(({
  value, disabled, readOnly,
  onClick, onFocus, maxLength, onChange,
  errored, tabIndex,
}, ref) => {
  const changeHandler = (e) => {
    const newValue = e.target.value;
    if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
      onChange(e, newValue);
    }
  };
  const displayValue = useMemo(
    () => value || '',
    [value],
  );
  return (
    <FormControl
      ref={ref}
      value={displayValue}
      onChange={changeHandler}
      disabled={disabled}
      readOnly={readOnly}
      onClick={onClick}
      onFocus={onFocus}
      isInvalid={errored}
      tabIndex={tabIndex}
    />
  );
});

StringInput.propTypes = {
  value: PropTypes.string,
  tabIndex: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  errored: PropTypes.bool,

};

StringInput.defaultProps = {
  value: '',
  tabIndex: null,
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  errored: false,
};

export default memo(StringInput);
