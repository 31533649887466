/* eslint-disable import/no-named-as-default */
import budget from './budget';
import firstIndicators from './firstIndicators';
import users from './users';
import titleSheetVariant from './titleSheetVariant';
import baseDoc from './baseDoc';
import csu from './CSU';
import elementFond from './elementFond';
import fondClassifier from './fondClassifier';
import kbpClassifier from './kbpClassifier';
import responsiblePerson from './responsiblePerson';
import units from './units';
import unitsCategory from './unitsCategory';
import branches from './branches';
import category from './category';
import basis from './basis';
import stateUnits from './stateUnits';
import post from './post';
import postClassifier from './postClassifier';
import tarif from './tarif';
import pedagogicalLoad from './pedagogicalLoad';
import individuals from './individuals';
import familyStatus from './familyStatus';
import education from './education';
import experienceTypes from './experienceTypes';
import csuLvl from './csuLvl';
import kvkClassifier from './kvkClassifier';
import banks from './banks';
import externalReports from './externalReports';
import signatureVariants from './signatureVariants';
import repForm from './repForm';
import basisDocs from './basisDocs';
import baseDocYear from './baseDocYear';
import csuGroup from './csuGroup';
import reportSettings from './reportSettings';

const catalogs = {
  firstIndicators,
  budget,
  users,
  responsiblePerson,
  csu,
  baseDoc,
  titleSheetVariant,
  elementFond,
  fondClassifier,
  kbpClassifier,
  units,
  unitsCategory,
  branches,
  category,
  basis,
  stateUnits,
  post,
  postClassifier,
  tarif,
  pedagogicalLoad,
  individuals,
  familyStatus,
  education,
  experienceTypes,
  csuLvl,
  kvkClassifier,
  banks,
  externalReports,
  signatureVariants,
  repForm,
  basisDocs,
  baseDocYear,
  csuGroup,
  reportSettings,
};

export const cat1c = new Proxy({}, {
  get(_, prop) {
    const cat = Object.keys(catalogs)
      .filter((d) => catalogs[d].backendName.toLowerCase() === prop.toLowerCase());
    if (cat.length === 1) return catalogs[cat[0]];
    throw new Error('Property {prop} not found in documents!');
  },
});

export default catalogs;
