import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const DivRequired = styled.div.attrs(({ required }) => ({
  style: {
    'border-bottom': required ? '1px dashed red' : 'none',
  },
}))``;

export const ItemField = forwardRef(({
  value, children, isLoading, editText, onChange, readOnly,
  onClick, onFocus, errored, className, prepend, required, placeholder, tabIndex,
}, ref) => {
  const groupClassName = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => `${className} ${errored ? 'is-invalid' : ''}`,
    [className, errored],
  );

  return (
    <DivRequired required={required}>
      <InputGroup className={groupClassName}>

        {prepend}

        <FormControl
          ref={ref}
          value={editText === null ? ((value && value.repr) || '') : editText}
          readOnly={readOnly}
          onClick={onClick}
          onFocus={onFocus}
          onChange={(e) => onChange(e, e.target.value)}
          isInvalid={errored}
          placeholder={placeholder}
          tabIndex={tabIndex}
        />

        {isLoading && (
          <InputGroup.Text className="bg-white">
            <Spinner animation="border" size="sm" />
          </InputGroup.Text>
        )}
        {children}

      </InputGroup>
    </DivRequired>

  );
});

export const valuePropType = PropTypes.shape({
  id: PropTypes.string,
  repr: PropTypes.string,
});

ItemField.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  value: valuePropType,
  editText: PropTypes.string,
  tabIndex: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  errored: PropTypes.bool,
  className: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

ItemField.defaultProps = {
  value: { id: null, repr: '' },
  isLoading: false,
  tabIndex: null,
  children: null,
  editText: null,
  onChange: () => null,
  readOnly: false,
  onClick: null,
  onFocus: null,
  errored: false,
  className: null,
  prepend: null,
  required: false,
  placeholder: '',
};

export default ItemField;
