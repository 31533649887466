import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CancelRequestButton } from '../../../components/button';
import { CommandPanelButtonText } from '../../../components/Form/styledForm';
import PortalContainer from '../../../components/ReportSettings';
// import LoadReportVariant from '../../catalogs/reportSettings/loadVariantButton';
// import SaveReportVariant from '../../catalogs/reportSettings/saveVariantButton';

const ContainerButton = styled.div`
  display: inline-flex;
`;

const Container = styled.div`
  margin-top: 10px;
  display: flex;
  background: #e9f0f85e;
  border-radius: 4px;
  border: 1px solid #cbcbe4;
  align-items: baseline
`;
const Text = styled.span`
  margin-left: 10px;
  margin-top: 25px;
  color: #4281C9;
  font-weight: 700;
  font-size: 15px;
`;

export const BigDivLoadStyled = styled.div`
  margin: 30px;          
  width: 11px;  
  height: 11px;
  border-radius: 100%;
  box-shadow: 3px 3px #486498, -3px 3px #90c8f0, -3px -3px #486498, 3px -3px #dfdfdf;
`;

const Loading = ({ children }) => (
  <Container>
    <BigDivLoadStyled />
    <Text>Зачекайте, будь ласка...</Text>
    {children}
  </Container>
);

const ReportContainer = ({
  children, isProcessing, portalOpened, SettingButton, ExtraButtons, actions,
}) => {
  const [updated, setUpdated] = useState(false);
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <CommandPanelButtonText
          disabled={isProcessing}
          text="Сформувати"
          onClick={() => {
            setUpdated(true);
            actions.generate();
          }}
        >
          Сформувати
        </CommandPanelButtonText>
        {ExtraButtons && <ExtraButtons />}
        {SettingButton && (
          <PortalContainer isOpened={portalOpened} disabled={isProcessing}>
            {SettingButton}
          </PortalContainer>
        )}
        {/* <ContainerButton> */}
        {/*  <LoadReportVariant */}
        {/*    disabled={isProcessing} */}
        {/*    reportKey={`Отчеты.${backendName}.reactReport`} */}
        {/*    onLoad={(e, variant) => actions.processServerReq('LoadVariant', { variant })} */}
        {/*  /> */}
        {/*  <SaveReportVariant */}
        {/*    disabled={isProcessing} */}
        {/*    reportKey={`Отчеты.${backendName}.reactReport`} */}
        {/*    onSave={(e, nameVariant, commentVariant) => actions.processServerReq('SaveVariant', { nameVariant, commentVariant })} */}
        {/*  /> */}
        {/* </ContainerButton> */}
        <CommandPanelButtonText
          disabled={isProcessing}
          text="До попередньої версії"
          onClick={() => {
            actions.goToOldVersion();
          }}
        >
          До попередньої версії
        </CommandPanelButtonText>
      </div>

      {isProcessing && !updated && <Loading />}
      {isProcessing && updated && (
        <Loading>
          <>
            {/* <CancelRequestTitle>Воно того варте! </CancelRequestTitle> */}
            <CancelRequestButton
              onClick={() => {
                setUpdated(false);

                actions.cancelRequest();
              }}
            >
              Скасувати запит
            </CancelRequestButton>
          </>
        </Loading>
      )}
      {children}
    </div>

  );
};

ReportContainer.propTypes = {
  children: PropTypes.node,
  ExtraButtons: PropTypes.func,
  SettingButton: PropTypes.element,
  isProcessing: PropTypes.bool,
  portalOpened: PropTypes.bool,
  actions: PropTypes.instanceOf([]).isRequired,
};

ReportContainer.defaultProps = {
  isProcessing: false,
  portalOpened: false,
  children: null,
  SettingButton: null,
  ExtraButtons: null,
};

export default ReportContainer;
