import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  csu: { label: 'Розпорядник', name: 'Распорядитель' },
  dDate: { label: 'станом на:', name: 'НаДату' },
  basis: { label: 'Підстава', name: 'ОснованиеПриказа' },
  csu2: { label: 'Установа', name: 'Установа' },
  kbp: { label: 'КБП', name: 'КБП' },
  fond: { label: 'Фонд', name: 'Фонд' },
};

const definition = {
  label: 'Звіт щодо кількісті затверджених документів',
  name: 'numberApprovedDocs',
  columns,
  frontend: 'rep/numberApprovedDocs',
  backendName: 'КоличествоУтвержденныхТарификационныхСписков',
  resultColumns: baseResultColumns,
};

export default definition;
