import { buildModes } from './common';

// Тут только ГОРОДА-КЛИЕНТЫ БМ!!!!!

export const cityDbOptions = [
  {
    text: 'Борислав',
    value: 'srv81.ciat.net.ua/Boryslav_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Запоріжжя',
    value: 'zaporizzhya.ciat.net.ua/Billing',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Івано-Франківськ',
    value: 'app.ivano-frankivsk.ciat.net.ua/IvFrTarif',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Ірпінь',
    value: 'irpen.ciat.net.ua/Billing',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Київ охорона здор.',
    value: 'kyiv.ciat.net.ua/KyivZdravTarif',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Київ',
    value: 'tarifkyiv.ciat.net.ua/TarifKyiv',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Ковель',
    value: 'srv81.ciat.net.ua/Kovel_Tarif',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Кременчук',
    value: 'srv81.ciat.net.ua/KremTarif',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Луцьк',
    value: 'lutsk.ciat.net.ua/Billing',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Одеса',
    value: 'odessa.ciat.net.ua/Billing',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Прилуки',
    value: 'srv81.ciat.net.ua/Pryluky_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Тернопіль',
    value: 'srv81.ciat.net.ua/TarifTern',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Тетіїв',
    value: 'srv81.ciat.net.ua/Tetiiv_Tarif',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Трускавець',
    value: 'srv81.ciat.net.ua/TruskavetsTarif',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Чорноморськ',
    value: 'srv81.ciat.net.ua/Chernomorsk_tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
];

export default cityDbOptions;
