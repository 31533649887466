import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const SearchInput = ({ value, onChange, disabled }) => (
  <InputGroup>
    <Form.Control
      className="search"
      value={value}
      onChange={(e) => onChange(e, e.target.value)}
      placeholder="Пошук (ctrl+F)"
      disabled={disabled}
    />
    <Button variant="outline-secondary" disabled={disabled} onClick={(e) => onChange(e, '')}>
      <FontAwesomeIcon icon={faTimes} />
    </Button>
  </InputGroup>
);

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SearchInput.defaultProps = {
  disabled: false,
  value: '',
};

export default SearchInput;
