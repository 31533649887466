import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';
import { stateUnits as md } from '../../../../constants/meta/catalogs/stateUnits';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { TarifsTP, VocationsTP } from './tabs';

const StateUnits = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      {(data[md.columns.blockSpanVisible.name]) && (
        <Row>
          <Col>
            {/* eslint-disable-next-line max-len */}
            <span>Дана картка присутня в затверджених тарифікаційних списках. Можливість редагування обмежена.</span>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly || data[md.columns.nameReadOnly.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly || !data[md.columns.ownerAvialable.name]}
          />
        </Col>
      </Row>
      <Tabs>
        <Tab title="Основні реквізити" eventKey="tarifs">
          <Container fluid>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.branches.label}
                  value={data[md.columns.branches.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.branches.name]: value,
                  })}
                  modelType="cat"
                  modelName="branches"
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.post.label}
                  value={data[md.columns.post.name]}
                  onChange={async (e, value) => {
                    await actions.onChange({
                      [md.columns.post.name]: value,
                    });
                    actions.onSR('POST_ON_CHANGE');
                  }}
                  modelType="cat"
                  modelName="post"
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.tarif.label}
                  value={data[md.columns.tarif.name]}
                  onChange={async (e, value) => {
                    await actions.onChange({
                      [md.columns.tarif.name]: value,
                    });
                    actions.onSR('TARIF_ON_CHANGE');
                  }}
                  modelType="cat"
                  modelName="tarif"
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.category.label}
                  value={data[md.columns.category.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.category.name]: value,
                  })}
                  modelType="cat"
                  modelName="category"
                  readOnly
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.units.label}
                  value={data[md.columns.units.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.units.name]: value,
                  })}
                  modelType="cat"
                  modelName="units"
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput
                  label={md.columns.sorting.label}
                  value={data[md.columns.sorting.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.sorting.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`fizik-${data.id}`}
                  label={md.columns.fizik.label}
                  value={data[md.columns.fizik.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.fizik.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`flVacancy-${data.id}`}
                  label={md.columns.flVacancy.label}
                  value={data[md.columns.flVacancy.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.flVacancy.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.StringInput
                  label={md.columns.code.label}
                  value={data[md.columns.code.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.code.name]: value,
                  })}
                  maxLength={10}
                  readOnly
                />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.individuals.label}
                  value={data[md.columns.individuals.name]}
                  onChange={async (e, value) => {
                    await actions.onChange({
                      [md.columns.individuals.name]: value,
                    });
                    actions.onSR('IND_ON_CHANGE');
                  }}
                  modelType="cat"
                  modelName="individuals"
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span>Періоди дії штатної одиниці</span>
                {/* eslint-disable-next-line max-len */}
                <TarifsTP data={data} actions={actions} readOnly={readOnly || data[md.columns.tarifsReadOnly.name]} />
              </Col>
            </Row>
            {/* <span>НЕ враховувати розрахунки для місяців (інформаційно 2015)</span> */}
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m1-${data.id}`}
                  label={md.columns.m1.label}
                  value={data[md.columns.m1.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m1.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m2-${data.id}`}
                  label={md.columns.m2.label}
                  value={data[md.columns.m2.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m2.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m3-${data.id}`}
                  label={md.columns.m3.label}
                  value={data[md.columns.m3.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m3.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m4-${data.id}`}
                  label={md.columns.m4.label}
                  value={data[md.columns.m4.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m4.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m5-${data.id}`}
                  label={md.columns.m5.label}
                  value={data[md.columns.m5.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m5.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m6-${data.id}`}
                  label={md.columns.m6.label}
                  value={data[md.columns.m6.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m6.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m7-${data.id}`}
                  label={md.columns.m7.label}
                  value={data[md.columns.m7.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m7.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m8-${data.id}`}
                  label={md.columns.m8.label}
                  value={data[md.columns.m8.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m8.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m9-${data.id}`}
                  label={md.columns.m9.label}
                  value={data[md.columns.m9.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m9.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m10-${data.id}`}
                  label={md.columns.m10.label}
                  value={data[md.columns.m10.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m10.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m11-${data.id}`}
                  label={md.columns.m11.label}
                  value={data[md.columns.m11.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m11.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`m12-${data.id}`}
                  label={md.columns.m12.label}
                  value={data[md.columns.m12.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.m12.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.TextInput
                  label={md.columns.note.label}
                  value={data[md.columns.note.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.note.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.page1ReadOnly.name]}
                  rows={3}
                />
              </Col>
            </Row>
          </Container>
        </Tab>
        <Tab title="Текст" eventKey="adds">
          <Container fluid>
            <Row>
              <Col>
                <EditorControls.TextInput
                  label={md.columns.text1.label}
                  value={data[md.columns.text1.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.text1.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.text1ReadOnly.name]}
                  rows={3}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.TextInput
                  label={md.columns.text2.label}
                  value={data[md.columns.text2.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.text2.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.text2ReadOnly.name]}
                  rows={3}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.TextInput
                  label={md.columns.text3.label}
                  value={data[md.columns.text3.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.text3.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.text3ReadOnly.name]}
                  rows={3}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.TextInput
                  label={md.columns.text4.label}
                  value={data[md.columns.text4.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.text4.name]: value,
                  })}
                  readOnly={readOnly || data[md.columns.text4ReadOnly.name]}
                  rows={3}
                />
              </Col>
            </Row>
          </Container>
        </Tab>
        <Tab title="Відпустка" eventKey="vocations">
          <VocationsTP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
      </Tabs>
    </Container>
  );
};

StateUnits.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default StateUnits;
