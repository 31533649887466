const focusNextElement = (container) => {
  // add all elements we want to include in our selection
  const focussableElements = 'a:not([disabled]):not([tabindex="-1"]), button:not([disabled]):not([tabindex="-1"]), input:not([disabled]):not([tabindex="-1"]), [tabindex]:not([disabled]):not([tabindex="-1"])';
  if (document.activeElement) {
    const focussable = [...container.querySelectorAll(focussableElements)];
    //
    //   // check for visibility while always include the current activeElement
    //   (element) => element.offsetWidth > 0
    //     || element.offsetHeight > 0
    //     || element === document.activeElement,
    // );
    const index = focussable.indexOf(document.activeElement);
    if (index > -1) {
      const nextElement = focussable[index + 1] || focussable[0];
      nextElement.focus();
    }
  }
};

export default focusNextElement;
