import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  KeySort: { label: 'Значення для сортування', name: 'Ключ', visible: true },
  FullName: { label: 'Повне найменування', name: 'ПолноеНаименование', visible: true },
  Categ: {
    name: 'Категория',
    label: 'Кваліфікаційна категорія закладу',
    visible: true,
  },
  Owner: { label: 'Фін. орган', name: 'Владелец', visible: true },
};

const footer = { ...columns };
/**
 * @const
 */
export const units = {
  label: 'Структурні підрозділи',
  name: 'units',
  backendName: 'Подразделения',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/units',
  listColumns: [
    ...new Set([
      'KeySort',
      ...reqListerColumns,
      'Owner',
      'FullName',
      'Categ',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default units;
