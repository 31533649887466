import base from './base';

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  csu: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  isApproved: { label: 'Документ затверджено', name: 'ДокументУтвержден', visible: false },
  stamp: { label: 'Квітанція', name: 'Квитанция', visible: false },
  year: { label: 'Рік', name: 'Год', visible: true },
  cType: { label: 'Вид рохрахунку', name: 'ВидРасчета', visible: true },
  note: { label: 'Примітка', name: 'Комментарий', visible: false },
  h50Visible: { label: 'РасчетЧасов50Видимость', name: 'РасчетЧасов50Видимость', visible: false },
  numDayVisible: { label: 'РасчетКоличествоДнейВНеделеВидимость', name: 'РасчетКоличествоДнейВНеделеВидимость', visible: false },

};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Расчет',
    label: 'Розрахунок',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      category: { label: 'Категорія', name: 'Категория' },
      numPostUse: { label: 'Кількість постів обслуговуючого та допоміжного персоналу', name: 'КоличествоПостовОбсл' },
      numPostBase: { label: 'Кількість постів в структурних підрозділах основого персоналу', name: 'КоличествоПостовОсн' },
      numStateUse: { label: 'Кількість штатних одиниць на один пост обслуговуючого та допоміжного персоналу (чергування)', name: 'КоличествоШтЕдОбсл' },
      numStateBase: { label: 'Кількість штатних одиниць на один пост структурного підрозділу (чергування)', name: 'КоличествоШтЕдОсн' },
      hTotal: { label: 'Годин всього', name: 'ЧасовВсего' },
      h50: { label: 'в т.ч. з оплатою 50%', name: 'Часов50' },
      numDay: { label: 'Кількість днів у тижні', name: 'КоличествоДнейВНеделе' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Розрахунок нічних та святкових годин',
  name: 'calcNightAndHoliday',
  backendName: 'РасчетНочныхИПраздничных',
  columns,
  frontend: 'doc/calcNightAndHoliday',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'author',
      'year',
      'cType',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
