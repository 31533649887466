import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  code: { label: 'Код', name: 'Код', visible: false },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  dCreate: { label: 'Дата', name: 'ДатаОснования', visible: true },
  fullName: { label: 'Примітка', name: 'ПолНаименование', visible: false },
};

const tables = {
  tp: {
    label: 'Заборонити використання для галузей',
    name: 'тчНеДоступныеОтрасли',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      branches: { label: 'Галузь', name: 'Отрасли' },
    },
  },
};

const footer = { ...columns };
/**
 * @const
 */
export const basisDocs = {
  label: 'Класифікатор підстав',
  name: 'basisDocs',
  backendName: 'ОснованияДок',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables,
  footer,
  frontend: 'cat/basisDocs',
  listColumns: [
    ...new Set([
        'dCreate',
        'name',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default basisDocs;
