import { buildModes } from './common';

// Тут только ОТГ !!!!

export const otgDbOptions = [
  {
    text: 'Балта ОТГ',
    value: 'srv81.ciat.net.ua/Baltska_OTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Богданівка ОТГ',
    value: 'srv81.ciat.net.ua/Bohdanivka_OTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Біла Церква ОТГ',
    value: 'srv81.ciat.net.ua/BilaTserkva_OTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Богуслав ОТГ',
    value: '25299709.ciat.net.ua/Bohuslav_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Бородчанська ОТГ',
    value: '043570353.ciat.net.ua/BohorodchanyOTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Борщагівська ОТГ',
    value: '04362131.ciat.net.ua/Borshchahivska_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Буштинська ОТГ',
    value: '043496853.ciat.net.ua/Bushtynska_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Вилоцька ОТГ',
    value: '043492773.ciat.net.ua/Vylotska_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Виноградів ОТГ',
    value: '04053677.ciat.net.ua/Vynohradiv_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Вишківська ОТГ',
    value: '440756723.ciat.net.ua/Vyshkivska_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Віньковецька ОТГ',
    value: '044033153.ciat.net.ua/VinkivtsiOTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Горінчівська ОТГ',
    value: '441338753.ciat.net.ua/HorinchivskaOTH_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Довжанська ОТГ',
    value: '043503323.ciat.net.ua/Dovzhanska_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Долинська ОТГ',
    value: 'dolyna.ciat.net.ua/DolynskaOTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Драгівської ОТГ',
    value: '043500273.ciat.net.ua/DrahivskoiOTH_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Засульська ОТГ',
    value: 'srv81.ciat.net.ua/Zasulska_OTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Калитянська ОТГ',
    value: '043638113.ciat.net.ua/Kalytianska_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Киїнська ОТГ',
    value: 'srv81.ciat.net.ua/Kyinka_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Клесівська ОТГ',
    value: 'srv81.ciat.net.ua/KlesivskaOTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Маловисківська ОТГ',
    value: 'srv81.ciat.net.ua/Malovyskivska_OTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Мелітополь ОТГ',
    value: 'srv81.ciat.net.ua/Melitopol_tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Мирноградська ОТГ',
    value: 'srv81.ciat.net.ua/Myrnogradsk_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Новобілоуська ОТГ',
    value: 'srv81.ciat.net.ua/Novobilouska_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Пилирецька ОТГ',
    value: '043508433.ciat.net.ua/PylypetskaOTH_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Полянська ОТГ',
    value: '043515873.ciat.net.ua/PolyanaOTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Пулини ОТГ',
    value: 'srv81.ciat.net.ua/Pulyny_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Путильська ОТГ',
    value: 'srv81.ciat.net.ua/Putyljsjka_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Рокитне ОТГ',
    value: '04358997.ciat.net.ua/Rokytne_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Селятинська ОТГ',
    value: 'srv81.ciat.net.ua/Seliatynska_OTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Синевір ОТГ',
    value: '043508893.ciat.net.ua/Synevyr_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Тростянець ОТГ',
    value: 'srv81.ciat.net.ua/Trostianets_OTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Феодосіївська ОТГ',
    value: 'srv81.ciat.net.ua/Feodosiivska_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Хуст ОТГ',
    value: '254407283.ciat.net.ua/Khust_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Чоп ОТГ',
    value: '040537373.ciat.net.ua/Chop_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Шацьк ОТГ',
    value: 'srv81.ciat.net.ua/Shatska_OTG_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
];

export default otgDbOptions;
