import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { budget as md } from '../../../../constants/meta/catalogs/budget';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const Budget = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;
  const mainPane = (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BudgetCode.label}
            value={data[md.columns.BudgetCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BudgetCode.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.TreasuryCode.label}
            value={data[md.columns.TreasuryCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TreasuryCode.name]: value,
            })}
            maxLength={18}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BudgetName.label}
            value={data[md.columns.BudgetName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BudgetName.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BudgetNameGenitive.label}
            value={data[md.columns.BudgetNameGenitive.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BudgetNameGenitive.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={11}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.CodeTLTerra.label}
            value={data[md.columns.CodeTLTerra.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CodeTLTerra.name]: value,
            })}
            maxLength={11}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      {mainPane}
    </Container>
  );
};

Budget.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Budget;
