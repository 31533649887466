import { baseReportColumns, baseResultColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  workDate: { label: 'Робоча дата', name: 'Дата' },
};

/**
 * @const
 */
const definition = {
  name: 'setWorkDate',
  backendName: 'УстановитьРабочуюДату',
  label: 'Встановити робочу дату',
  frontend: 'dp/setWorkDate',
  columns,
};

export default definition;
