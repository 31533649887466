import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  FullName: { label: 'Повне найменування', name: 'ПолнНаименование', visible: true },
  kpCode: { label: 'Код КП', name: 'КодКП', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
};

const footer = { ...columns };
/**
 * @const
 */
export const postClassifier = {
  label: 'Класифікатор посад',
  name: 'postClassifier',
  backendName: 'КлассификаторДолжностей',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/postClassifier',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default postClassifier;
