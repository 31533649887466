import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import closeIcon from '../../assets/icons/Close_Small.svg';
import SearchResults from './searchResults';

const CloseIcon = () => <img src={closeIcon} alt="" />;

const Div = styled.div`
  display: flex;
  outline: none;
  
  &:focus-within{
  .searchInput{
  width:100%
  transition: width 2s
  }
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.15);
  border-right: none;
  background: white;
  border-radius: 2px 0px 0px 2px;
  padding: 7px 8px;
  font-size: 12px;
  width: 150px; /*150px - если есть кнопка закрыть, 170рх - если кнопка закрыть еще не появилась*/
  transition: width 2s;
  outline:none;
  border-left: 1px solid #afbbcc;
  border-top: 1px solid #afbbcc;
  border-bottom: 1px solid #afbbcc;
  
  &:focus{
    border-left: 1px solid rgb(142, 179, 223);
    border-top: 1px solid rgb(142, 179, 223);
    border-bottom: 1px solid rgb(142, 179, 223);
  }
  &:focus ~ button {
    border-top: 1px solid rgb(142, 179, 223);
    border-bottom: 1px solid rgb(142, 179, 223);
  }
  &:disabled{
    border-left: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    border-top: 1px solid #E6E6E6;
    background: #ffffff;
  }
}
`;

const CloseButton = styled.button`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  border: 1px solid red;
  border-left: none;
  outline:none;
  background: #ffffff;
  box-sizing: border-box;
  border-left: none;
  border-right: none;
  box-shadow: inset -1px 1px 2px rgba(0, 0, 0, 0.15);
  border-top: 1px solid #afbbcc;
  border-bottom: 1px solid #afbbcc;
  &:disabled {
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    background: #ffffff;
    cursor: default;
  }
  :focus {outline:none;}
`;

const SearchInput = ({ disabled, value, onChange }) => {
  const inputText = useRef();

  const focusInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange({
      target: { value: '' },
    });
    inputText.current.focus();
  };

  return (
    <>
      <Div
        disabled={disabled}
      >
        <Input
          ref={inputText}
          value={value}
          onChange={onChange}
          disabled={disabled}
          className="searchInput"
        />
        <CloseButton onClick={focusInput} disabled={disabled}>
          <CloseIcon />
        </CloseButton>
        {/* <SearchButton disabled={disabled}> */}
        {/*  <SearchIcon /> */}
        {/* </SearchButton> */}
      </Div>
      <SearchResults inputValue={value} />
    </>
  );
};

SearchInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  disabled: false,
  value: '',
};

export default SearchInput;
