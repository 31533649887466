/* eslint-disable */
import React from 'react';

const CloseIcon = (props) => (
  <svg width="10" height="10" viewBox="0 0 6 6" fill="white" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.5 0.5L5.5 5.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 0.5L0.5 5.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default CloseIcon;
