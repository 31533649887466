import React from 'react';

const ExpandIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.5 14.5V17.5H2.5V9.5H5.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 11.5V2.5H17.5V14.5H8.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.5 13.5L14 6" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 5.5H14.5V8" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ExpandIcon;
