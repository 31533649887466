import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  str1: { label: 'Перший рядок шапки звіту', name: 'СтрокаШапки1', visible: false },
  str2: { label: 'Другий рядок шапки звіту', name: 'СтрокаШапки2', visible: false },
  flDecl: { label: 'Розшифровка за видами робіт', name: 'флРасшифровкаПоВидамРасчета', visible: false },
  flVis: { label: 'Не показувати у списку вибору документа', name: 'флНеПоказыватьВСпискеВыбора', visible: false },
  noRep: { label: 'Не виводити інформацію щодо фонду та кількості штатних одиниць', name: 'НеПоказыватьИтогиВШапке', visible: false },
  owner: { label: 'Установа', name: 'Владелец', visible: false },
  regProtect: { label: 'Захист редагування', name: 'ЗащитаРедактирования', visible: false },
};

const footer = { ...columns };
/**
 * @const
 */
export const repForm = {
  label: 'Форми звітів',
  name: 'repForm',
  backendName: 'ФормыОтчетов',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/repForm',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default repForm;
