import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

import SaveIcon from '../../assets/icons/save';

const SaveButton = ({ text, onClick, ...args }) => (
  <Button onClick={onClick} {...args} title={text}>
    <SaveIcon />
  </Button>
);

SaveButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

SaveButton.defaultProps = {
  text: 'Зберегти',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default SaveButton;
