import React from 'react';

const EditIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.5 6H6.5V3" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.5 13.5V17.5H6.7H3.5V5.5L6.5 2.5H14.5V5.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.1 6.5L17.5 7.9L12.6 12.9L11.8 13.6H10.4V12.2L16.1 6.5Z" stroke="#4281C9" strokeWidth="0.9909" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.1 11.4L12.6 12.9" stroke="#4281C9" strokeWidth="0.9909" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.7 7.89999L16.1 9.29999" stroke="#4281C9" strokeWidth="0.9909" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default EditIcon;
