import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { CommandPanelColor } from '../Form/styledForm';
import { StyleInnerHeader } from '../styledMenuHeader/menuHeader';
import CommandPanelButton from '../button/commandPanelButton';
import { CloseIcon } from '../../assets/icons';

export const CloseCommandButton = styled(CommandPanelButton)`
  background: none;
  padding: 5px;
  &:hover{
    background: #AAC6E6;
  }
`;

export const StyleHeader = styled(StyleInnerHeader)`
  margin: 0px;
`;

const getSizeStyle = (size) => {
  if (size === 'small') {
    return `
      width: 60%;
    `;
  }
  if (size === 'middle') {
    return `
      width: 90%;
      height: 70%;
    `;
  }
  return `
    width: 100%;
    height: 100%;
  `;
};

const ContainerModal = styled.div`
  padding: 2em;
  background: white;
  display: flex;
  flex-direction: column;
  // box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  // border: 0.5px solid;
  border-radius: 2px;
  overflow: auto;
  
  border: 1px solid #37a0a7;
  box-shadow: 0px 1px 30px -4px rgba(58,140,194,1);

  ${({ size }) => getSizeStyle(size)}
`;

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // overflow: auto;
  
  z-index: ${({ zIndexModal }) => (1000 + zIndexModal)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContainerSettings = styled.div`
`;
export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
const GeneralDiv = styled.div`
  display: flex;
`;
const CommandPanelBut = styled(CommandPanelColor)`
  font-family: Roboto, sans-serif;
  padding: 6px 10px;
  font-size: 13.5px;
  margin-right: 0;
`;

class Modal extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    closeIcon: PropTypes.bool,
    header: PropTypes.string,
    triggerButton: PropTypes.node,
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    size: PropTypes.oneOf(['small', 'middle', 'fullscreen']),
    hideCloseIcon: PropTypes.bool,
    zIndexModal: PropTypes.number,
  };

  static defaultProps = {
    triggerButton: null,
    closeIcon: false,
    header: null,
    opened: null,
    onClose: null,
    size: 'fullscreen',
    hideCloseIcon: false,
    zIndexModal: 0,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.opened === null ? state.portalOpened : props.opened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = state.bodyOverflow;
    }
    // props.opened may be null, false, true
    return { ...state, portalOpened: props.opened === null ? state.portalOpened : props.opened };
  }

  constructor(props) {
    super(props);
    this.state = {
      portalOpened: false,
      bodyOverflow: document.body.style.overflow,
    };
  }

  componentWillUnmount() {
    document.body.style.overflow = this.state.bodyOverflow;
  }

  openModal = (e) => {
    const { portalOpened } = this.state;
    const { onClose } = this.props;
    if (portalOpened && onClose) {
      onClose(e);
    }
    this.setState({ portalOpened: !portalOpened });
  };

  render() {
    const { portalOpened } = this.state;
    const {
      triggerButton, header, closeIcon, children, opened, size, hideCloseIcon, zIndexModal,
    } = this.props;
    const MyButton = triggerButton && React.cloneElement(
      triggerButton,
      opened === null ? {
        onClick: (e) => {
          this.openModal(e);
          triggerButton.props.onClick && triggerButton.props.onClick(e);
        },
      } : {},
    );
    return (
      <GeneralDiv>
        {MyButton}
        {portalOpened && createPortal(
          (
            <StyledDiv zIndexModal={zIndexModal}>
              <ContainerModal size={size}>
                <div>
                  <ContainerHeader>
                    {!!header && (
                      <StyleHeader>{header}</StyleHeader>
                    )}
                    {!hideCloseIcon
                    && (
                      <div>
                        {closeIcon ? (
                          <CloseCommandButton
                            onClick={this.openModal}
                            text="Закрити"
                          >
                            <CloseIcon />
                          </CloseCommandButton>
                        )
                          : (
                            <div>
                              <CommandPanelBut
                                onClick={this.openModal}
                                label="Застосувати"
                              />
                              {/* <NegativeButton onClick={this.openModal}> */}
                              {/*  Скасувати */}
                              {/* </NegativeButton> */}
                            </div>
                          )}
                      </div>
                    )}
                  </ContainerHeader>
                </div>
                <ContainerSettings>
                  {children}
                </ContainerSettings>
              </ContainerModal>
            </StyledDiv>
          ), document.body,
        )}
      </GeneralDiv>
    );
  }
}

export default Modal;
