import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/changeToStaffList';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.main;

const TPHeader = ({
                    activeCol,
                    highlights,
                    onToggleHighlght,
                  }) => (
    <TableHeaderRow noGutters >
      <Col
          xl={12}
          sm={12}
          className="px-3  d-flex flex-column justify-content-end"
      >
        <Row>
          <Col>
            <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'stateUnit'}
                highlighted={highlights.includes('stateUnit')}
                onToggleHighlght={() => onToggleHighlght('stateUnit')}
            >
              {meta.columns.stateUnit.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'num'}
                highlighted={highlights.includes('num')}
                onToggleHighlght={() => onToggleHighlght('num')}
            >
              {meta.columns.num.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'sol'}
                highlighted={highlights.includes('sol')}
                onToggleHighlght={() => onToggleHighlght('sol')}
            >
              {meta.columns.sol.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'add'}
                highlighted={highlights.includes('add')}
                onToggleHighlght={() => onToggleHighlght('add')}
            >
              {meta.columns.add.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'pay'}
                highlighted={highlights.includes('pay')}
                onToggleHighlght={() => onToggleHighlght('pay')}
            >
              {meta.columns.pay.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'mFond'}
                highlighted={highlights.includes('mFond')}
                onToggleHighlght={() => onToggleHighlght('mFond')}
            >
              {meta.columns.mFond.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'yFond'}
                highlighted={highlights.includes('yFond')}
                onToggleHighlght={() => onToggleHighlght('yFond')}
            >
              {meta.columns.yFond.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
