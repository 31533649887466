import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFile as icon } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

const Unexecute = ({ disabled, onClick, label }) => (
  <DefaultCPButton
    onClick={onClick}
    label={label}
    disabled={disabled}
    variant="secondary"
  >
    <span className="fa-layers fa-fw ">
      <FontAwesomeIcon icon={icon} />
      <FontAwesomeIcon icon={faTimes} color="var(--danger)" transform="shrink-6 down-2 left-1" />
    </span>

  </DefaultCPButton>
);

Unexecute.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

Unexecute.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Зробити непроведеним',
};

export default Unexecute;
