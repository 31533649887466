import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  BudgetCode: { label: 'Код бюджету', name: 'КодБюджета', visible: false },
  TreasuryCode: { name: 'КодКазны', label: 'Номер бюджету', visible: true },
  CodeTLTerra: {
    name: 'КодТЛТерра',
    label: 'Код за TL_TERRA',
    visible: true,
  },
  BudgetName: {
    name: 'НаимБюджета',
    label: 'Найменування Бюджету',
    visible: false,
  },
  BudgetNameGenitive: {
    name: 'НаимБюджетаРодитПадеж',
    label: 'Найменування бюджету (родовий відмінок)',
    visible: false,
  },
};

const footer = { ...columns };
/**
 * @const
 */
export const budget = {
  label: 'Бюджети',
  name: 'budget',
  backendName: 'Города',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/budget',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default budget;
