import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const MonthButton = ({
  onClick, value, startDate, endDate,
}) => {
  const className = useMemo(
    () => {
      if (!startDate || !endDate) return '';
      if (endDate < value.startDate) return '';
      if (startDate > value.endDate) return '';
      if (value.startDate >= startDate && value.endDate <= endDate) return 'start-0 end-0';
      if (value.startDate >= startDate && value.endDate > endDate) return 'start-0 end-part';
      if (value.startDate < startDate && value.endDate <= endDate) return 'start-part end-0';
      if (value.startDate < startDate && value.endDate > endDate) return 'start-part end-part';
      return '';
    },
    [endDate, startDate, value.endDate, value.startDate],
  );
  return (
    <Button variant="outline-secondary" className={className} onClick={(e) => onClick(e, value)}>
      {value.label}
    </Button>
  );
};

MonthButton.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
};

MonthButton.defaultProps = {
  startDate: null,
  endDate: null,
};
export default MonthButton;
