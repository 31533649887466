import React, {
  forwardRef, memo, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

const TextInput = forwardRef(({
  value, disabled, readOnly,
  onClick, onFocus, maxLength, onChange,
  errored, rows,
}, ref) => {
  const [cursor, setCursor] = useState({ start: 0, end: 0 });
  const inputRef = ref || useRef(null);
  const changeHandler = (e) => {
    const newValue = e.target.value;
    const currentCursor = { start: e.target.selectionStart, end: e.target.selectionEnd };
    if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
      onChange(e, newValue);
      setCursor({
        start: currentCursor.start,
        end: currentCursor.start,
      });
    }
  };
  const displayValue = useMemo(
    () => value || '',
    [value],
  );

  useEffect(() => {
    if (inputRef.current) inputRef.current.setSelectionRange(cursor.start, cursor.end);
  });
  return (
    <FormControl
      as="textarea"
      rows={rows}
      ref={inputRef}
      value={displayValue}
      onChange={changeHandler}
      disabled={disabled}
      readOnly={readOnly}
      onClick={onClick}
      onFocus={onFocus}
      isInvalid={errored}
    />
  );
});

TextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  errored: PropTypes.bool,
  rows: PropTypes.number,
};

TextInput.defaultProps = {
  value: '',
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  errored: false,
  rows: 3,
};

export default memo(TextInput);
