import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';

const ChekboxInput = forwardRef(({
  value, onChange, disabled, label, id, errored, readOnly,
}, ref) => (
  <FormCheck
    ref={ref}
    type="switch"
    checked={value}
    disabled={disabled || readOnly}
    label={label}
    id={id}
    onChange={(e) => onChange(e, !value)}
    isInvalid={errored}
  />
));

ChekboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  errored: PropTypes.bool,
  readOnly: PropTypes.bool,
};

ChekboxInput.defaultProps = {
  disabled: null,
  label: null,
  value: false,
  errored: false,
  readOnly: false,
};

export default ChekboxInput;
