import PeriodSelection from './periodSelection';
import { DataComposition } from './DataComposition';
import SolaryKind from './solaryKind';
import PlaningKind from './planingKind';
import PaymentGroups from './paymentGroups';
import SignatureTypes from './signatureTypes';


const enums = {
  PeriodSelection,
  DataComposition,
  SolaryKind,
  PlaningKind,
  PaymentGroups,
  SignatureTypes,
};

export default enums;
