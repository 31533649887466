import React, { memo } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';

import {
  AnalysisMenuItem,
  MainMenuItem,
  ServicesMenuItem,
  CatalogsMenuItem,
} from '../../containers/dashboard/dashboardMenuPoints';
import mdRep from '../../constants/meta/reports';
import mdDoc from '../../constants/meta/documents';
import mdCat from '../../constants/meta/catalogs';

import StyledHeaderDocument from '../styledTable/headerDocument';
import { catComponents } from '../../containers/catalogs';
import { docComponents } from '../../containers/documents';
import { repComponents } from '../../containers/reports';
import LinkComp from '../windowsManager/link';

const AppRouter = () => (
  <Routes>

    <Route path="/" element={<Outlet />}>
      <Route index element={<MainMenuItem />} />
      <Route path="analysis" element={<AnalysisMenuItem />} />
      <Route path="catalogs/" element={<CatalogsMenuItem />} />
      <Route path="services/" element={<ServicesMenuItem />} />
      <Route path="*" element={<h1>Помилились?</h1>} />

      {/** Catalogs lists * */}

      {Object.keys(catComponents)
        .map((el) => {
          const url = `/${mdCat[el].frontend}/`;
          const Comp = catComponents[el].lister;
          return (
            <Route
              exact
              key={`cat-list-${el}`}
              path={url}
              element={(
                <StyledHeaderDocument
                  caption={mdCat[el].label}
                >
                  <LinkComp
                    component={Comp}
                    title={`Список "${mdCat[el].label}" `}
                  />
                </StyledHeaderDocument>
              )}
            />
          );
        })}

      {Object.keys(catComponents)
        .map((el) => {
          const url = `/${mdCat[el].frontend}/`;
          const Comp = catComponents[el].editor;
          return (
            <Route
              exact
              key={`cat-editor-${el}`}
              path={`${url}:id/`}
              element={(
                <LinkComp
                  component={Comp}
                  title={`Елем.дов. "${mdCat[el].label} "`}
                />
              )}
            />
          );
        })}

      {/** Documents lists * */}

      {Object.keys(docComponents)
        .map((el) => {
          const url = `/${mdDoc[el].frontend}/`;
          const Comp = docComponents[el].lister;
          return (
            <Route
              exact
              key={`doc-list-${el}`}
              path={url}
              element={(
                <StyledHeaderDocument
                  caption={mdDoc[el].label}
                >
                  <LinkComp
                    component={Comp}
                    title={`Список "${mdDoc[el].label}" `}
                  />
                </StyledHeaderDocument>
              )}
            />
          );
        })}

      {Object.keys(docComponents)
        .map((el) => {
          const url = `/${mdDoc[el].frontend}/`;
          const Comp = docComponents[el].editor;
          return (
            <Route
              exact
              key={`doc-editor-${el}`}
              path={`${url}:id/`}
              element={(
                <LinkComp
                  component={Comp}
                  title={`Документ "${mdDoc[el].label} "`}
                />
              )}
            />
          );
        })}

      {/** Reports * */}

      {Object.keys(repComponents)
        .map((el) => {
          const url = `/${mdRep[el].frontend}/`;
          const Comp = repComponents[el].editor;
          return (
            <Route
              exact
              key={`rep-${el}`}
              path={url}
              element={(
                <StyledHeaderDocument
                  caption={mdRep[el].label}
                >
                  <LinkComp
                    component={Comp}
                    title={`Звіт "${mdRep[el].label}"`}
                  />
                </StyledHeaderDocument>
              )}
            />
          );
        })}
    </Route>

  </Routes>
);

export default memo(AppRouter);
