import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      // Error path
      return (
        <Alert
          variant="danger"
          dismissible
          onClose={() => this.setState({
            error: null,
            errorInfo: null,
          })}
        >
          <Alert.Heading>
            При виконанні останньої дії виникла помилка.
          </Alert.Heading>

          <details style={{ whiteSpace: 'pre-wrap', color: 'red' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </Alert>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  children: null,
};

export default ErrorBoundary;
