import React from 'react';
import {
  BoxMenuHeader,
  BoxMenuLineHr,
  ElementsListContainer,
} from '../../../components/styledBoxMenu/instancesForBoxMenu/index';
import UseMenuHeader from '../hooks/useMenuHeader';

const DirectoriesMenuItem = () => (
  <UseMenuHeader label="Головна">
    <div style={{ marginTop: '40px' }}>
      <BoxMenuHeader>Мої вподобання</BoxMenuHeader>
      <BoxMenuLineHr />
      <ElementsListContainer style={{ height: '73vh', overflow: 'auto' }} />
    </div>
  </UseMenuHeader>
);

export default DirectoriesMenuItem;
