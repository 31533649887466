import base from './base';

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  csu: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  isApproved: { label: 'Документ затверджено', name: 'ДокументУтвержден', visible: false },
  stamp: { label: 'Квітанція', name: 'Квитанция', visible: false },
  baseDoc: { label: 'Підстава', name: 'Основание', visible: true },
  signatureVariants: { label: 'Варіант підпису', name: 'Подпись', visible: false },
  fond: { label: 'Элемент фонд', name: 'ЭлементФонд', visible: true },
  koef: { label: 'Коэф рік', name: 'КоэфГод', visible: false },
  kbp: { label: 'КБП', name: 'КлассификаторКБП', visible: true },

};

/**
 * @const
 */
const tables = {
  general: {
    name: 'ВыводИзШтатногоРасписания',
    label: 'Вивести з штатного розкладу',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      stateUnit: { label: 'Штатна одиниця', name: 'ШтатнаяЕдиница' },
      num: { label: 'Количество', name: 'Количество' },
      sol: { label: 'Оклад', name: 'Оклад' },
      add: { label: 'Надбавки', name: 'Надбавки' },
      pay: { label: 'Доплаты', name: 'Доплаты' },
      mFond: { label: 'Фонд заробітної плати на міс.', name: 'ФондЗПМес' },
      yFond: { label: 'Фонд заробітної плати на рік', name: 'ФондЗПГод' },
    },
  },
  main: {
    name: 'ВводВШтатноеРасписание',
    label: 'Додати до штатного розкладу',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      stateUnit: { label: 'Штатна одиниця', name: 'ШтатнаяЕдиница' },
      num: { label: 'Количество', name: 'Количество' },
      sol: { label: 'Оклад', name: 'Оклад' },
      add: { label: 'Надбавки', name: 'Надбавки' },
      pay: { label: 'Доплаты', name: 'Доплаты' },
      mFond: { label: 'Фонд заробітної плати на міс.', name: 'ФондЗПМес' },
      yFond: { label: 'Фонд заробітної плати на рік', name: 'ФондЗПГод' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Зміни до штатного розкладу',
  name: 'changeToStaffList',
  backendName: 'ИзменениеШтатногоРасписания',
  columns,
  frontend: 'doc/changeToStaffList',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'csu',
      'baseDoc',
      'kbp',
      'fond',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
