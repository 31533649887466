import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, Container, Col,
} from 'react-bootstrap';
import md from '../../../../constants/meta/documents/calcNightAndHoliday';
import { GeneralTP } from './tabs';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { CommandPanelColor, StyledLabel } from '../../../../components/Form/styledForm';

const CalcNightAndHolidayTabs = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;
  const FI = data[md.columns.csu.name];
  const FiOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        <GeneralTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.columns.note.label} eventKey="main">
        <Col>
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.note.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />
        </Col>
      </Tab>
    </Tabs>
  );
};

CalcNightAndHolidayTabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default CalcNightAndHolidayTabs;
