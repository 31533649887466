import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/personCard';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.main;

const TPHeader = ({
  activeCol,
  highlights,
  onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col
      className="px-3  d-flex text-center"
    >
      <Row className="w-100" noGutters>
        <Col
          className="d-flex"
        >
          <Col xl={6}>
            <TableHeaderCell
              active={activeCol === 'baseDoc'}
              highlighted={highlights.includes('baseDoc')}
              onToggleHighlght={() => onToggleHighlght('baseDoc')}
            >
              {meta.columns.baseDoc.label}
            </TableHeaderCell>
          </Col>

          <Col xl={4}>
            <TableHeaderCell
              active={activeCol === 'minSalary'}
              highlighted={highlights.includes('minSalary')}
              onToggleHighlght={() => onToggleHighlght('minSalary')}
            >
              {meta.columns.minSalary.label}
            </TableHeaderCell>
          </Col>

          <Col xl={4}>
            <TableHeaderCell
              active={activeCol === 'baseSum'}
              highlighted={highlights.includes('baseSum')}
              onToggleHighlght={() => onToggleHighlght('baseSum')}
            >
              {meta.columns.baseSum.label}
            </TableHeaderCell>
          </Col>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
