import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

import PrintIcon from '../../assets/icons/print';

const PrintButton = ({
  disabled, text, onClick, ...args
}) => (
  <Button onClick={onClick} {...args} title={text} disabled={disabled}>
    <PrintIcon />
  </Button>
);

PrintButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

PrintButton.defaultProps = {
  text: 'Друкувати',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
  disabled: false,
};

export default PrintButton;
