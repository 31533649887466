import React from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import { baseDocYear as md } from '../../../../constants/meta/catalogs/baseDocYear';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { TpTP } from './tabs';


const BaseDocYear = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
        <Row>
            <Col>
                <EditorControls.StringInput
                    label={md.columns.name.label}
                    value={data[md.columns.name.name]}
                    onChange={(e, value) => actions.onChange({
                        [md.columns.name.name]: value,
                    })}
                    maxLength={150}
                    readOnly={readOnly}
                />
            </Col>
      </Row>
      <Row>
            <Col>
                <EditorControls.TextInput
                    label={md.columns.fullName.label}
                    value={data[md.columns.fullName.name]}
                    onChange={(e, value) => actions.onChange({
                        [md.columns.fullName.name]: value,
                    })}
                    rows={3}
                    readOnly={readOnly}
                />
            </Col>
      </Row>
        <Row>
            <EditorControls.CheckboxInput
                controlId={`inActive-${data.id}`}
                label={md.columns.inActive.label}
                value={data[md.columns.inActive.name]}
                onChange={(e, value) => actions.onChange({
                    [md.columns.inActive.name]: value,
                })}
                readOnly={readOnly}
            />
        </Row>
      <Row>
          <Row>
              <Col>
                  <span>{md.tables.tp.label}</span>
              </Col>
          </Row>
          <TpTP data={data} actions={actions} readOnly={readOnly} />
      </Row>
    </Container>
  );
};

BaseDocYear.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default BaseDocYear;
