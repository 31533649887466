import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledMenuHeader from '../../../components/styledMenuHeader/menuHeader';

const UseMenuHeader = ({ label, children }) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <>
      <StyledMenuHeader inputValue={inputValue} setInputValue={setInputValue}>
        {label}
      </StyledMenuHeader>
      {!inputValue
            && children }
    </>
  );
};

UseMenuHeader.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

UseMenuHeader.defaultProps = {
  children: null,
};

export default UseMenuHeader;
