import styled from 'styled-components';
import { CommandPanelButton } from '../../components/button';

// const StyledHtml = styled.html`
//     font-size: 16px;
// `;

export const PrintableTable = styled.table.attrs(({ fixed, top }) => fixed && {
  style: { top },
})`
  ${({ addStyles }) => addStyles}
  border-collapse: collapse;
  table-layout: fixed;
  width: max-content;
  & td.empty-column {
    padding: 0;
    height: 0;
  }
  & td {
    background: white;
    height: 1.2em;
  }
  & td.FixedToTop, td.FixedToLeft {
    position: sticky;
    z-index: 1;
    box-shadow: 0 2px 2px -1px black;
  }
  & td.FixedToTop.FixedToLeft{
    z-index: 2;
  }
   @media screen {
    & td.ActiveCell {
      border: black solid 2px;
    }
   }
   @media print {
      & td.FixedToTop, td.FixedToLeft {
        position: relative;
        z-index: 0;
        box-shadow: none;
      }
      & td.FixedToTop.FixedToLeft{
        z-index: 0;
      }
`;

export const PrintableRow = styled.tr`
`;

export const PrintableCell = styled.td.attrs(({
  rowSpan=null, colSpan=null, decryption, top, left,
}) => ({
  rowSpan,
  colSpan,
  style: {
    ...decryption && { cursor: 'help' },
    ...top === null ? {} : { top: `${top}px` },
    ...left === null ? {} : { left: `${left}px` },
  },
}))`
  position: relative;
  overflow: hidden;
  padding: 0 .125rem;
  // overflow-y: scroll;
  // display: flex;
  //resize: horizontal;
`;

export const CellDiv = styled.div`
  display: flex;
  position: relative;
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  font: inherit;
  overflow: hidden;
  border: none;
  text-align: inherit;
  padding: 1px;
  background: rgb(255,255,153);
  resize: none;
`;

export const ContainerButton = styled.div`
  display: flex;
  border: 1px solid #cbcbe4;
  padding: 5px;
`;
export const ContainerButtonTop = styled(ContainerButton)`
  border-radius: 4px 4px 0 0;
  border-bottom: none;
`;
export const ContainerButtonBottom = styled(ContainerButton)`
  border-radius: 0 0 4px 4px;
  border-top: none;
`;
export const StyledContainer = styled.div`
  display: flex;
  overflow: auto;
  display: grid!important;
  width: 100%;
  grid-template-rows: [menu] max-content [table] 80vh; 
`;

export const StyledContainer2 = styled.div`
  overflow: auto;
  height: 100%;
  max-width: -webkit-fill-available;
  grid-row: table;
  padding: 0;
  border: 1px solid #cacbe4;
  border-radius: 4px;
`;

export const PrintableArea = styled.div`
  width: fit-content;
  margin: 0;
  @media print {
    html {
      font-size: 160px;
    }
  }
`;

export const DivResizerX = styled.div`
  border: none;
  cursor: col-resize;
  //float: right;
  width: 4px;
  height: 100%;
  // border: 1px dotted gray;
  position: absolute;
  right: 0;
  top: 0;
  @media print{
    width: 0;
    display: none;
  }
`;

export const ContainerSave = styled.div`
  margin-left: auto;
  display: flex;
`;

export const TextSave = styled.span`
  align-self: center;
  color: #4281c9;
  font-weight: 700;
  font-size: 16.5px;
  margin-right: 10px;
`;

export const ButtonIcon = styled(CommandPanelButton)`
  background: none;
  padding: 0;
    :hover {
      filter: grayscale(0.8);
      background: none;
    };

`;
