import {
  defaultColumns,
  defaultOrder, hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  method: { label: 'Спосіб нарахування', name: 'СпособРасчета', visible: false },
};

export const mainCalc = {
  label: 'Види нарахувань',
  name: 'mainCalc',
  columns,
  backendName: 'ТР_ОсновныеНачисления',
  defaultOrder,
  frontend: 'calc/mainCalc',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default mainCalc;
