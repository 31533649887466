import FirstIndicatorsEditor from './firstIndicators/editor';
import FirstIndicatorsLister from './firstIndicators/lister';

import BudgetEditor from './budget/editor';
import BudgetLister from './budget/lister';
import ResponsiblePersonEditor from './responsiblePerson/editor';
import ResponsiblePersontLister from './responsiblePerson/lister';

import ElementFondEditor from './elementFond/editor';
import ElementFondLister from './elementFond/lister';

import FondClassifierEditor from './fondClassifier/editor';
import FondClassifierLister from './fondClassifier/lister';

import KBPClassifierEditor from './KBPClassifier/editor';
import KBPClassifierLister from './KBPClassifier/lister';

import UsersEditor from './users/editor';
import UsersLister from './users/lister';

import BaseDocEditor from './baseDoc/editor';
import BaseDocLister from './baseDoc/lister';

import CSUEditor from './CSU/editor';
import CSULister from './CSU/lister';

import UnitsEditor from './units/editor';
import UnitsLister from './units/lister';

import UnitsCategoryEditor from './unitsCategory/editor';
import UnitsCategoryLister from './unitsCategory/lister';
import BranchesEditor from './branches/editor';
import BranchesLister from './branches/lister';
import CategoryEditor from './category/editor';
import CategoryLister from './category/lister';
import BasisEditor from './basis/editor';
import BasisLister from './basis/lister';
import StateUnitsEditor from './stateUnits/editor';
import StateUnitsLister from './stateUnits/lister';
import PostEditor from './post/editor';
import PostLister from './post/lister';
import PostClassifierEditor from './postClassifier/editor';
import PostClassifierLister from './postClassifier/lister';
import TarifEditor from './tarif/editor';
import TarifLister from './tarif/lister';
import PedagogicalLoadEditor from './pedagogicalLoad/editor';
import PedagogicalLoadLister from './pedagogicalLoad/lister';
import IndividualsEditor from './individuals/editor';
import IndividualsLister from './individuals/lister';
import FamilyStatusEditor from './familyStatus/editor';
import FamilyStatusLister from './familyStatus/lister';
import EducationEditor from './education/editor';
import EducationLister from './education/lister';
import ExperienceTypesEditor from './experienceTypes/editor';
import ExperienceTypesLister from './experienceTypes/lister';
import CsuLvlEditor from './csuLvl/editor';
import CsuLvlLister from './csuLvl/lister';
import KvkClassifierEditor from './kvkClassifier/editor';
import KvkClassifierLister from './kvkClassifier/lister';
import BanksEditor from './banks/editor';
import BanksLister from './banks/lister';
import ExternalReportsEditor from './externalReports/editor';
import ExternalReportsLister from './externalReports/lister';
import SignatureVariantsEditor from './signatureVariants/editor';
import SignatureVariantsLister from './signatureVariants/lister';
import RepFormEditor from './repForm/editor';
import RepFormLister from './repForm/lister';
import BasisDocsEditor from './basisDocs/editor';
import BasisDocsLister from './basisDocs/lister';
import BaseDocYearEditor from './baseDocYear/editor';
import BaseDocYearLister from './baseDocYear/lister';
import CsuGroupEditor from './csuGroup/editor';
import CsuGroupLister from './csuGroup/lister';

const catComponents = {
  baseDoc: {
    editor: BaseDocEditor,
    lister: BaseDocLister,
  },

  firstIndicators: {
    editor: FirstIndicatorsEditor,
    lister: FirstIndicatorsLister,
  },
  budget: {
    editor: BudgetEditor,
    lister: BudgetLister,
  },
  elementFond: {
    editor: ElementFondEditor,
    lister: ElementFondLister,
  },
  fondClassifier: {
    editor: FondClassifierEditor,
    lister: FondClassifierLister,
  },
  kbpClassifier: {
    editor: KBPClassifierEditor,
    lister: KBPClassifierLister,
  },
  responsiblePerson: {
    editor: ResponsiblePersonEditor,
    lister: ResponsiblePersontLister,
  },
  csu: {
    editor: CSUEditor,
    lister: CSULister,
  },
  users: {
    editor: UsersEditor,
    lister: UsersLister,
  },
  units: {
    editor: UnitsEditor,
    lister: UnitsLister,
  },
  unitsCategory: {
    editor: UnitsCategoryEditor,
    lister: UnitsCategoryLister,
  },
  branches: {
    editor: BranchesEditor,
    lister: BranchesLister,
  },
  category: {
    editor: CategoryEditor,
    lister: CategoryLister,
  },
  basis: {
    editor: BasisEditor,
    lister: BasisLister,
  },
  stateUnits: {
    editor: StateUnitsEditor,
    lister: StateUnitsLister,
  },
  post: {
    editor: PostEditor,
    lister: PostLister,
  },
  postClassifier: {
    editor: PostClassifierEditor,
    lister: PostClassifierLister,
  },
  tarif: {
    editor: TarifEditor,
    lister: TarifLister,
  },
  pedagogicalLoad: {
    editor: PedagogicalLoadEditor,
    lister: PedagogicalLoadLister,
  },
  individuals: {
    editor: IndividualsEditor,
    lister: IndividualsLister,
  },
  familyStatus: {
    editor: FamilyStatusEditor,
    lister: FamilyStatusLister,
  },
  education: {
    editor: EducationEditor,
    lister: EducationLister,
  },
  experienceTypes: {
    editor: ExperienceTypesEditor,
    lister: ExperienceTypesLister,
  },
  csuLvl: {
    editor: CsuLvlEditor,
    lister: CsuLvlLister,
  },
  kvkClassifier: {
    editor: KvkClassifierEditor,
    lister: KvkClassifierLister,
  },
  banks: {
    editor: BanksEditor,
    lister: BanksLister,
  },
  externalReports: {
    editor: ExternalReportsEditor,
    lister: ExternalReportsLister,
  },
  signatureVariants: {
    editor: SignatureVariantsEditor,
    lister: SignatureVariantsLister,
  },
  repForm: {
    editor: RepFormEditor,
    lister: RepFormLister,
  },
  basisDocs: {
    editor: BasisDocsEditor,
    lister: BasisDocsLister,
  },
  baseDocYear: {
    editor: BaseDocYearEditor,
    lister: BaseDocYearLister,
  },
  csuGroup: {
    editor: CsuGroupEditor,
    lister: CsuGroupLister,
  },
};

export default {
  catComponents,

  BaseDocEditor,
  BaseDocLister,

  FirstIndicatorsEditor,
  FirstIndicatorsLister,
  BudgetEditor,
  BudgetLister,
  KBPClassifierEditor,
  KBPClassifierLister,
  ElementFondEditor,
  ElementFondLister,
  FondClassifierEditor,
  FondClassifierLister,

  ResponsiblePersonEditor,
  ResponsiblePersontLister,

  CSUEditor,
  CSULister,

  UsersEditor,
  UsersLister,

  UnitsEditor,
  UnitsLister,
  UnitsCategoryEditor,
  UnitsCategoryLister,
  BranchesEditor,
  BranchesLister,
  CategoryEditor,
  CategoryLister,
  BasisEditor,
  BasisLister,
  StateUnitsEditor,
  StateUnitsLister,
  PostEditor,
  PostLister,
  PostClassifierEditor,
  PostClassifierLister,
  TarifEditor,
  TarifLister,
  PedagogicalLoadEditor,
  PedagogicalLoadLister,
  IndividualsEditor,
  IndividualsLister,
  FamilyStatusEditor,
  FamilyStatusLister,
  EducationEditor,
  EducationLister,
  ExperienceTypesEditor,
  ExperienceTypesLister,
  CsuLvlEditor,
  CsuLvlLister,
  KvkClassifierEditor,
  KvkClassifierLister,
  BanksEditor,
  BanksLister,
  ExternalReportsEditor,
  ExternalReportsLister,
  SignatureVariantsEditor,
  SignatureVariantsLister,
  RepFormEditor,
  RepFormLister,
  BasisDocsEditor,
  BasisDocsLister,
  BaseDocYearEditor,
  BaseDocYearLister,
  CsuGroupEditor,
  CsuGroupLister,
};

export {
  catComponents,
  FirstIndicatorsEditor,
  FirstIndicatorsLister,
  BudgetEditor,
  BudgetLister,
  ResponsiblePersonEditor,
  ResponsiblePersontLister,
  KBPClassifierEditor,
  KBPClassifierLister,
  ElementFondEditor,
  ElementFondLister,
  FondClassifierEditor,
  FondClassifierLister,
  CSUEditor,
  CSULister,
  UsersEditor,
  UsersLister,
  BaseDocEditor,
  BaseDocLister,
  CategoryEditor,
  CategoryLister,
  BasisEditor,
  BasisLister,
  StateUnitsEditor,
  StateUnitsLister,
  PostEditor,
  PostLister,
  PostClassifierEditor,
  PostClassifierLister,
  TarifEditor,
  TarifLister,
  PedagogicalLoadEditor,
  PedagogicalLoadLister,
  IndividualsEditor,
  IndividualsLister,
  FamilyStatusEditor,
  FamilyStatusLister,
  EducationEditor,
  EducationLister,
  ExperienceTypesEditor,
  ExperienceTypesLister,
  CsuLvlEditor,
  CsuLvlLister,
  KvkClassifierEditor,
  KvkClassifierLister,
  BanksEditor,
  BanksLister,
  ExternalReportsEditor,
  ExternalReportsLister,
  SignatureVariantsEditor,
  SignatureVariantsLister,
  RepFormEditor,
  RepFormLister,
  BasisDocsEditor,
  BasisDocsLister,
  BaseDocYearEditor,
  BaseDocYearLister,
  CsuGroupEditor,
  CsuGroupLister,
};
