import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { faTrash as icon } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

const DeleteButton = ({
  disabled, onClick, label, confirmText,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <DefaultCPButton
        icon={icon}
        onClick={() => setOpened(true)}
        variant="danger"
        label={label}
        disabled={disabled}
      />
      <Modal show={opened} onHide={() => setOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Видалення елементів
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmText}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={(e) => {
              setOpened(false);
              onClick(e);
            }}
          >
            Так
          </Button>
          <Button variant="secondary" onClick={() => setOpened(false)}>Ні</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

DeleteButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  confirmText: PropTypes.string,
};

DeleteButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Помітити на видалення',
  confirmText: `
  Елементи будуть позначені на видалення. Скасувати цю дію можна в будь-який момент.
  За замовчуванням позначені на виделення елементи не відображаються,
  але це можна змінити натисканням на відповідну кнопку на панелі інструментів.
  У всіх інших випадках позначені на видалення елементи поводяться як звичайні.
  Ви бажаєте продовжити?
  `,
};

export default DeleteButton;
