export const tableHeader = `
  padding: 2px 5px;
  cursor: pointer;
`;
export const tableHeaderLister = `
  ${tableHeader};
  background: linear-gradient(0deg,#d0e5fa,#dde9f8,#dfeefd,#d2def8);
  // background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
`;
export const tableInside = `
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: black;
  // border: 1px solid #DDE2E9;
  border: 1px solid #afbbcc;
`;
export const hoverTable = `
  &:hover{
    background: linear-gradient(0deg, #66BC7A, #66BC7A), #008F21};
`;
export const font = `
  font-family: Roboto,sans-serif;
  font-size: 14px;
  color: black;
`;

//   TableHeader,
//   TableInside,
//   HoverTable,
//   Font,
