import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { YesIcon } from '../../../../assets/icons';

const TableListerCell = ({
  value, type, variants, onContextMenu,
}) => {
  const displayValue = useMemo(
    () => {
      if (type === 'date') {
        if (!value) {
          return '';
        }
        const d = new Date(value);
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
        return d.toLocaleString('uk', {
          day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
        });
      }
      if (type === 'moneys') {
        const n = value ? Number.parseFloat(value) : 0;
        return n.toLocaleString('uk', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
      if (type === 'boolVariants') {
        return variants[value ? 0 : 1];
      }
      if (type === 'bool') {
        return value ? <img src={YesIcon} alt="YesIcon" /> : null;
      }
      if (type === 'variants') {
        return value in variants ? variants[value] : value;
      }
      if (value instanceof Object && 'repr' in value) {
        return value.repr;
      }
      return value;
    },
    [type, value, variants],
  );
  return (
    <td className={type} title={typeof displayValue === 'string' ? displayValue : ''} onContextMenu={onContextMenu}>
      {displayValue}
    </td>

  );
};

TableListerCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({
      id: PropTypes.string,
      repr: PropTypes.string,
    }),
  ]),
  type: PropTypes.oneOf([
    'date', 'moneys', 'boolVariants', 'bool', 'default', 'variants',
  ]),
  variants: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({}),
  ]),
  onContextMenu: PropTypes.func.isRequired,
};

TableListerCell.defaultProps = {
  value: null,
  type: 'default',
  variants: ['Так', 'Ні'],
};

export default memo(TableListerCell);
