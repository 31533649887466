import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import TableListerCell from '../tablecell';

const TableCalcListerRow = ({
  row, columns, onFocus, selected, tabIndex, onContextMenu, actions,
}) => {
  const onClickHandler = useCallback(
    (e) => {
      onFocus(e, row.id);
    },
    [onFocus, row.id],
  );
  const className = useMemo(
    () => {
      let result = 'catrow';
      result += `${selected ? ' selected' : ''} `;
      return result;
    },
    [selected],
  );

  // const canBeOpened = isFolder || !permissions.foldersUsed;
  return (
    <tr
      data-id={row.id}
      onClick={onClickHandler}
      className={className}
      tabIndex={tabIndex}
      onDoubleClick={(e) => actions.onRowDoubleClick(e, row.id)}
    >
      {columns.map((c) => (
        <TableListerCell
          key={c.key}
          value={row[c.name]}
          variants={c.variants}
          type={c.type}
          onContextMenu={(e) => onContextMenu(e, row.id)}
        />
      ))}
    </tr>

  );
};

TableCalcListerRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.oneOf([
      'date', 'moneys', 'boolVariants', 'bool',
    ]),
  })).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onFocus: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    onToggle: PropTypes.func.isRequired,
    enEdit: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
};

export default memo(TableCalcListerRow);
