import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  Ddate: { label: 'Дата підстави', name: 'ДатаОснования', visible: false },
  FullName: { label: 'Повне найменування', name: 'ПолНаименование', visible: true },
  NoVisible: {
    name: 'флНедействующий',
    label: 'Не враховувати в розрахунках',
    visible: false,
  },
  IsFact: {
    name: 'ЭтоФакт',
    label: 'Це факт',
    visible: false,
  },
};

const footer = { ...columns };
/**
 * @const
 */
export const basis = {
  label: 'Підстава',
  name: 'basis',
  backendName: 'ОснованиеПриказа',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/basis',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'FullName',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default basis;
