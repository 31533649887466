import React, {
  memo, useMemo,useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput, StringInput, CheckboxInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/catalogs/individuals';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';
// import PaymentGroups from "../../../../../../constants/meta/enums/paymentGroups";
import enums from '../../../../../../constants/meta/enums';
import individuals from "../../../../../../constants/meta/catalogs/individuals";

const tablename = 'educationF';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, activeCol, readOnly, highlights, onChange, onSR,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onCalcChange = useCallback(
      async (e, v) => {
        await onRowChange(e, { [tableMD.columns.calc.name]: v });
        await onSR('CHANGE_CALC_IN_TABLE', { rowId: rowIndex });
      },
      [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol} noGutters>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col
            className="d-flex"
          >
            <Col>
              <TableRowCell column="eType" highlighted={highlights.includes('eType')}>
                <StringInput
                  value={row[tableMD.columns.eType.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.eType.name]: v })}
                  readOnly={readOnly}
                  maxLength={10}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="instit" highlighted={highlights.includes('instit')}>
                <StringInput
                    value={row[tableMD.columns.instit.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.instit.name]: v })}
                    readOnly={readOnly}
                    maxLength={10}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="spec" highlighted={highlights.includes('spec')}>
                <StringInput
                    value={row[tableMD.columns.spec.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.spec.name]: v })}
                    readOnly={readOnly}
                    maxLength={10}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="dipl" highlighted={highlights.includes('dipl')}>
                <StringInput
                    value={row[tableMD.columns.dipl.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.dipl.name]: v })}
                    readOnly={readOnly}
                    maxLength={50}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="yEnd" highlighted={highlights.includes('yEnd')}>
                <NumberInput
                    value={row[tableMD.columns.yEnd.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.yEnd.name]: v })}
                    readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="qual" highlighted={highlights.includes('qual')}>
                <StringInput
                    value={row[tableMD.columns.qual.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.qual.name]: v })}
                    readOnly={readOnly}
                    maxLength={100}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="facult" highlighted={highlights.includes('facult')}>
                <StringInput
                    value={row[tableMD.columns.facult.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.facult.name]: v })}
                    readOnly={readOnly}
                    maxLength={50}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="degree" highlighted={highlights.includes('degree')}>
                <StringInput
                    value={row[tableMD.columns.degree.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.degree.name]: v })}
                    readOnly={readOnly}
                    maxLength={50}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="fEducation" highlighted={highlights.includes('fEducation')}>
                <StringInput
                    value={row[tableMD.columns.fEducation.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.fEducation.name]: v })}
                    readOnly={readOnly}
                    maxLength={10}
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.fEducation.name]: PropTypes.string,
    [tableMD.columns.degree.name]: PropTypes.string,
    [tableMD.columns.facult.name]: PropTypes.string,
    [tableMD.columns.qual.name]: PropTypes.string,
    [tableMD.columns.dipl.name]: PropTypes.string,
    [tableMD.columns.spec.name]: PropTypes.string,
    [tableMD.columns.yEnd.name]: PropTypes.number,
    [tableMD.columns.instit.name]: PropTypes.string,
    [tableMD.columns.eType.name]: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
