import {
  useCallback, useContext, useMemo, useRef, useState,
} from 'react';
import {
  fromJS, List, Map, OrderedMap,
} from 'immutable';
import definition from '../../../constants/meta';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
// import { newFile } from '../../notifier';

const getMaxId = (t) => t.reduce((R, r, k) => (Number(k.slice(1)) > R ? Number(k.slice(1)) : R), 0);
const SELECTED_KEY = '_SELECTED';
const TOGGLED_KEY = '_TOGGLED';
const LEVEL_KEY = '_LEVEL';

const useReportEditor = (reportParams) => {
  const {
    modelName,
  } = useMemo(() => reportParams, [reportParams]);

  const modelType = 'rep';

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const currentData = useRef(null);
  const [data, setData] = useState({
    current: {},
    history: {
      data: [], pointer: null,
    },
    autoExecute: false,
    modelType,
    modelName,
    headerForm: [],
    tables: [],
    result: [],
    dataCompositionSettings: [],
    isProcessing: true,
    isChanged: false,
    isValidated: false,
    hasError: false,
    errorMsg: null,
    disabledFields: [],
  });

  currentData.current = data.current;
  const controller = new AbortController();

  const appContest = useContext(AppContext);

  const serverRequestError = useCallback((e) => {
    setErr({
      errorMsg: e,
      model: modelName,
    });

    setData({
      ...data,
      hasError: true,
      isProcessing: false,
      errorMsg: err,
    });
  });

  const dcSettingsMapper = (fieldName, response, oldValue = null) => {
    const getIndexedCollection = (collection) => new Map(collection);
    const getAvailableCollection = (DCS, section) => {
      // Если доступные поля не пришли - используем старые
      if (!DCS[section] && !!oldValue) return oldValue[section].items;
      return getIndexedCollection(DCS[section]);
    };

    let GAV = new Map();
    let FAV = new Map();
    let OAV = new Map();
    let SAV = new Map();
    let CAFAV = new Map();
    let Group = new Map();
    let Filter = new Map();
    let Order = new Map();
    let Selection = new Map();
    let ConditionalAppearance = new Map();

    const proSet = (obj, parent = null) => {
      const mapKeys = getIndexedCollection(obj);
      const compileItem = (item) => {
        // Устнаовть парент по таблице перекодировки
        let newItem = new Map();
        if (item.get('Parent') === null) {
          newItem = item;
        } else if (item.get('Parent') === '') {
          newItem = item.set('Parent', null);
        } else {
          newItem = item.set('Parent', mapKeys.get(item.get('Parent'), parent));
        }

        // Проработать таблицу
        if (newItem.get('Type', '') === 'Table') {
          newItem = newItem.set('Columns', proSet(newItem.get('Columns')));
          newItem = newItem.set('Rows', proSet(newItem.get('Rows')));
        }
        return newItem;
      };
      return indexTable(tempO.entries(([k, v]) => [mapKeys.get(k), compileItem(v)]));
    };

    if (response[fieldName] !== undefined) {
      GAV = getAvailableCollection(response[fieldName], 'GroupAvailableFields');
      FAV = getAvailableCollection(response[fieldName], 'FilterAvailableFields');
      OAV = getAvailableCollection(response[fieldName], 'OrderAvailableFields');
      SAV = getAvailableCollection(response[fieldName], 'SelectionAvailableFields');
      CAFAV = getAvailableCollection(response[fieldName], 'ConditionalAppearanceFilterAvailableFields');
      Group = proSet(response[fieldName].Group);
      Filter = proSet(response[fieldName].Filter);
      Order = proSet(response[fieldName].Order);
      Selection = proSet(response[fieldName].Selection);
      ConditionalAppearance = proSet(response[fieldName].ConditionalAppearance)
        .map((cRow) => cRow.set('Filter', proSet(cRow.get('Filter', new Map()))));
    }

    return new Map({
      Group,
      Filter,
      Order,
      Selection,
      ConditionalAppearance,

      GroupAvailableFields: new Map({
        items: GAV,
        visibleItems: generateVisibleItems(GAV),
      }),

      FilterAvailableFields: new Map({
        items: FAV,
        visibleItems: generateVisibleItems(FAV),
      }),
      OrderAvailableFields: new Map({
        items: OAV,
        visibleItems: generateVisibleItems(OAV),
      }),
      SelectionAvailableFields: new Map({
        items: SAV,
        visibleItems: generateVisibleItems(SAV),
      }),
      ConditionalAppearanceFilterAvailableFields: new Map({
        items: CAFAV,
        visibleItems: generateVisibleItems(CAFAV),
      }),
    });
  };

  const processServerReq = useCallback((method, extra = null) => {
    // controller = new AbortController();
    const { signal } = controller;
    /**
    * @const
    * @type {Immutable.Map}
    */

    const modelDef = definition[modelType][modelName];

    setData({
      ...data,
      isProcessing: true,
      hasError: false,
    });

    const DCSNode = data.dataCompositionSettings;

    // console.log('DCSNode', DCSNode);

    // const DCS = new Map({
    //   Group: DCSNode.Group,
    //   Filter: DCSNode.Filter,
    //   Order: DCSNode.Order,
    //   Selection: DCSNode.Selection,
    //   ConditionalAppearance: DCSNode.ConditionalAppearance,
    // });

    const itemData = {
      ...currentData.current,
      dataCompositionSettings: {},
      // dataCompositionSettings: DCS.toJS(),
    };

    api
      .post$(`${modelType}/${modelDef.backendName}/SR`, {
        signal, method, item: itemData, ...extra,
      }, appContest)
      .then((d) => {
        if (d.ok) {
          const dJSON = d.json();
          // eslint-disable-next-line no-use-before-define
          serverReqDone(dJSON);
          if (method === 'reportToFile') {
            dJSON.then((ff) => {
              const f = ff.fileFromReport;
              // newFile(f.filename, f.content, f.type, f.description);
            });
          }
        } else if (d.status === 422) {
          d.json().then(
            // eslint-disable-next-line no-underscore-dangle
            (dt) => serverRequestError(dt._Error),
          );
        } else {
          serverRequestError(`${d.status} ${d.statusText}`);
        }
      })
      .catch((e) => {
        if (!signal.aborted) {
          serverRequestError(e);
        }
      });
  }, []);

  const generate = useCallback(() => {
    setData({
      ...data,
      autoExecute: false,
    });
    processServerReq('generate');
  }, [data, processServerReq]);

  const serverReqDone = useCallback(async (promiseResponse) => {
    /**
    * @const
    * @type {Immutable.Map}
    */
    const { autoExecute } = data;

    const response = await promiseResponse;

    // const DCS = dcSettingsMapper('DataCompositionSettings', response, data.dataCompositionSettings);

    const item = {
      ...response,
      // dataCompositionSettings: DCS,
      model: modelName,
    };

    setLoading(false);
    currentData.current = {
      ...item,
    };

    setData({
      current: currentData.current,
      isProcessing: false,
    });

    if (autoExecute) {
      // eslint-disable-next-line no-use-before-define
      generate();
    }
    // eslint-disable-next-line no-use-before-define
  }, [data, generate, modelName, modelType]);

  const cancelRequest = () => {
    setData({
      ...data,
      isProcessing: false,
    });
    // controller.abort();
  };

  /**
  * @param _type {string}
  * @param model {string}
  * @param mapInitialOptions {object}
  * @param mapStore {function}
  * @param autoExecute {bool}
  * @return {function(*)}
  */
  const init = useCallback((
    _type,
    model,
    mapInitialOptions = null,
    autoExecute = false,
  ) => {
    if (mapInitialOptions) {
      const initOptions = Object.keys(data).reduce((r, key) => ({
        ...r,
        [mapInitialOptions[key]]: data.get(key),
      }), {});
      processServerReq('INIT', initOptions);
      // setData({
      //   ...initOptions,
      //   autoExecute,
      // });
    } else {
      processServerReq('INIT');
    }
  }, [data]);

  const changeField = useCallback((path, value) => {
    /**
    * @const
    * @type {bool}
    */

    currentData.current[path] = value;

    setData({
      ...data,
      current: currentData.current,
    });
  }, []);

  const activateTableRow = useCallback((tableName, rowId, isMutiple = false) => {
    /**
    * @const
    * @type {bool}
    */
    /**
    * @const
    * @type {Immutable.List}
    */
    if (isMutiple) {
      currentData.current[`tables/${tableName}`].rowId.IS_ACTIVE = !currentData.current[`tables/${tableName}`].rowId.IS_ACTIVE;
    } else {
      currentData.current[`tables/${tableName}`].rowId.IS_ACTIVE = !data[`tables/${tableName}`].rowId.IS_ACTIVE;
    }
    setData({
      ...data,
      tables: currentData.current,
    });
  }, [data]);

  const fillTable = useCallback((tableName, path, value) => {
    /**
    * @const
    * @type {Immutable.Map}
    */

    const table = data[`tables/${tableName}`];

    const newTable = table.map((row) => {
      row[path] = value;
      return row;
    });

    setData({
      ...data,
      [`tables/${tableName}`]: newTable,
      isChanged: true,
    });
    // .set(
    //   `tables/${payload.name}`,
    //   payload.items,
    // );
  }, []);

  const DCSelectField = useCallback((path, field, multiple = false) => {
    const oldTable = data[path];
    const newTable = multiple ? oldTable : oldTable.map((_r) => _r.delete(SELECTED_KEY));
    const newTable2 = field
      ? newTable[field][SELECTED_KEY][!newTable[field][SELECTED_KEY]]
      : newTable;
    if (!oldTable.equals(newTable2)) {
      setData({
        ...data,
        isChanged: true,
        [path]: newTable2,
      });
    }
  }, [data]);

  const DCToggleFieldDone = useCallback((path, field) => {
    setData({
      ...data,
      isChanged: true,
      [path]: data[path].items,
    });
  }, [data]);

  const DCToggleField = useCallback((path, field) => {
    const modelDef = definition[modelType][modelName];

    const cVItem = data[path].visibleItems[field];
    if (cVItem.itemsCount && !cVItem.TOGGLED_KEY && !cVItem.Items) {
      api.post$(`${modelType}/${modelDef.backendName}/getAvailableParent`, {
        field,
        section: path[1],
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((dt) => {
              const fPath = field.split('.')
                .reduce((R, p) => [...R, R.length ? `${R[R.length - 1]}.${p}` : p], [])
                .reduce((R, p) => [...R, p, 'Items'], []);
              const inclPath = `${path}.items.${fPath}`;
              setData({
                ...data,
                isChanged: true,
                [inclPath]: dt.Items,
              });
              DCToggleFieldDone(path, field);
            });
          } else {
            throw new Error(`${resp.status} ${resp.statusText}`);
          }
        })
        .catch((e) => serverRequestError(e.message));
    } else {
      DCToggleFieldDone(path, field);
    }
  }, [data]);

  const DCDeleteField = useCallback((path, id = null) => {
    const KEY = '_SELECTED';

    const deleteBroken = (table) => table.filter((row) => !row.Parent || table.has(row.Parent));
    let newTable1;
    if (!id) {
      newTable1 = data[path].filter((row) => !row[KEY]);
    } else {
      newTable1 = data[path].filter((row, rId) => rId !== id);
    }
    let newTable2;
    while (true) {
      newTable2 = deleteBroken(newTable1);
      if (newTable2.equals(newTable1)) {
        break;
      }
      newTable1 = newTable2;
    }

    setData({
      ...data,
      isChanged: true,
      [path]: newTable2,
    });
  }, [data]);

  /**
  * Лобавление полей в раздел
  * @param path {[string, string]} - Узел стора куда добвляется поле(я).
  * @param fields - Сами поля, которые добавляются
  * @param parentId - Кто родитель
  * @param index - Куда
  * @return {function(*, *)}
  * @constructor
  */
  const DCAddField = useCallback((path, fields, parentId, index) => {
    const tableName = '1'; // ???
    const table = data[`tables/${tableName}`];
    const maxId = getMaxId(table);
    // const remapParents = table.mapEntries(([k, v]) => [k, k < index ? k : k + fields.size]);
    const before = table.slice(0, index);
    const after = table.slice(index);
    const newFields = fields.entries(([k, v], i) => [
      `_${maxId + i + 1}`,
      v
        .set('Use', true).set('Parent', parentId),
    ]).setIn([`_${maxId + fields.size}`, SELECTED_KEY], true);

    const newTable = before
      .merge(newFields)
      .merge(after);
    setData({
      ...data,
      isChanged: true,
      [path]: newTable,
    });
  }, [data]);

  const getFile = useCallback((paramGetter) => {
    /**
    * @const
    * @type {Immutable.Map}
    */
    const modelDef = definition[modelType][modelName];

    api
      .post$(`${modelType}/${modelDef.backendName}/GetFile`, { item: data.current, ...paramGetter })
      .then((r) => {
        if (r.ok) {
          r.json().then((d) => {
            // newFile(d.filename, d.content, d.type, d.description);
          });
        } else {
          serverRequestError(r.status);
        }
      })
      .catch((e) => {
        serverRequestError(e);
      });
  }, [data, modelName, modelType, serverRequestError]);

  /**
  * Перемещение полей внутри одного раздела
  * @param path {string} - Узел стора куда добвляется поле(я).
  * @param fields - Сами поля, которые добавляются
  * @param from - отклуда перемещаем
  * @param to - куда перемещаем
  * @param newParentId - Новый Ролитель
  * @return {function(*, *)}
  * @constructor
  */
  const goToOldVersion = useCallback(() => {
    const modelDef = definition[modelType][modelName];

    const getOldVersionLink = async () => {
      const url = `${modelType}/${modelDef.backendName}/getURL`;
      const r = await api.get$(url);
      if (!r.ok) {
        let e;
        try {
          e = await r.text();
        } catch {
          serverRequestError(`${r.status} ${r.statusText}`);
        }
      }
      return r.json();
    };

    getOldVersionLink()
      .then((d) => {
        try {
          window.open(d.oldVersionRef, '_blank').focus();
        } catch {
          serverRequestError('В адресній строці необхідно дозволити відкривання нових вкладок');
        }
      })
      .catch((e) => {
        serverRequestError(e);
      });
  }, [modelName, modelType]);

  const reportToFile = useCallback((typeFile) => {
    setData({
      ...data,
      autoExecute: false,
    });
    processServerReq('reportToFile', { format: typeFile });
  }, [data]);

  const actions = useMemo(
    () => ({
      init,
      processServerReq,
      generate,
      changeField,
      activateTableRow,
      fillTable,
      DCAddField,
      DCSelectField,
      DCDeleteField,
      DCToggleField,
      cancelRequest,
      goToOldVersion,
      // reportToFile,
      // getFile,
      loading,
    }),
    [
      DCDeleteField,
      DCSelectField,
      DCToggleField,
      activateTableRow,
      cancelRequest,
      changeField,
      fillTable,
      generate,
      //   getFile,
      //   goToOldVersion,
      init,
      processServerReq,
    ],
  );

  return {
    data: data.current,
    err,
    actions,
  };
};

export default useReportEditor;
