const catSelectors = {
  budget: import('./budget/lister/selector'),
  baseDoc: import('./baseDoc/lister/selector'),
  csu: import('./CSU/lister/selector'),
  users: import('./users/lister/selector'),
  elementFond: import('./elementFond/lister/selector'),
  responsiblePerson: import('./responsiblePerson/lister/selector'),
  fondClassifier: import('./fondClassifier/lister/selector'),
  firstIndicators: import('./firstIndicators/lister/selector'),
  kbpClassifier: import('./KBPClassifier/lister/selector'),
  units: import('./units/lister/selector'),
  unitsCategory: import('./unitsCategory/lister/selector'),
  branches: import('./branches/lister/selector'),
  category: import('./category/lister/selector'),
  basis: import('./basis/lister/selector'),
  stateUnits: import('./stateUnits/lister/selector'),
  post: import('./post/lister/selector'),
  postClassifier: import('./postClassifier/lister/selector'),
  tarif: import('./tarif/lister/selector'),
  pedagogicalLoad: import('./pedagogicalLoad/lister/selector'),
  individuals: import('./individuals/lister/selector'),
  familyStatus: import('./familyStatus/lister/selector'),
  education: import('./education/lister/selector'),
  experienceTypes: import('./experienceTypes/lister/selector'),
  csuLvl: import('./csuLvl/lister/selector'),
  kvkClassifier: import('./kvkClassifier/lister/selector'),
  banks: import('./banks/lister/selector'),
  externalReports: import('./externalReports/lister/selector'),
  signatureVariants: import('./signatureVariants/lister/selector'),
  repForm: import('./repForm/lister/selector'),
  basisDocs: import('./basisDocs/lister/selector'),
  baseDocYear: import('./baseDocYear/lister/selector'),
  csuGroup: import('./csuGroup/lister/selector'),
};

export default catSelectors;
