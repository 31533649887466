import React from 'react';
import {
  MainIcon,
  PlanningIcon,
} from '../../../assets/icons';

import def from '..';

const NavPanel = {
  main: {
    name: 'main',
    label: 'Головна',
    link: '/',
    icon: (<img src={MainIcon} alt="Main" title="Головна" />),
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.personCard, primary: true },
        ],
      },
    ],

  },

  analysis: {
    name: 'analysis',
    label: 'Аналіз структури заробітної плати',
    link: '/analysis/',
    icon: (<img src={PlanningIcon} alt="analysis" title="Аналіз структури заробітної плати" />),
    boxes: [
      {
        name: 'catalogs',
        label: 'Довідники',
        items: [
          { def: def.cat.branches, primary: true },
          { def: def.cat.category, primary: true },
          { def: def.cat.baseDoc, primary: true },
          { def: def.cat.post, primary: true },
          { def: def.cat.units, primary: true },
          { def: def.cat.stateUnits, primary: true },
          { def: def.cat.individuals, primary: true },
        ],
      },
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.personCard, primary: true },
          { def: def.doc.indicatorsStartYear, primary: true },
          { def: def.doc.changeToStaffList, primary: true },
          { def: def.doc.calcNightAndHoliday, primary: true },
          { def: def.doc.calcTarifList, primary: true },
        ],
      },
      {
        name: 'reports',
        label: 'Звiти',
        items: [
          { def: def.rep.seniority, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.repTypeCharges, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.applicate, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.applicateYearNew, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.indicatorsStartYear, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.staffList, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.numberApprovedDocs, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.stateData, primary: true, parent: 'generalAnalytics' },
        ],
      },

    ],
  },

  catalogs: {
    name: 'catalogs',
    label: 'Довідники',
    link: '/catalogs/',
    icon: (<img src={PlanningIcon} alt="catalogs" title="Довідники" />),
    boxes: [
      {
        name: 'catalogs',
        label: 'Довідники',
        items: [
          { def: def.cat.csu, primary: true },
          { def: def.cat.responsiblePerson, primary: true },
          { def: def.cat.budget, primary: true },
          { def: def.cat.firstIndicators, primary: true },
          { def: def.cat.unitsCategory, primary: true },
          { def: def.cat.post, primary: true },
          { def: def.cat.postClassifier, primary: true },
          { def: def.cat.tarif, primary: true },
          { def: def.cat.pedagogicalLoad, primary: true },
          { def: def.cat.familyStatus, primary: true },
          { def: def.cat.education, primary: true },
          { def: def.cat.experienceTypes, primary: true },
          { def: def.cat.csuLvl, primary: true },
          { def: def.cat.kvkClassifier, primary: true },
          { def: def.cat.banks, primary: true },
          { def: def.cat.externalReports, primary: true },
          { def: def.cat.signatureVariants, primary: true },
          // { def: def.calc.mainCalc, primary: true },
          { def: def.cat.repForm, primary: true },
          { def: def.cat.basisDocs, primary: true },
          { def: def.cat.baseDocYear, primary: true },
          { def: def.cat.csuGroup, primary: true },
          { def: def.cat.fondClassifier, primary: true },
        ],
      },
    ],
  },

  services: {
    name: 'services',
    label: 'Сервіс',
    link: '/services/',
    icon: (<img src={PlanningIcon} alt="service" title="Сервіс" />),
    boxes: [
      {
        name: 'service',
        label: 'Сервіс',
        items: [
          { def: def.dp.setWorkDate, primary: true },
          { def: def.cat.repForm, primary: true },
        ],
      },

    ],
  },

};

export default NavPanel;
