import React from 'react';
import PropTypes from 'prop-types';
import useWinManager from '../../components/windowsManager/Hooks/useWinManager';
import WinManagerContext from '../../providers/winManagerProvider';

const WinmgrRoot = ({ children }) => {
  const winManager = useWinManager();
  return (
    <WinManagerContext.Provider value={winManager}>
      {children}
    </WinManagerContext.Provider>

  );
};

WinmgrRoot.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default WinmgrRoot;
