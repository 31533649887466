import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  inOrder: { label: 'Порядок', name: 'Порядок', visible: false },
};

const footer = { ...columns };
/**
 * @const
 */
export const csuLvl = {
  label: 'Рівні розпорядників',
  name: 'csuLvl',
  backendName: 'УровниРаспорядителя',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/csuLvl',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default csuLvl;
