/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';

const APMenu = ({ id, ...rest }) => (
  <DocEditorCommandPanel
    id={id}
    {...rest}
  />
);

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default APMenu;
