import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  normative: { label: 'Нормативна кількість годин', name: 'НормативноеКоличествоЧасов', visible: false },

};

const footer = { ...columns };
/**
 * @const
 */
export const pedagogicalLoad = {
  label: 'Педагогічне навантаження',
  name: 'pedagogicalLoad',
  backendName: 'ПедагогическаяНагрузка',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/pedagogicalLoad',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default pedagogicalLoad;
