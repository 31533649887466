import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  UDK: { label: 'Код Головного управління Державного казначейства', name: 'КодУДК', visible: false },
  VDK: { label: 'Код відділу державного казначейства', name: 'КодВДК', visible: false },
  terra: { label: 'Код території', name: 'КодТеритории', visible: false },
  nameRasp: { label: 'Назва для ропорядження', name: 'НаименованиеРаспоряжение', visible: false },
  UDKName: { label: 'Назва УДК', name: 'НаимУДК', visible: false },
  VDKName: { label: 'Назва ВДК', name: 'НаимВДК', visible: false },
};

const footer = { ...columns };
/**
 * @const
 */
export const banks = {
  label: 'Органи ДКУ',
  name: 'banks',
  backendName: 'Банки',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/banks',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default banks;
