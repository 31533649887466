import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const EditorHeader = ({
  header,
  onClose,
  onSaveNClose,
  isChanged,
  loading,
}) => {
  const [q, setQ] = useState(false);

  const onCloseClick = useCallback(
    () => {
      if (isChanged || loading) {
        setQ(true);
      } else {
        onClose();
      }
    },
    [isChanged, loading, onClose],
  );

  return (
    <>
      <div className="d-flex ml-3 justify-content-between">
        <h3>{header}</h3>
        <Button variant="link" onClick={onCloseClick} className="ml-auto">
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
      <Modal show={q} onHide={() => setQ(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Вихід без запису
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={onSaveNClose}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Зберегти зміни та вийти
          </Button>
          <Button
            variant="danger"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
            Вийти без збереження
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EditorHeader.propTypes = {
  header: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSaveNClose: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

EditorHeader.defaultProps = {
  header: '',
  loading: false,
};

export default EditorHeader;
