import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { kbpClassifier as md } from '../../../../constants/meta/catalogs/kbpClassifier';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const KBPClassifier = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
            required
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Industry.label}
            value={data[md.columns.Industry.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.Industry.name]: value,
              });
            }}
            modelType="cat"
            modelName="kfkvkClassifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <h6 className="font-weight-bold text-primary">
        Код та назва, станом на вказану дату
      </h6>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateKl.label}
            value={data[md.columns.DateKl.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.DateKl.name]: value,
              });
              actions.onSR('ПриИзменении_Дата');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.CodeOnDate.label}
            value={data[md.columns.CodeOnDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CodeOnDate.name]: value,
            })}
            readOnly={readOnly}
            maxLength={4}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.Note.label}
            value={data[md.columns.Note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Note.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.KFK4.label}
            value={data[md.columns.KFK4.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KFK4.name]: value,
            })}
            modelType="cat"
            modelName="kfkvkClassifier"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.parent.name]: value,
              });
            }}
            modelType="cat"
            modelName="kbpClassifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
};

KBPClassifier.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default KBPClassifier;
