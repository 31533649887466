import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { units as md } from '../../../../constants/meta/catalogs/units';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const Units = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
          <Col>
              <EditorControls.TextInput
                  label={md.columns.FullName.label}
                  value={data[md.columns.FullName.name]}
                  onChange={(e, value) => actions.onChange({
                      [md.columns.FullName.name]: value,
                  })}
                  readOnly={readOnly}
                  rows={3}
              />
          </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.KeySort.label}
            value={data[md.columns.KeySort.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KeySort.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Categ.label}
            value={data[md.columns.Categ.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Categ.name]: value,
            })}
            modelType="cat"
            modelName="unitsCategory"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Owner.label}
            value={data[md.columns.Owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Owner.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
};

Units.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Units;
