import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const StyledModalBackGround = styled.div`
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  position: fixed;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalWindow = styled.div`
  position: fixed;
  background: white;
  left: 39%;
  top: 36%;
  padding: 40px;  
  font-family: Roboto, sans-serif;  
  border-radius: 4px;
  z-index: 9999;
`;

const TextForModal = styled.p`
  color: black;
  font-size: 1.5em;
  text-align: center;
  font-weight: 700;
`;

const ModalButton = styled.button`
  outline: none;
  cursor: pointer;
  padding: 6px 8px;
  border: none;
  margin-top: 40px;
  margin-left: 53px;
  min-width: 86px;
  line-height: 20px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: white;
  border-radius: 4px;
  :focus {outline:none;}
`;
const ModalButtonExit = styled(ModalButton)`
  background: linear-gradient(180deg,#338bc0 0%,#062a53 100%);
   &:hover{
     background: #062a53;
  };
`;
const ModalButtonCancel = styled(ModalButton)`
  background: linear-gradient(180deg,#ec3737 0%,#cc0000 100%);
   &:hover{
     background: #cc0000;
  };
`;

// localStorage.clear();

const LogOutModal = ({ closeModal, handleLogout }) => {
  const handleDecisionClick = (event) => {
    let res;
    if (event.keyCode === 27) {
      event.preventDefault();
      res = document.getElementById('cancel') && document.getElementById('cancel')
        .click();
    }
    if (event.keyCode === 13) {
      event.preventDefault();
      res = document.getElementById('exit') && document.getElementById('exit')
        .click();
    }
    return res;
  };

  useEffect(
    () => document.addEventListener('keyup', handleDecisionClick),
    [],
  );

  return (
    <ModalWindow className="ModalWindow">
      {
        createPortal(
          <StyledModalBackGround onClick={closeModal} />,
          document.body,
        )
      }
      <TextForModal>Ви впевнені, що бажаєте вийти?</TextForModal>
      <ModalButtonExit id="exit" onClick={() => debounce(handleLogout(), 500)}>Вихiд</ModalButtonExit>
      <ModalButtonCancel id="cancel" onClick={closeModal}>Скасувати</ModalButtonCancel>
    </ModalWindow>
  );
};

LogOutModal.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default LogOutModal;
