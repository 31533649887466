import React, {
  memo, useMemo,useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput, StringInput, DateInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/catalogs/individuals';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';


const tablename = 'rasp';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, activeCol, readOnly, highlights, onChange, onSR,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol} noGutters>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col
            className="d-flex"
          >
            <Col>
              <TableRowCell column="rasp" highlighted={highlights.includes('rasp')}>
                <ItemPicker
                    value={row[tableMD.columns.rasp.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.rasp.name]: v })}
                    modelType="cat"
                    modelName="csu"
                    readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.rasp.name]: referencePropType,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
