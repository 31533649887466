import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import def from '../../constants/meta';

const Header = styled.h2`
background-color: white;
position: sticky;
z-index: 1;
top: 0;
font-weight: 700;
font-size: 16px;
color: #386EAB;`;

const Container = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
`;

const SmallContainer = styled.div`
width: 400px;
`;

// const FILTER_KEYS = ['label'];

const types = {
  cat: 'Довідники',
  doc: 'Документи',
  dp: 'Обробки',
  rep: 'Звіти',
  ChTP: 'Додаткові властивості',
  infoRegs: 'Регістри відомостей',
};

const SearchResults = ({ inputValue }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(Object.entries(def).map((type) => type.reduce((RR, modelName) => ({
      ...RR,
      [types[type[0]]]: Object.values(modelName)
        .filter((i) => i.label && i.label.toLowerCase().includes(inputValue.toLowerCase())),
    }), {})));
  }, [inputValue]);

  return (
    <>
      {inputValue ? (
        <Container className="mt-2">
          {items.map((type) => (
            Object.values(type)[0].length > 0
                    && (
                      <SmallContainer key={Object.keys(type)}>
                        <Header className="mt-2">{Object.keys(type)}</Header>
                        {Object.values(type)[0].map((i) => (
                          <Nav.Link key={i.modelName} href={`/#/${i.modelType}/${i.modelName}/`}>
                            {i.label}
                          </Nav.Link>
                        ))}
                      </SmallContainer>
                    )
          ))}
        </Container>
      ) : (<></>)}
    </>
  );
};

SearchResults.propTypes = {
  inputValue: PropTypes.string,
};

SearchResults.defaultProps = {
  inputValue: '',
};

export default SearchResults;
