import React from 'react';
import PropTypes from 'prop-types';

const Minus = ({
  color, style,
}) => (
  <svg width={style.width || '10'} height={style.height || '4'} style={style} viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L8 2" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Minus.propTypes = {
  color: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};

Minus.defaultProps = {
  color: '#4281C9',
  style: {},
};

export default Minus;
