import seniority from './seniority';
import repTypeCharges from './repTypeCharges';
import applicate from './applicate';
import applicateYearNew from './applicateYearNew';
import indicatorsStartYear from './indicatorsStartYear';
import staffList from './staffList';
import numberApprovedDocs from './numberApprovedDocs';
import stateData from './stateData';

const reports = {
  seniority,
  repTypeCharges,
  applicate,
  applicateYearNew,
  indicatorsStartYear,
  staffList,
  numberApprovedDocs,
  stateData,
};

export default reports;
