import React, { useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { modelType, modelName } from './def';
import TableEditor from '../../newTableEditor';
import {
  ResultSegment,
  ContainerDiv,
} from '../../../components/Form/styledForm';
import ReportContainer from '../reportContainer';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import { useMD } from '../../newLister/hooks/md';

const ReportEditor = ({
  isProcessing, portalOpened, actions, data, sessionOptions,
}) => {
  const md = useMD(modelType, modelName);
  // const readOnly = !sessionOptions.is_admin;
  const readOnly = false;

  // useEffect(() => {
  //   actions.changeField('isNewTypeSign', true);
  //   actions.changeField('isShowCredit', true);
  // }, [actions]);

  const csuFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: data[md.columns.csu.name] }],
    [data],
  );

  const SettingsButton = (
    <>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.budget.name]}
            label={md.columns.budget.label}
            modelType="cat"
            modelName="budget"
            onChange={(e, value) => {
              actions.changeField([md.columns.csu.name], {});
              actions.changeField([md.columns.budget.name], value);
            }}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.csu.name]}
            label={md.columns.csu.label}
            modelType="cat"
            modelName="csu"
            onChange={(e, value) => actions.changeField([md.columns.csu.name], value)}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            value={data[md.columns.dDate.name]}
            label={md.columns.dDate.label}
            onChange={(e, value) => actions.changeField([md.columns.dDate.name], value)}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.basis.name]}
            label={md.columns.basis.label}
            modelType="cat"
            modelName="baseDoc"
            onChange={(e, value) => actions.changeField([md.columns.basis.name], value)}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.csu2.name]}
            label={md.columns.csu2.label}
            modelType="cat"
            modelName="csu"
            onChange={(e, value) => actions.changeField([md.columns.csu2.name], value)}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.fond.name]}
            label={md.columns.fond.label}
            modelType="cat"
            modelName="fondClassifier"
            onChange={(e, value) => actions.changeField([md.columns.fond.name], value)}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            value={data[md.columns.kbp.name]}
            label={md.columns.kbp.label}
            modelType="cat"
            modelName="kbpClassifier"
            onChange={(e, value) => actions.changeField([md.columns.kbp.name], value)}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      actions={actions}
    >
      <ResultSegment>
        <TableEditor
          data={data}
          actions={actions}
        />
      </ResultSegment>
    </ReportContainer>
  );
};

ReportEditor.propTypes = {
  isProcessing: PropTypes.bool,
  portalOpened: PropTypes.bool,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  isProcessing: false,
};

// ReportEditor.displayName = `Report${md.name}Editor`;

export default ReportEditor;
