import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  OnlyYearDBF: {
    label: 'Тільки річні суми',
    name: 'ДБФТолькоГодовые',
    visible: false,
  },
  OnDate: { label: 'НаДату', name: 'НаДату', visible: true },
  ListNamePeriod: {
    label: 'СписокНаименованияПериод',
    name: 'СписокНаименованияПериод',
    visible: true,
  },
  ListNameClassifier: {
    label: 'СписокНаименованияКлассификатор',
    name: 'СписокНаименованияКлассификатор',
    visible: true,
  },
  ListNameFullName: {
    label: 'СписокНаименованияНаименованиеПолное',
    name: 'СписокНаименованияНаименованиеПолное',
    visible: true,
  },
  ListNameCode: {
    label: 'СписокНаименованияКод',
    name: 'СписокНаименованияКод',
    visible: true,
  },
  ListNameInactive: {
    label: 'СписокНаименованияНеДействующий',
    name: 'СписокНаименованияНеДействующий',
    visible: true,
  },
};

export const fondClassifier = {
  label: 'Класификатор фонд',
  name: 'fondClassifier',
  backendName: 'КлассификаторФонд',
  defaultOrder,
  columns,
  frontend: 'cat/fondClassifier',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.onlyItems,
};

export default fondClassifier;
