import PersonCardEditor from './personCard/editor';
import PersonCardLister from './personCard/lister';

import FinancingEditor from './financing/editor';
import FinancingLister from './financing/lister';
import IndicatorsStartYearEditor from './indicatorsStartYear/editor';
import IndicatorsStartYearLister from './indicatorsStartYear/lister';
import ChangeToStaffListEditor from './changeToStaffList/editor';
import ChangeToStaffListLister from './changeToStaffList/lister';
import CalcNightAndHolidayEditor from './calcNightAndHoliday/editor';
import CalcNightAndHolidayLister from './calcNightAndHoliday/lister';
import CalcTarifListEditor from './calcTarifList/editor';
import CalcTarifListLister from './calcTarifList/lister';

const docComponents = {
  personCard: {
    editor: PersonCardEditor,
    lister: PersonCardLister,
  },
  financing: {
    editor: FinancingEditor,
    lister: FinancingLister,
  },
  indicatorsStartYear: {
    editor: IndicatorsStartYearEditor,
    lister: IndicatorsStartYearLister,
  },
  changeToStaffList: {
    editor: ChangeToStaffListEditor,
    lister: ChangeToStaffListLister,
  },
  calcNightAndHoliday: {
    editor: CalcNightAndHolidayEditor,
    lister: CalcNightAndHolidayLister,
  },
  calcTarifList: {
    editor: CalcTarifListEditor,
    lister: CalcTarifListLister,
  },

};

export {
  docComponents,
};

export default {
  docComponents,
};
