import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

const CommandPanelButton = ({
  text,
  children,
  onClick,
  label,
  disabled,
  ...args
}) => (
  <Button onClick={onClick} {...args} title={text} label={label} disabled={disabled}>
    {children}
    {label}
  </Button>
);

CommandPanelButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.symbol, PropTypes.node, PropTypes.string]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

CommandPanelButton.defaultProps = {
  text: '',
  children: null,
  disabled: false,
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
  label: '',
};

export default CommandPanelButton;
