import { proxyApi } from '../constants/misc';
import { tryRefresh } from './auth';

/**
 * @const
 * @type {Set<string>}
 */
const METHODS = new Set(['POST', 'PUT', 'DELETE', 'PATCH', 'GET']);

/**
 * @function
 * @param {object} params
 * @return {string}
 */
const paramConverter = (params) => Object.keys(params)
  .map((k) => `${k}=${params[k]}&`)
  .join('');

/**
 * @function
 * @param {string} method
 * @param {string} uri
 * @param {object} msg
 * @param {object} controller
 * @param {object} appContest
 * @param {string} backend
 * @return {Promise<Response>}
 */

export const initReq = async ({
  method, uri, msg, controller, backend = null, appContest = {},
}) => {
  const {
    accessToken, refreshToken, setSessionParams, AP, setTokens,
  } = appContest;

  if (!accessToken) throw new Error('Відсутній token доступа до backend.');
  const accessPoint = backend || proxyApi[AP];

  const signal = controller ? controller.signal : null;

  const loader = async (t) => fetch(
    `${accessPoint}${uri}${t === 'GET' ? `?${paramConverter(msg)}` : ''}`,
    {
      signal,
      method,
      headers: {
        Authorization: `Bearer ${t}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      mode: 'cors',
      credentials: 'include',
      body: method === 'GET' ? null : JSON.stringify(msg),
    },
  );
  const r = await loader(accessToken);
  if (r.status === 403) {
    const refreshResponse = await tryRefresh({ backEnd: accessPoint, refreshToken });
    if (refreshResponse.ok) {
      const d = await refreshResponse.json();
      const newToken = d.access_token;

      setTokens({
        accessToken: newToken,
        refreshToken,
      });

      return loader(newToken);
    }

    setTokens({
      accessToken: null,
      refreshToken: null,
    });

    setSessionParams({
      sessionOptions: null,
      AP: '',
    });
  }
  return r;
};

/**
 * @function
 * @property {func} post$
 * @property {func} postCat$
 * @property {func} postDoc$
 * @property {func} postInfoReg$
 * @property {func} postAccReg$
 * @property {func} put$
 * @property {func} putCat$
 * @property {func} putDoc$
 * @property {func} putInfoReg$
 * @property {func} putAccReg$
 * @property {func} delete$
 * @property {func} deleteCat$
 * @property {func} deleteDoc$
 * @property {func} deleteInfoReg$
 * @property {func} deleteAccReg$
 * @property {func} patch$
 * @property {func} patchCat$
 * @property {func} patchDoc$
 * @property {func} patchInfoReg$
 * @property {func} patchAccReg$
 * @return {Promise<object>}
 */

const api = new Proxy(initReq, {
  get(target, propKey) {
    const method = [...METHODS].find((el) => propKey.startsWith(el.toLowerCase()));

    if (!method) {
      return null;

      // throw new Error('Unknown or disabled http-method.\n'
      //     + `Use Force or [${[...METHODS]}], Luke.`);
    }

    const fn = propKey
      .substring(method.length)
      .replace(/([a-z])([A-Z])/g, '$1/$2')
      .toLowerCase();

    return (...args) => {
      const controller = args.find((item) => item && item.signal);
      /**
       * @const
       * @desc uri part from argument or empty-string
       * @type {string}
       */

      const partUri = args.shift() || '';

      /**
       * @const
       * @desc Prepared path for request with cutted '$/' at end.
       * @type {string}
       */
      const finalPath = partUri.length
        ? `${fn.slice(0, -1)}/${partUri}`
        : `${fn.slice(0, -1)}`;

      /**
       * @const
       * @type {object}
       */

      const requestData = args.shift() || {};

      const appContest = args.shift() || '';

      return target({
        method,
        uri: `api/req${finalPath}`,
        msg: requestData,
        controller,
        appContest,

      });
    };
  },
});

export default api;
