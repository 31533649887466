import React, {
  PureComponent, createRef,
} from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';

function numberFormat(value, precision) {
  return value.toLocaleString('uk', {
    useGrouping: true,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}

const DECIMAL_POINT = ',';

class NumberInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      focused: false,
      displayValue: '',
      t: 0,
    };
    this.ref = createRef();
    this.cursor = {
      start: 0,
      end: 0,
    };
  }

  static getDerivedStateFromProps(props) {
    return {
      displayValue: numberFormat(props.value, props.precision),
    };
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { focused, displayValue, t } = this.state;
    const { precision, autoSelect } = this.props;
    const mustChangeSelection = (prevState.focused !== focused)
      || (prevState.displayValue !== displayValue)
      || (prevState.t !== t);
    if (!mustChangeSelection) return null;
    if (focused && prevState.focused) {
      const r = prevState.displayValue.length - displayValue.length;
      const v = displayValue.replaceAll(',', DECIMAL_POINT).replaceAll('.', DECIMAL_POINT);
      const l = v.length;
      const st = this.cursor.start;
      if (l < precision + 1) {
        return {
          start: precision + 1,
          end: precision + 1,
        };
      }
      if (r === 0) {
        if (v.substring(st - 1, st) === DECIMAL_POINT) {
          // Нажата десятичная точка
          return {
            start: l - st,
            end: l - st,
          };
        }
        return {
          start: l - st,
          end: l - st,
        };
      }
      if (r > 0) {
        // Удаляем
        return {
          start: l - st,
          end: l - st,
        };
      }
      return {
        start: l - st + 1 + r,
        end: l - st + 1 + r,
      };
    }
    if (focused && !prevState.focused && autoSelect) {
      return {
        start: displayValue.length,
        end: 0,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const l = this.ref.current.value.length;
      this.ref.current.setSelectionRange(l - snapshot.start, l - snapshot.end);
    }
  }

  onChangeHandler = (e) => {
    const {
      precision,
      onChange,
      value,
      minValue, maxValue,
    } = this.props;
    e.persist();
    let textValue = e.target.value
      .replaceAll(',', DECIMAL_POINT)
      .replaceAll('.', DECIMAL_POINT)
      .replaceAll(' ', '');
    const isNegative = textValue.indexOf('-') === textValue.lastIndexOf('-') && textValue.indexOf('-') > -1;
    textValue = textValue.replaceAll('-', '');
    textValue = textValue.replaceAll(DECIMAL_POINT, '.');
    let newValue = parseFloat(textValue);
    if (textValue === '') newValue = 0;
    if (!Number.isNaN(newValue)) {
      if (textValue.indexOf('.') !== textValue.lastIndexOf('.')) {
        const getInc = () => {
          for (let i = 3; i > 0; i -= 1) {
            if (Math.abs(newValue) >= 10 ** (3 * i)) return i + 1;
          }
          return 1;
        };
        this.cursor.start = textValue.indexOf('.') + getInc();
        this.cursor.end = textValue.indexOf('.') + getInc();
      } else {
        this.cursor.start = this.ref.current.selectionStart;
        this.cursor.end = this.ref.current.selectionEnd;
      }
      const s = newValue.toFixed(precision + 1);
      newValue = parseFloat(s.substring(0, s.indexOf('.') + 1 + precision)) * (isNegative ? -1 : 1);

      if (value !== newValue && newValue >= minValue && newValue <= maxValue) {
        onChange(e, newValue);
      } else {
        this.setState({ t: new Date().valueOf() });
      }
    }
  };

  onFocusHandler = (e) => {
    e.persist();
    const { onFocus } = this.props;
    const { focused } = this.state;
    if (!focused) this.setState({ focused: true });
    if (onFocus) onFocus(e);
  };

  onBlurHandler = (e) => {
    e.persist();
    const { onBlur } = this.props;
    this.setState({ focused: false });
    if (onBlur) onBlur(e);
  };

  render() {
    const {
      disabled,
      readOnly,
      onClick,
      size,
      errored,
      className,
      inputClassName,
      append,
      placeholder,
      tabIndex,
    } = this.props;
    const {
      displayValue,
      error,
    } = this.state;
    const inpClsName = `${inputClassName} text-right`;
    return (
      <InputGroup className={className} style={{ backgroundColor: `${readOnly ? '#dadada' : ''}` }}>
        <FormControl
          size={size}
          ref={this.ref}
          value={displayValue}
          // type="number"
          disabled={disabled}
          readOnly={readOnly}
          onClick={onClick}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
          isInvalid={errored || !!error}
          className={inpClsName}
          placeholder={placeholder}
          tabIndex={tabIndex}
          onChange={this.onChangeHandler}
        />
        {append}
        {error && (
          <Feedback type="invalid" tooltip>{error}</Feedback>
        )}
      </InputGroup>
    );
  }
}

NumberInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  errored: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.string,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  append: PropTypes.element,
  autoSelect: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

NumberInput.defaultProps = {
  value: 0,
  minValue: Number.MIN_SAFE_INTEGER,
  maxValue: Number.MAX_SAFE_INTEGER,
  disabled: false,
  readOnly: false,
  onClick: null,
  onFocus: null,
  onBlur: null,
  precision: 0,
  size: null,
  errored: false,
  className: '',
  inputClassName: '',
  append: null,
  autoSelect: true,
  placeholder: '',
  tabIndex: null,
};

export default NumberInput;
