import {
  defaultColumns,
  directions,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  Author: { label: 'Автор', name: 'Автор', visible: true },
  Industry: { label: 'Галузь', name: 'Отрасль', visible: false },
  parent: { label: 'Родитель', name: '_Родитель', visible: false },
  KFK4: {
    label: 'КФК(4зн.)',
    name: 'КлассификаторКФКВК',
    visible: true,
  },
  FullName: {
    label: 'Повна назва',
    name: 'ПолнНаименование',
    visible: true,
  },
  DateKl: {
    label: 'Дата',
    name: 'ДатаКл',
    visible: true,
  },
  CodeOnDate: { label: 'Код на дату', name: 'тКод', visible: true },
  Note: { label: 'Примітка', name: 'Примечание', visible: true },
};

export const kbpClassifier = {
  label: 'Кодифікатор бюджетних програм МБ (КБП)',
  name: 'kbpClassifier',
  backendName: 'КлассификаторКБП',
  defaultOrder: [{
    column: defaultColumns.code.name,
    direction: directions.ascending,
  }],
  columns,
  frontend: 'cat/kbpClassifier',
  listColumns: [...new Set([...reqListerColumns, 'FullName'])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default kbpClassifier;
