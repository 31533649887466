import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  fullName: { label: 'Повне найменування', name: 'ПолНаименование', visible: true },
  inActive: { label: 'Не враховувати в розрахунках', name: 'флНедействующий', visible: true },
};

const tables = {
  tp: {
    label: 'Підстави для підвищення',
    name: 'тчОснований',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      baseDoc: { label: 'Підстава', name: 'ОснованиеПриказа' },
    },
  },
};

const footer = { ...columns };

/**
 * @const
 */
export const baseDocYear = {
  label: 'Підстави для підвіщення на рік',
  name: 'baseDocYear',
  backendName: 'ОснованияПриказаГод',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  tables,
  footer,
  frontend: 'cat/baseDocYear',

  // Remove duplicates
  listColumns: [...new Set([
    ...reqListerColumns,
  ])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  // foldersOnTop: true,
};

export default baseDocYear;
