import React, {
  memo, useCallback, useContext, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  // AppBar, useTheme,
  Box, ButtonGroup, IconButton, Tab, Tabs, Typography,
} from '@mui/material';
// import PropTypes from 'prop-types';
import WinManagerContext from '../../providers/winManagerProvider';

// const ManagerLink = ({ menuExpanded }) => {
const ManagerLink = () => {
  const {
    dellComponentFromWindowsManager,
    linkComponents,
    currentURL,
    nextWindow,
    prevWindow,
    // windowsStack,
  } = useContext(WinManagerContext);
  const navigate = useNavigate();
  // const theme = useTheme();
  // const t = theme.transitions.create(['left']);
  const onCloseIconClick = useCallback(
    (e, value) => {
      e.preventDefault();
      e.stopPropagation();
      dellComponentFromWindowsManager(value);
    },
    [dellComponentFromWindowsManager],
  );
  const knowUrls = useMemo(
    () => linkComponents.map((l) => l.url),
    [linkComponents],
  );
  const tabValue = useMemo(
    () => (knowUrls.includes(currentURL) ? currentURL : false),
    [currentURL, knowUrls],
  );
  return (
    <div
      style={{
        display: 'flex', borderTop: '1px solid #afbbcc', width: '100%',
      }}
    >
      <ButtonGroup size="small">
        <IconButton
          onClick={() => prevWindow(navigate)}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </IconButton>
        <IconButton
          onClick={() => nextWindow(navigate)}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </IconButton>
      </ButtonGroup>

      <Tabs
        value={tabValue}
        onChange={(e, v) => navigate(v, { replace: true })}
        variant="scrollable"
        TabScrollButtonProps={{ sx: { color: 'text.primary' } }}
        scrollButtons="auto"
      >
        {
          linkComponents.map(({ title, url }) => (
            <Tab
              key={`link-key-${url}`}
              sx={{ alignSelf: 'start' }}
              label={(
                <Box display="flex" alignItems="center">
                  <div style={{ paddingBottom: '15px' }}>
                    <Typography variant="subtitle3" sx={{ mr: 1 }}>
                      {title}
                    </Typography>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={(e) => onCloseIconClick(e, url)}
                    />
                  </div>
                </Box>

              )}
              value={url}
            />
          ))
        }
      </Tabs>

    </div>
  );
};

export default memo(ManagerLink);
