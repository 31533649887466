import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/personCard';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.pedLoad;

const TPHeader = ({
  activeCol,
  highlights,
  onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col
      className="px-3  d-flex text-center"
    >
      <Row className="w-100" noGutters>
        <Col
          className="d-flex"
        >
          <Col>
            <TableHeaderCell
              active={activeCol === 'calcs'}
              highlighted={highlights.includes('calcs')}
              onToggleHighlght={() => onToggleHighlght('calcs')}
            >
              {meta.columns.calcs.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
                active={activeCol === 'hours'}
                highlighted={highlights.includes('hours')}
                onToggleHighlght={() => onToggleHighlght('hours')}
            >
              {meta.columns.hours.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
                active={activeCol === 'fact'}
                highlighted={highlights.includes('fact')}
                onToggleHighlght={() => onToggleHighlght('fact')}
            >
              {meta.columns.fact.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
                active={activeCol === 'postr'}
                highlighted={highlights.includes('postr')}
                onToggleHighlght={() => onToggleHighlght('postr')}
            >
              {meta.columns.postr.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
                active={activeCol === 'classes'}
                highlighted={highlights.includes('classes')}
                onToggleHighlght={() => onToggleHighlght('classes')}
            >
              {meta.columns.classes.label}
            </TableHeaderCell>
          </Col>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
