import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  csu: { label: 'Розпорядник', name: 'Распорядитель' },
  units: { label: 'Підрозділ', name: 'Подразделение' },
  branches: { label: 'Галузь', name: 'Отрасль' },
  kbp: { label: 'КБП', name: 'КлассификаторКБП' },
  period: { label: 'Період', name: 'Период' },

};

const definition = {
  label: 'Звіт по показникам на початок навчального року',
  name: 'indicatorsStartYear',
  columns,
  frontend: 'rep/indicatorsStartYear',
  backendName: 'ОтчетПоПоказателямНаНачалоГода',
  resultColumns: baseResultColumns,
};

export default definition;
