import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { branches as md } from '../../../../constants/meta/catalogs/branches';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const Branches = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.KoefPay.label}
            value={data[md.columns.KoefPay.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KoefPay.name]: value,
            })}
            readOnly={readOnly}
            precision={4}
          />
        </Col>
      </Row>
      <Row>
        <Col>
            <EditorControls.CheckboxInput
                controlId={`NoVisible-${data.id}`}
                label={md.columns.NoVisible.label}
                value={data[md.columns.NoVisible.name]}
                onChange={(e, value) => actions.onChange({
                    [md.columns.NoVisible.name]: value,
                })}
                readOnly={readOnly}
            />
        </Col>
      </Row>
    </Container>
  );
};

Branches.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Branches;
