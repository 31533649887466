import React, {
  memo, useMemo,useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput, StringInput, SelectorInput, CheckboxInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/catalogs/CSU';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';
import externalReports from "../../../../../../constants/meta/catalogs/externalReports";


const tablename = 'extRep';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, activeCol, readOnly, highlights, onChange, onSR,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onCalcChange = useCallback(
      async (e, v) => {
        await onRowChange(e, { [tableMD.columns.calc.name]: v });
        await onSR('CHANGE_CALC_IN_TABLE', { rowId: rowIndex });
      },
      [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol} noGutters>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col
            className="d-flex"
          >
            <Col>
              <TableRowCell column="extRep" highlighted={highlights.includes('extRep')}>
                <ItemPicker
                    value={row[tableMD.columns.extRep.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.extRep.name]: v })}
                    modelType="cat"
                    modelName="externalReports"
                    readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="incl" highlighted={highlights.includes('incl')}>
                <CheckboxInput
                    id={`incl-${row[tableMD.columns.incl.name]}`}
                    readOnly={readOnly}
                    value={row[tableMD.columns.incl.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.incl.name]: v })}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="repName" highlighted={highlights.includes('repName')}>
                <StringInput
                    value={row[tableMD.columns.repName.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.repName.name]: v })}
                    readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.incl.name]: PropTypes.bool,
    [tableMD.columns.repName.name]: PropTypes.string,
    [tableMD.columns.extRep.name]: referencePropType,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
