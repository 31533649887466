import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  csu: { label: 'Розпорядник', name: 'Распорядитель' },
  dDate: { label: 'станом на:', name: 'НаДату' },
  basis: { label: 'Підстава', name: 'ОснованиеПриказа' },
  csu2: { label: 'Установа', name: 'Установа' },
  branches: { label: 'Галузь', name: 'Отрасль' },
  kbp: { label: 'КБП', name: 'КБП' },
  fond: { label: 'Фонд', name: 'Фонд' },
  calc: { label: 'Вид розрахунку', name: 'ВидРасчета' },
};

const definition = {
  label: 'Звіт по видах нарахувань',
  name: 'repTypeCharges',
  columns,
  frontend: 'rep/repTypeCharges',
  backendName: 'ОтчетПоВидамНачислений',
  resultColumns: baseResultColumns,
};

export default definition;
