import React from 'react';

const NewIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.5 6H12.5V3" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5 14.5C11.5 12.8 12.8 11.5 14.5 11.5C14.9 11.5 15.2 11.6 15.5 11.7V5.5L12.5 2.5H4.5V16.5H12.3C11.8 16 11.5 15.3 11.5 14.5Z" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.5 17.5C16.1569 17.5 17.5 16.1569 17.5 14.5C17.5 12.8431 16.1569 11.5 14.5 11.5C12.8431 11.5 11.5 12.8431 11.5 14.5C11.5 16.1569 12.8431 17.5 14.5 17.5Z" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.5 13.5V15.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5 14.5H15.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default NewIcon;
