import React from 'react';
import PropTypes from 'prop-types';
import {
    Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';
import { post as md } from '../../../../constants/meta/catalogs/post';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { TarifsTP, UsualsTP, SolaryesTP, AddsTP, SolaryesCloseTP } from './tabs';


const StateUnits = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange || data[md.columns.formReadOnly.name];

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
              label={md.columns.kpCode.label}
              value={data[md.columns.kpCode.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.kpCode.name]: value,
              })}
              maxLength={10}
              readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
            <Col>
                <EditorControls.CheckboxInput
                    controlId={`postNoRounds-${data.id}`}
                    label={md.columns.postNoRounds.label}
                    value={data[md.columns.postNoRounds.name]}
                    onChange={async (e, value) => {
                        await actions.onChange({
                            [md.columns.postNoRounds.name]: value,
                        });
                        actions.onSR('POSTNOROUNDS_ON_CHANGE');
                    }}
                    readOnly={readOnly}
                />
            </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
              label={md.columns.category.label}
              value={data[md.columns.category.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.category.name]: value,
              })}
              modelType="cat"
              modelName="category"
              readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
              label={md.columns.branches.label}
              value={data[md.columns.branches.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.branches.name]: value,
              })}
              modelType="cat"
              modelName="branches"
              readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
              label={md.columns.postClassifier.label}
              value={data[md.columns.postClassifier.name]}
              onChange={async (e, value) => {
                  await actions.onChange({
                      [md.columns.postNoRounds.name]: value,
                  });
                  actions.onSR('POST_ON_CHANGE');
              }}
              readOnly={readOnly}
              modelType="cat"
              modelName="postClassifier"
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="adds">
            <Tab title='Доступні та недоступні види нарахувань' eventKey="adds">
                <Container fluid>
                  <Row>
                    <Col>
                        <EditorControls.StringInput
                            label={md.columns.sort.label}
                            value={data[md.columns.sort.name]}
                            onChange={(e, value) => actions.onChange({
                                [md.columns.sort.name]: value,
                            })}
                            maxLength={5}
                            readOnly={readOnly}
                        />
                    </Col>
                  </Row>
                  <Row>
                      <Col>
                          <EditorControls.CheckboxInput
                              controlId={`flLimit-${data.id}`}
                              label={md.columns.flLimit.label}
                              value={data[md.columns.flLimit.name]}
                              onChange={async (e, value) => {
                                  await actions.onChange({
                                      [md.columns.flLimit.name]: value,
                                  });
                                  actions.onSR('FLIMIT_ON_CHANGE');
                              }}
                              readOnly={readOnly}
                          />
                      </Col>
                      <Col>
                            <EditorControls.CheckboxInput
                                controlId={`flNoHours-${data.id}`}
                                label={md.columns.flNoHours.label}
                                value={data[md.columns.flNoHours.name]}
                                onChange={(e, value) => actions.onChange({
                                    [md.columns.flNoHours.name]: value,
                                })}
                                readOnly={readOnly}
                            />
                      </Col>
                  </Row>
                  <Row>
                        <Col>
                            <EditorControls.CheckboxInput
                                controlId={`flUseble-${data.id}`}
                                label={md.columns.flUseble.label}
                                value={data[md.columns.flUseble.name]}
                                onChange={async (e, value) => {
                                    await actions.onChange({
                                        [md.columns.flUseble.name]: value,
                                    });
                                    actions.onSR('FUSABLE_ON_CHANGE');
                                }}
                                readOnly={readOnly}
                            />
                        </Col>
                        <Col>
                            <EditorControls.CheckboxInput
                                controlId={`flUvisible-${data.id}`}
                                label={md.columns.flUvisible.label}
                                value={data[md.columns.flUvisible.name]}
                                onChange={(e, value) => actions.onChange({
                                    [md.columns.flUvisible.name]: value,
                                })}
                                readOnly={readOnly}
                            />
                        </Col>
                  </Row>
                  <Row>
                        <Col>
                            <EditorControls.CheckboxInput
                                controlId={`limmitedCounts-${data.id}`}
                                label={md.columns.limmitedCounts.label}
                                value={data[md.columns.limmitedCounts.name]}
                                onChange={async (e, value) => {
                                    await actions.onChange({
                                        [md.columns.limmitedCounts.name]: value,
                                    });
                                    actions.onSR('LIMITEDCOUNTS_ON_CHANGE');
                                }}
                                readOnly={readOnly}
                            />
                        </Col>
                        <Col>
                            <EditorControls.CheckboxInput
                                controlId={`flNumber-${data.id}`}
                                label={md.columns.flNumber.label}
                                value={data[md.columns.flNumber.name]}
                                onChange={(e, value) => actions.onChange({
                                    [md.columns.flNumber.name]: value,
                                })}
                                readOnly={readOnly}
                            />
                        </Col>
                  </Row>
                  <Row>
                      <Col>
                            <EditorControls.CheckboxInput
                                controlId={`flFixed-${data.id}`}
                                label={md.columns.flFixed.label}
                                value={data[md.columns.flFixed.name]}
                                onChange={async (e, value) => {
                                    await actions.onChange({
                                        [md.columns.flFixed.name]: value,
                                    });
                                    actions.onSR('FLFIXED_ON_CHANGE');
                                }}
                                readOnly={readOnly}
                            />
                      </Col>
                      {(data[md.columns.grSolaryesVisible.name]) && (
                      <Col>
                          <EditorControls.NumberInput
                              label={md.columns.minimal.label}
                              value={data[md.columns.minimal.name]}
                              onChange={(e, value) => actions.onChange({
                                  [md.columns.minimal.name]: value,
                              })}
                              readOnly={readOnly}
                              precision={2}
                          />
                      </Col>
                      )}
                      {(data[md.columns.grSolaryesVisible.name]) && (
                      <Col>
                          <EditorControls.NumberInput
                              label={md.columns.maximal.label}
                              value={data[md.columns.maximal.name]}
                              onChange={(e, value) => actions.onChange({
                                  [md.columns.maximal.name]: value,
                              })}
                              readOnly={readOnly}
                              precision={2}
                          />
                      </Col>
                      )}
                  </Row>
                </Container>
              {(data[md.columns.solaryesVisible.name]) && (
                <SolaryesTP data={data} actions={actions} readOnly={readOnly} />
              )}
              <Row>
                {(data[md.columns.addsVisible.name]) && (
                 <Col>
                  <span>Доступні види нарахувань</span>
                  <AddsTP data={data} actions={actions} readOnly={readOnly} />
                 </Col>
                )}
                {(data[md.columns.solaryesCloseVisible.name]) && (
                 <Col>
                  <span>Недоступні види нарахувань</span>
                  <SolaryesCloseTP data={data} actions={actions} readOnly={readOnly} />
                 </Col>
                )}
              </Row>
            </Tab>
            {(data[md.columns.tarifsVisible.name]) && (
              <Tab title={md.tables.tarifs.label} eventKey="tarifs">
                  <TarifsTP data={data} actions={actions} readOnly={readOnly} />
              </Tab>
            )}
            <Tab title={md.tables.usuals.label} eventKey="usuals">
                <UsualsTP data={data} actions={actions} readOnly={readOnly} />
            </Tab>
      </Tabs>

    </Container>
  );
};

StateUnits.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default StateUnits;
