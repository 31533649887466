import React, {
  useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

const DDMenu = styled(Dropdown.Menu)`
  //top: 10px;
  overflow: auto;
  max-height: 200px;
  z-index: 5000;
  //border: 1px solid #ced4da;
  //margin-top: 3px;
`;

export const DataList = ({
  data, onSelect, activeItem,
}) => {
  const ref = useRef();
  const oldActiveItem = useRef(null);
  useEffect(
    () => {
      if (ref.current && activeItem !== null) {
        const el = ref.current.querySelector('.active');
        const t1 = el.getBoundingClientRect().top;
        const b1 = el.getBoundingClientRect().bottom;
        const t0 = ref.current.getBoundingClientRect().top;
        const h0 = ref.current.getBoundingClientRect().height;
        const s0 = ref.current.scrollTop;
        if (oldActiveItem.current === null || oldActiveItem.current < activeItem) {
          // привязываемся к нижней точке
          if ((b1 - t0 + s0) > (h0 + s0)) {
            ref.current.scrollTo({ top: b1 - t0 - h0 + s0, behavior: 'smooth' });
          }
        } else {
          // привязываемся к верхней точке
          // eslint-disable-next-line no-lonely-if
          if ((t1 - t0) < 0) {
            ref.current.scrollTo({ top: t1 - t0 + s0, behavior: 'smooth' });
          }
        }
        oldActiveItem.current = activeItem;
      }
    },
    [activeItem],
  );

  return (
    <DDMenu align="right" ref={ref}>
      {data.map((row, idx) => (
        <Dropdown.Item
          key={row.id.id}
          onClick={(e) => onSelect(e, row.id)}
          active={activeItem === idx}
        >
          {row.repr}
        </Dropdown.Item>
      ))}
    </DDMenu>
  );
};

DataList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.shape({
      id: PropTypes.string.isRequired,
      repr: PropTypes.string,
    }).isRequired,
    repr: PropTypes.string.isRequired,
  })),
  onSelect: PropTypes.func.isRequired,
  activeItem: PropTypes.number,
};

DataList.defaultProps = {
  data: [],
  activeItem: null,
};

export default DataList;
