import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  NetworkCode: { label: 'Код Мережа', name: 'КодМережа', visible: true },
  code: { label: 'Код', name: 'Код', visible: false },
  ControlOfReTransferTheApplications: {
    label: 'Перепроведення заявок',
    name: 'КонтрольПереПроведенияЗаявок',
    visible: true,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'НаименованиеПолное',
    visible: true,
  },
  FullName2: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: true,
  },
  Sort: { label: 'Код для сортування', name: 'Сорт', visible: true },
  AccountToTheTable: {
    label:
        'Заповнювати рахунки з табличної частини заявки в табличну частину розпорядження (для ФУ)',
    name: 'СчетаВТаблицу',
    visible: true,
  },
  ThisIsMainSteward: {
    label: 'Головний розпорядник бюджетних коштів',
    name: 'ЭтоГлавныйРаспорядитель',
    visible: true,
  },
  KVKCode: {
    label: 'КВК',
    name: 'КВККод',
    visible: true,
  },
  EDRPOU: { label: 'ЄДРПОУ', name: 'ЄДРПОУ', visible: true },
  grPayment: { label: 'Група отлати праці', name: 'ГруппаОтлатыТруда', visible: true },
  sorting: { label: 'Сортування', name: 'Сортировка', visible: false },
  Classifier: { label: 'КВК', name: 'Классификатор', visible: false },
  DateCl: { label: 'станом на:', name: 'ДатаКл', visible: false },
  csuLvl: { label: 'Рівень розпорядника', name: 'УровеньРаспорядителя', visible: false },
  Bank: { label: 'Банк', name: 'Банк', visible: true },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  flGrant: { label: 'Дозволити не враховувати кількість ставок', name: 'флРазрешитьНеучитыватьКолТариф', visible: false },
  mainCsu: { label: 'Головний розпорядник коштів', name: 'ГлавныйРаспорядитель', visible: false },
  Sorting: { label: 'Сортування', name: 'Сортировка', visible: true },
  parentName: { label: 'Розп. вищ. рівня', name: 'РодительНаименование', visible: true },
  pointDel: { label: 'Позначка вилучення', name: 'ПометкаУдаления', visible: true },
  groupCSU: { label: 'Групування розпорядників', name: 'ГруппаРаспорядителя', visible: true },
  forced: { label: 'Напередвизначений', name: 'Предопределенный', visible: true },
  csuLevel: { label: 'Рівень розпорядника', name: 'УровеньРаспорядителя', visible: true },
  link: { label: 'Посилання', name: 'Ссылка', visible: true },
  basket: { label: 'Облік за кошиками', name: 'УчетПоКорзинам', visible: true },
  csuInHead: { label: 'Розпорядник у шапці', name: 'флРаспорядительВШапке', visible: true },
};

const tables = {
  extRep: {
    label: 'Доступні тарифікаційні розряди',
    name: 'тчВнешниеОтчеты',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      extRep: { label: 'Звіт', name: 'внОтчет' },
      incl: { label: 'Увімкнено', name: 'Включен' },
      repName: { label: 'Найменування звіту', name: 'ИмяОтчета' },
    },
  },
};

const footer = { ...columns };

export const csu = {
  label: 'Розпорядники бюджетних коштів',
  name: 'csu',
  columns,
  tables,
  footer,
  backendName: 'Распорядители',
  listColumns: [
    ...new Set([
      'KVKCode',
      ...reqListerColumns,
      'Sorting',
      'parentName',
      'pointDel',
      'Bank',
      'groupCSU',
      'forced',
      'NetworkCode',
      'FullName2',
      'csuLevel',
      'grPayment',
      'link',
      'basket',
      'EDRPOU',
    ]),
  ],
  defaultOrder,
  frontend: 'cat/CSU',
  hierarchy: true,
  hierarchyType: hierarchyTypes.onlyItems,
  useFoldersLazyLoad: true,
};

export default csu;
