import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  csu: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОрган' },
  dDate: { label: 'станом на:', name: 'НаДату' },
  fond: { label: 'Фонд', name: 'Фонд' },
  kbp: { label: 'КБП', name: 'КБП' },
  flState: { label: 'обрахунок кількості ставок', name: 'флЗдарв' },
};

const definition = {
  label: 'Наявність даних за штатними одиницями',
  name: 'stateData',
  columns,
  frontend: 'rep/stateData',
  backendName: 'НаличиеДанныхПоШтатЕдWeb',
  resultColumns: baseResultColumns,
};

export default definition;
