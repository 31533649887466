import base from './base';

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: false },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  isApproved: { label: 'Тільки читання', name: 'ЗаблокироватьИзменения', visible: true },
  typeAcc: { label: 'Вид Нарахування', name: 'ВидРасчета', visible: false },
  dateCh: { label: 'Дата ост.змін', name: 'ДатаПоследнегоИзм', visible: false },
  post: { label: 'Посада', name: 'Должность', visible: true },
  category: { label: 'Категорія', name: 'Категория', visible: true },
  stamp: { label: 'Квитанція', name: 'Квитанция', visible: false },
  numbr: { label: 'Кільк. посад', name: 'Кво', visible: false },
  kfk: { label: 'КФК', name: 'КлассификаторКФК', visible: false },
  decrKoef: { label: 'Понижаючий коефіцієнт', name: 'КоэффициентПонижающий', visible: false },
  timeKoef: { label: 'Коефіцієнт погодинної оплати', name: 'КоэффициентПочасовойОплаты', visible: false },
  tarifKoef: { label: 'Коефіцієнт тарифного розряду', name: 'КоэффициентТарифногоРазряда', visible: false },
  minZp: { label: 'Мінімальна зарплата', name: 'МинимальнаяЗп', visible: false },
  reasons: { label: 'Підстава', name: 'ОснованиеПриказа', visible: true },
  reasonsY: { label: 'Підстави на підвищення з.п.', name: 'ОснованиеПриказаГод', visible: false },
  reasonsDoc: { label: 'Підстава для змін', name: 'ОснованияДок', visible: false },
  workH: { label: 'Години', name: 'ОтработанныеЧасы', visible: false },
  branches: { label: 'Галузь', name: 'Отрасль', visible: false },
  units: { label: 'Підрозділ', name: 'Подразделение', visible: true },
  sizes: { label: 'Сума', name: 'Размер', visible: false },
  tarif: { label: 'Тарифний розряд', name: 'ТарифныйРазряд', visible: true },
  tarif1: { label: 'Тарифний розряд (не використовується)', name: 'ТарифныйРазряд1', visible: false },
  flUnused: {
    label: 'Не діюча', name: 'флНеДействующий', visible: true, type: 'bool',
  },
  flNoTabs: { label: 'Не враховувати кількість ставок', name: 'флНеУчитыватьКолСтавок', visible: false },
  flNoRound: { label: 'Розрахунок без округлення', name: 'флОкрБазСуммы', visible: false },
  flSolary: { label: 'Вид оплати', name: 'флОплатаПоОкладу', visible: false },
  flSolaryHour: { label: 'Погодинна оплата', name: 'флПочасовыйОклад', visible: false },
  flSolaryHourTrainer: { label: 'За нормативом', name: 'флРасчетДляТренеров', visible: false },
  flNuSolary: { label: 'Зняти нарахування', name: 'флСнятьНачисления', visible: false },
  flNuSolaryOne: { label: 'Фл учет ставок равно один', name: 'флУчетСтавокРавноОдин', visible: false },
  stateUnit: { label: 'Штатна одиниця', name: 'ШтатнаяЕдиница', visible: true },
  elementFond: { label: 'Элемент фонд', name: 'ЭлементФонд', visible: false },
  flNewTypes: {
    label: 'Не дод. нов. видів розр.', name: 'НеДобавлятьНовыхВидовРасчета', visible: true, type: 'bool',
  },
  kbp: { label: 'КБП', name: 'КлассификаторКБП', visible: false },
  blockCh: { label: 'ЗаблокироватьИзменения', name: 'ЗаблокироватьИзменения', visible: false },
  blockChL: { label: 'ЗаблокированоНадпись', name: 'ЗаблокированоНадпись', visible: false },
  mShowPedagog: { label: 'мРасписыватьПедагогическуюНагрузку', name: 'мРасписыватьПедагогическуюНагрузку', visible: false },
  SUComment: { label: 'КомментарийШЕ', name: 'КомментарийШЕ', visible: false },
  reasonsAvailable: { label: 'ЭлементыОснованиеПриказаДоступность', name: 'ЭлементыОснованиеПриказаДоступность', visible: false },
  flNoTabsVisible: { label: 'ЭлементыфлНеУчитыватьКолСтавокВидимость', name: 'ЭлементыфлНеУчитыватьКолСтавокВидимость', visible: false },
  addGroupAvilable: { label: 'ЭлементыНачисленияДобавитьгруппуДоступность', name: 'ЭлементыНачисленияДобавитьгруппуДоступность', visible: false },
  recountAvialable: { label: 'ЭлементыПересчитатьНачисленияЗаСтажДоступность', name: 'ЭлементыПересчитатьНачисленияЗаСтажДоступность', visible: false },
  tableAvialable: { label: 'Таблична частина доступність', name: 'ЭлементыГруппа12Доступность', visible: false },
  formReadOnly: { label: 'ЭтаФормаТолькоПросмотр', name: 'ЭтаФормаТолькоПросмотр', visible: false },
  tpMainBaseSumReadOnly: { label: 'ЭлементыОснованияПодчиненныеЭлементыОснованияРазмерТолькоПросмотр', name: 'ЭлементыОснованияПодчиненныеЭлементыОснованияРазмерТолькоПросмотр', visible: false },
  timeKoefVisible: { label: 'ЭлементыКоэффициентПочасовойОплатыВидимость', name: 'ЭлементыКоэффициентПочасовойОплатыВидимость', visible: false },
  flSolaryAvialable: { label: 'ЭлементыфлОплатаПоОкладуДоступность', name: 'ЭлементыфлОплатаПоОкладуДоступность', visible: false },
  gr10Visible: { label: 'ЭлементыГруппа10Видимость', name: 'ЭлементыГруппа10Видимость', visible: false },
  gr8Visible: { label: 'ЭлементыГруппа8Видимость', name: 'ЭлементыГруппа8Видимость', visible: false },
  numbrAvialable: { label: 'ЭлементыКвоДоступность', name: 'ЭлементыКвоДоступность', visible: false },
  workHVisible: { label: 'ЭлементыОтработанныеЧасыВидимость', name: 'ЭлементыОтработанныеЧасыВидимость', visible: false },
  workHtitle: { label: 'ЭлементыОтработанныеЧасыЗаголовок', name: 'ЭлементыОтработанныеЧасыЗаголовок', visible: false },
  pages14Visible: { label: 'ЭлементыСтр1_4Видимость', name: 'ЭлементыСтр1_4Видимость', visible: false },
  pages59Visible: { label: 'ЭлементыСтр5_9Видимость', name: 'ЭлементыСтр5_9Видимость', visible: false },
  pages1011Visible: { label: 'ЭлементыСтр10_11Видимость', name: 'ЭлементыСтр10_11Видимость', visible: false },
  isWeb: { label: 'ЭтоВэб', name: 'ЭтоВэб', visible: false },
  dateEnd: {
    label: 'Діє до', name: 'ДатаОкончания', visible: true, type: 'date',
  },
  fond: { label: 'Фонд', name: 'Фонд', visible: true },
  fizPerson: {
    label: 'Вказана Ф.О.', name: 'ВказанаФізичнаОсоба', visible: true, type: 'bool',
  },
  dateEndCh: {
    label: 'Дата ост. змін', name: 'ДатаПоследнегоИзм', visible: true, type: 'date',
  },
};

/**
 * @const
 */
const tables = {
  main: {
    name: 'Основания',
    label: 'Підстави',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      baseDoc: { label: 'Підстава', name: 'Основание' },
      minSalary: { label: 'Мінімальна зарплата', name: 'МинимальнаяЗп' },
      baseSum: { label: 'Базова сума', name: 'Размер' },
    },
  },
  general: {
    name: 'Начисления',
    label: 'Складові зарплати',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      calc: { label: 'Вид Нарахування', name: 'ВидРасчета' },
      value: { label: 'Значення нарах.', name: 'Размер' },
      method: { label: 'Спосіб нарах.', name: 'СпособРасчета' },
      kbpClassifier: { label: 'КБП', name: 'КлассификаторКБП' },
      elementFond: { label: 'Фонд', name: 'ЭлементФонд' },
    },
  },
  pedLoad: {
    name: 'ПедНагрузка',
    label: 'Педагогічне навантаження',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      calcs: { label: 'Педагогічне навантаження', name: 'ПедагогическаяНагрузка' },
      hours: { label: 'Нормативна кількість годин', name: 'НормативноеКоличествоЧасов' },
      fact: { label: 'Фактична кількість годин', name: 'ФактическоеКоличествоЧасов' },
      postr: { label: 'Кількість посад', name: 'КоличествоДолжностей' },
      classes: { label: 'Класи', name: 'Классы' },
    },
  },
  pedLoad14: {
    name: 'ПедНагрузка14',
    label: '1 - 4 класи',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      calcs: { label: 'Педагогічне навантаження', name: 'ПедагогическаяНагрузка' },
      hours: { label: 'Нормативна кількість годин', name: 'НормативноеКоличествоЧасов' },
      fact: { label: 'Фактична кількість годин', name: 'ФактическоеКоличествоЧасов' },
      postr: { label: 'Кількість посад', name: 'КоличествоДолжностей' },
      classes: { label: 'Класи', name: 'Классы' },
    },
  },
  pedLoad59: {
    name: 'ПедНагрузка59',
    label: '5 - 9 класи',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      calcs: { label: 'Педагогічне навантаження', name: 'ПедагогическаяНагрузка' },
      hours: { label: 'Нормативна кількість годин', name: 'НормативноеКоличествоЧасов' },
      fact: { label: 'Фактична кількість годин', name: 'ФактическоеКоличествоЧасов' },
      postr: { label: 'Кількість посад', name: 'КоличествоДолжностей' },
      classes: { label: 'Класи', name: 'Классы' },
    },
  },
  pedLoad1011: {
    name: 'ПедНагрузка1011',
    label: '10 - 11 класи',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      calcs: { label: 'Педагогічне навантаження', name: 'ПедагогическаяНагрузка' },
      hours: { label: 'Нормативна кількість годин', name: 'НормативноеКоличествоЧасов' },
      fact: { label: 'Фактична кількість годин', name: 'ФактическоеКоличествоЧасов' },
      postr: { label: 'Кількість посад', name: 'КоличествоДолжностей' },
      classes: { label: 'Класи', name: 'Классы' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Картка штатної одиниці',
  name: 'personCard',
  backendName: 'ПриказОНачислениях',
  columns,
  frontend: 'doc/personCard',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'stateUnit',
      'dateEnd',
      'fizPerson',
      'flUnused',
      'post',
      'units',
      'tarif',
      'category',
      'fond',
      'CSU',
      'reasons',
      'dateEndCh',
      'note',
      'flNewTypes',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
