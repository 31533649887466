import React, { useMemo, useRef, useState } from 'react';

import { HashRouter as Router } from 'react-router-dom';

import { AppContext } from '../../providers/authProvider';

import LoginManager from './login_mgr';
import WinmgrRoot from './winMgr';

const Root = () => {
  const tokens = useRef({
    accessToken: '',
    refreshToken: '',
  });

  const setTokens = ({
    accessToken,
    refreshToken,
  }) => {
    tokens.current.accessToken = accessToken;
    tokens.current.refreshToken = refreshToken;
  };

  const [sessionParams, setSessionParams] = useState({
    AP: null,
    sessionOptions: {},
  });
  const cxValue = useMemo(() => ({
    ...sessionParams,
    accessToken: tokens.current.accessToken,
    refreshToken: tokens.current.refreshToken,
    setSessionParams,
    setTokens,
  }), [sessionParams]);

  return (
    <AppContext.Provider value={cxValue}>
      <Router>
        <WinmgrRoot>
          <LoginManager />
        </WinmgrRoot>
      </Router>

    </AppContext.Provider>
  );
};

export default Root;
