import React from 'react';
import { Card, ButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import md from '../../../../constants/meta/documents/calcTarifList';
import CalcTarifList from './editor';
import APMenu from './menu';
import CalcTarifListTabs from './tables';
import { modelType, modelName } from '../def';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import EditorHeader from '../../../newEditor/header';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';

/**
 * Функция, которая возвращает readOnly документа
 * @param data - Данные объекта
 * @returns {boolean}
 */
function isReadOnly(data) {
  return !!data[md.columns.isApproved.name];
}

const CalcTarifListEditor = ({
  id,
  search,
  onClose,
  onSave,
}) => {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
    readOnlyGetter: isReadOnly,
  });

  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
        loading={loading}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
      >
        <ButtonGroup>
          <DefaultCPButton
            variant="info"
            label={data[md.columns.isApproved.name] ? 'Зняти затвердження' : 'Затвердити'}
            onClick={() => {
              actions.onSR('ON_APPROVE');
            }}
            disabled={!data[md.columns.canDeleteisApproved.name]}
          />
        </ButtonGroup>
      </APMenu>
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>

        <Card.Header>
          <CalcTarifList data={data} actions={actions} permissions={permissions} />
        </Card.Header>
        <Card.Body className="p-0">
          <CalcTarifListTabs data={data} actions={actions} permissions={permissions} />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
};

CalcTarifListEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

CalcTarifListEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export default CalcTarifListEditor;
