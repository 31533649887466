import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/documents/personCard';

import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { AppContext } from '../../../../providers/authProvider';

const solaryKinds = {
  m1: { label: 'за окладом', value: 1 },
  m2: { label: 'погодинна оплата', value: 2 },
};

const planingKindsOptions = Object.values(solaryKinds).map(({ value, label }) => (
  { value, display_name: label }
));

const PersonCardEditor = ({ data, actions, permissions }) => {
  const appContext = useContext(AppContext);
  const { sessionOptions } = appContext;
  const FI = data[md.columns.CSU.name];
  const FiOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const readOnly = !permissions.canChange || data[md.columns.formReadOnly.name];
  return (
    <EditorCollapse>
      {sessionOptions.is_admin && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <span>{data[md.columns.blockChL.name]}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.number.name]: value,
            })}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.stateUnit.label}
            value={data[md.columns.stateUnit.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.stateUnit.name]: value,
              });
              actions.onSR('STATEUNIT_ON_CHANGE');
            }}
            modelType="cat"
            modelName="stateUnits"
            readOnly={readOnly}
            filter={FiOwnerFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>{data[md.columns.SUComment.name]}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`flUnused-${data.id}`}
            label={md.columns.flUnused.label}
            value={data[md.columns.flUnused.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.flUnused.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`flNewTypes-${data.id}`}
            label={md.columns.flNewTypes.label}
            value={data[md.columns.flNewTypes.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.flNewTypes.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.category.label}
            value={data[md.columns.category.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.category.name]: value,
            })}
            modelType="cat"
            modelName="category"
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.units.label}
            value={data[md.columns.units.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.units.name]: value,
            })}
            modelType="cat"
            modelName="units"
            readOnly
            filter={FiOwnerFilter}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.post.label}
            value={data[md.columns.post.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.post.name]: value,
              });
              actions.onSR('POST_ON_CHANGE');
            }}
            modelType="cat"
            modelName="post"
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.numbr.label}
            value={data[md.columns.numbr.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.numbr.name]: value,
              });
              actions.onSR('NUMBR_ON_CHANGE');
            }}
            readOnly={!data[md.columns.numbrAvialable.name] || readOnly}
            precision={10}
          />
        </Col>
        {data[md.columns.flNoTabsVisible.name] && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`flNoTabs-${data.id}`}
              label={md.columns.flNoTabs.label}
              value={data[md.columns.flNoTabs.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.flNoTabs.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        )}
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.branches.label}
            value={data[md.columns.branches.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.branches.name]: value,
              });
              actions.onSR('BRANCHES_ON_CHANGE');
            }}
            modelType="cat"
            modelName="branches"
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.kbp.label}
            value={data[md.columns.kbp.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.kbp.name]: value,
              });
              actions.onSR('KBP_ON_CHANGE');
            }}
            modelType="cat"
            modelName="kbpClassifier"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.elementFond.label}
            value={data[md.columns.elementFond.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.elementFond.name]: value,
            })}
            modelType="cat"
            modelName="elementFond"
            readOnly={readOnly}
            filter={FiOwnerFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.RadioInput
            label={md.columns.flSolary.label}
            value={data[md.columns.flSolary.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.flSolary.name]: value,
              });
              actions.onSR('FLSOLARY_ON_CHANGE');
            }}
            readOnly={!data[md.columns.flSolaryAvialable.name] || readOnly}
            values={planingKindsOptions}
          />
        </Col>
      </Row>
      <Row>
        {data[md.columns.gr8Visible.name] && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`flSolaryHour-${data.id}`}
              label={md.columns.flSolaryHour.label}
              value={data[md.columns.flSolaryHour.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.flSolaryHour.name]: value,
                });
                actions.onSR('FLSOLARYHOUR_ON_CHANGE');
              }}
              readOnly={readOnly}
            />
          </Col>
        )}
        {data[md.columns.gr8Visible.name] && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`flSolaryHourTrainer-${data.id}`}
              label={md.columns.flSolaryHourTrainer.label}
              value={data[md.columns.flSolaryHourTrainer.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.flSolaryHourTrainer.name]: value,
                });
                actions.onSR('FLSOLARYHOURTRAINER_ON_CHANGE');
              }}
              readOnly={readOnly}
            />
          </Col>
        )}
        {(data[md.columns.timeKoefVisible.name] && data[md.columns.gr8Visible.name]) && (
          <Col>
            <EditorControls.NumberInput
              label={md.columns.timeKoef.label}
              value={data[md.columns.timeKoef.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.timeKoef.name]: value,
                });
                actions.onSR('TIMEKOEF_ON_CHANGE');
              }}
              readOnly={readOnly}
              precision={5}
            />
          </Col>
        )}
        {(data[md.columns.gr8Visible.name] && data[md.columns.workHVisible.name]) && (
          <Col>
            <EditorControls.NumberInput
              label={md.columns.workHtitle.label}
              value={data[md.columns.workH.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.workH.name]: value,
                });
                actions.onSR('WORKH_ON_CHANGE');
              }}
              readOnly={readOnly}
              precision={5}
            />
          </Col>
        )}
      </Row>
      <Row>
        {(data[md.columns.gr10Visible.name] || data[md.columns.gr8Visible.name]) && (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.typeAcc.label}
              value={data[md.columns.typeAcc.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.typeAcc.name]: value,
              })}
              modelType="calc"
              modelName="mainCalc"
              readOnly={readOnly}
            />
          </Col>
        )}
        {(data[md.columns.gr10Visible.name] || data[md.columns.gr8Visible.name]) && (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.tarif.label}
              value={data[md.columns.tarif.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.tarif.name]: value,
                });
                actions.onSR('TARIF_ON_CHANGE');
              }}
              modelType="cat"
              modelName="tarif"
              readOnly
            />
          </Col>
        )}
        {data[md.columns.gr8Visible.name] && (
          <Col>
            <EditorControls.NumberInput
              label={md.columns.sizes.label}
              value={data[md.columns.sizes.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.sizes.name]: value,
              })}
              readOnly
              precision={2}
            />
          </Col>
        )}
      </Row>
      <Row>
        {(data[md.columns.gr10Visible.name] || data[md.columns.gr8Visible.name]) && (
          <Col>
            <EditorControls.NumberInput
              label={md.columns.decrKoef.label}
              value={data[md.columns.decrKoef.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.decrKoef.name]: value,
                });
                actions.onSR('DECRKOEF_ON_CHANGE');
              }}
              readOnly={readOnly}
              precision={5}
            />
          </Col>
        )}
      </Row>
    </EditorCollapse>
  );
};

PersonCardEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PersonCardEditor;
