import React, {
  memo, useMemo,useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  SelectorInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/catalogs/signatureVariants';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';
import enums from '../../../../../../constants/meta/enums';
import responsiblePerson from "../../../../../../constants/meta/catalogs/responsiblePerson";

const tablename = 'variantOfSignature';

const tableMD = md.tables[tablename];
const sValues = Object.values(enums.SignatureTypes).map((st) => ({ value: st.name, display_name: st.label }));

const TPRow = ({
  row, active, rowIndex, activeCol, readOnly, highlights, onChange,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
      <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
        <Col className="border-right">
          <TableRowCell column="Official" highlighted={highlights.includes('Official')}>
            <ItemPicker
                value={row[tableMD.columns.Official.name]}
                modelType="cat"
                modelName="responsiblePerson"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.Official.name]: v })}
                readOnly={readOnly}
            />
          </TableRowCell>
        </Col>
        <Col>
          <TableRowCell column="SignatureType" highlighted={highlights.includes('SignatureType')}>
            <SelectorInput
                value={row[tableMD.columns.SignatureType.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.SignatureType.name]: v })}
                readOnly={readOnly}
                values={sValues}
            />
          </TableRowCell>
        </Col>
      </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.SignatureType.name]: referencePropType,
    [tableMD.columns.Official.name]: referencePropType,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,

  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
