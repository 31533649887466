import React from 'react';

const CancelIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {/* <path d="M20 0H0V20H20V0Z" fill="none" /> */}
    <path d="M16.5 15.5H7.1C6.8 15.5 6.4 15.3 6.2 15.1L2.5 10L6.2 4.9C6.4 4.6 6.7 4.5 7.1 4.5H16.5C17.1 4.5 17.5 4.9 17.5 5.3V14.6C17.5 15.1 17.1 15.5 16.5 15.5Z" stroke="#4281C9" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.5 7.5L13.5 12.5" stroke="#4281C9" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5 7.5L8.5 12.5" stroke="#4281C9" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CancelIcon;
