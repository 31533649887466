import React from 'react';
import PropTypes from 'prop-types';

const Plus = ({ color }) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 5L8 5" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 2L5 8" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Plus.propTypes = {
  color: PropTypes.string,
};

Plus.defaultProps = {
  color: '#4281C9',
};

export default Plus;
