import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BigDivLoadStyled } from '../../containers/field/selectorField/item/styles';

const Conatiner = styled.div`
  position: relative;
`;

const WrapperContainer = styled.div`
  ${({ isLoading }) => isLoading ? 'filter: blur(3px) saturate(25%);' : ''}
`;

const LoaderConatiner = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const WrappedLoader = forwardRef(({
  children,
  isLoading,
}, ref) => (
  <Conatiner ref={ref}>
    <WrapperContainer isLoading={isLoading}>
      {children}
    </WrapperContainer>
    {isLoading && (
      <LoaderConatiner>
        <BigDivLoadStyled />
      </LoaderConatiner>
    )}
  </Conatiner>
));

WrappedLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isLoading: PropTypes.bool,
};

WrappedLoader.defaultProps = {
  isLoading: false,
};
export default WrappedLoader;
