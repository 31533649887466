import { directions } from '../common';

/**
 * @const
 */
const columns = {
  id: { label: 'id', name: 'id', visible: false },
  repr: { label: 'Відображення', name: 'repr', visible: false },
  number: { label: 'Номер', name: 'Номер', visible: true },
  // creationDate: {
  //   label: 'Дата создания',
  //   name: 'CreationDate',
  //   visible: false,
  // },
  date: {
    label: 'Дата документу', name: 'Дата', visible: true, type: 'date',
  },
  isDeleted: { label: 'Видалений', name: 'ПометкаУдаления', visible: false },
  isProcessed: { label: 'Проведений', name: 'Проведен', visible: false },
  orderNo: { label: 'Порядковий номер', name: '_OrderNo', visible: false },

  attachedFiles: { label: 'Додані фали', name: 'ТаблицаПрикрепленныхФайлов', visible: false },
};

/**
 * @const
 */
const base = {
  columns,
  listColumns: [
    'id',
    'repr',
    'number',
    'date',
    'isDeleted',
    'isProcessed',
    'orderNo',
  ],
  defaultOrder: [
    {
      column: columns.date.name,
      direction: directions.ascending,
    },
  ],
};

export default base;
