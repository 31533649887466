import React from 'react';

const InfoIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#4281C9" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M11.5 13.7C10.6 14.7 9.49995 15.3 8.89995 15.3C8.49995 15.3 8.19995 15 8.49995 13.8L9.39995 9.79995C9.49995 9.39995 9.49995 9.29995 9.39995 9.29995C9.29995 9.29995 8.79995 9.59995 8.39995 9.89995L8.19995 9.39995C9.19995 8.39995 10.3 7.79995 10.8 7.79995C11.3 7.79995 11.3 8.39995 11.1 9.29995L10.1 13.3C9.99995 13.7 9.99995 13.9 10.1 13.9C10.2 13.9 10.7 13.6 11.1 13.2L11.5 13.7ZM11 4.69995C11.6 4.69995 11.8 5.09995 11.8 5.59995C11.8 6.19995 11.4 6.79995 10.7 6.79995C10.2 6.79995 9.89995 6.39995 9.89995 5.89995C9.89995 5.29995 10.3 4.69995 11 4.69995Z" fill="#4281C9" />
  </svg>
);

export default InfoIcon;
