import base from './base';

const columns = {
  ...base.columns,
  Author: { label: 'Автор', name: 'Автор', visible: true },
  AuthorCh: { label: 'Автор змін', name: 'АвторИзм', visible: true },
  Tags: { label: 'Опис', name: 'АвтПримечание', visible: true },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  PlaningMode: { label: 'Вид плану', name: 'ВидПлана', visible: false },
  InputRemainder: { label: 'Вхідний залишок на рахунку', name: 'ВходОстатокСчета', visible: false },
  Listed: { label: 'Перераховано', name: 'ИтогИтСумма', visible: false },
  OutputRemainder: { label: 'Вихідний залишок на рахунку', name: 'ВыходОстатокСчета', visible: false },
  DateTreas: {
    label: 'Дата проведення ДКУ', name: 'ДатаКазна', visible: true, type: 'date',
  },
  Financed: { label: 'Документ профінансовано', name: 'ДокументПрофинансирован', visible: false },
  Fond: {
    label: 'Фонд',
    name: 'Фонд',
    visible: false,
  },
  IsApproved: {
    label: 'Документ затверджено',
    name: 'ДокументУтвержден',
    visible: true,
  },
  ImportAllNotes: { label: 'Не зводити примітку', name: 'ИмпортВсехПримечаний', visible: false },
  FinReceived: { label: 'Профінансовано', name: 'КвитанцияФ', visible: false },
  Transferting: { label: 'Передача коштів до р-нів', name: 'ПередачаСредств', visible: false },
  Note: { label: 'Примітка', name: 'Примечание', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  RNo: { label: 'Номер', name: 'РегНомер', visible: true },
  CSUAccount: { label: 'Рахунок розп./отр. (на:)', name: 'РСРаспорядителя', visible: false },
  FIAccount: { label: 'Рахунок розп.вищ.р. (з:)', name: 'РСФинансовыйОрган', visible: true },
  Sum: {
    label: 'Сума документа', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  FinancingReturn: { label: 'Повернення фінансування', name: 'флВозвратФинансирования', visible: false },
  Loan: { label: 'За рахунок позики', name: 'флЗаРахПозики', visible: false },
  AdditionalColumns: { label: 'Показати дод.колонки', name: 'флПоказатьОстИФинОбязат', visible: false },
  FinReceivedTreas: { label: 'Профінансовано ДКСУ', name: 'флПрофинансированКазной', visible: false },
  IncludeCSUIntoHead: {
    label: 'Распорядитель в шапке',
    name: 'флРаспорядительВШапке',
    visible: false,
  },
  FondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  TaxObject: { label: 'Джерело фінансування', name: 'ЭлементДохода', visible: false },
  TaxObjectCode: { label: 'Код', name: 'КодИсточникПередачи', visible: false },
  IsFinancing: {
    label: 'Розпорядження',
    name: 'ЭтоРаспоряжение',
    visible: false,
  },
  A3142: {
    label: 'Рах.заг.фонду,з якого передаються кошти до спец.фонду '
      + '(Номер рахунку починається з 3142...)',
    name: 'Сч3142',
    visible: false,
  },
  A3261: {
    label: 'Номер аналітичного рахунку місцевого бюджету (3261...)',
    name: 'Сч3261',
    visible: false,
  },
  A3262: {
    label: 'Рахунок, на який буде перерах.кошти за опер. із фінансування (3262...)',
    name: 'Сч3262',
    visible: false,
  },
  A3142Code: {
    label: 'Код',
    name: 'Сч3142Код',
    visible: false,
  },
  A3261Code: {
    label: 'Код',
    name: 'Сч3261Код',
    visible: false,
  },
  A3262Code: {
    label: 'Код',
    name: 'Сч3262Код',
    visible: false,
  },
  ShowAccounts: { label: 'Відображення аналітичних рахунків у табл.', name: 'флСчета', visible: false },
  ToHold: { label: 'Провести без залишків', name: 'ПровестиЕслиНетДенегНаСчету', visible: false },
  TransfertingZFToSF: {
    label: 'Одночасно є передачею коштів із заг.ф.до спец.ф.',
    name: 'ПередачаИзЗф_Сф',
    visible: false,
  },
  NumberOfMounthReturn: { label: 'Місяць повернення', name: 'НомМесяцаВозврата', visible: false },
  Code: { label: '№ р/р.', name: 'НомерСчетаФО', visible: true },
  DateFill: { label: 'Дата Заполнения', name: 'ДатаЗаполненияТч.Код', visible: false },
  AddApplication: { label: 'Доповнити список підлеглими документами', name: 'ДописатьЗаявку', visible: false },
  IsFinancingVisible: { label: 'ЭтоРаспоряжениеВидимость', name: 'ЭтоРаспоряжениеВидимость' },
  TransfertingZFToSFVisible: { label: 'ВидимостьПередачаСредств', name: 'ВидимостьПередачаСредств' },
  CalcBalanceOfAccounts: { label: 'флУчетДвиженийПоКазнСчетах', name: 'флУчетДвиженийПоКазнСчетах' },
  AccByClients: { label: 'УчетПоКонтрагентам', name: 'УчетПоКонтрагентам' },
  ClientInDoc: { label: 'КонтрагентВЗаявке', name: 'КонтрагентВЗаявке' },
  ComposeSettings: { label: 'Складне налаштування для повернення фінансування', name: 'флСложнаяНастройка' },
  activeBR: { label: 'флБюджетРазвитияВключен', name: 'флБюджетРазвитияВключен' },
  activeObjectsBuilding: { label: 'флОбъектыСтроительстваВключены', name: 'флОбъектыСтроительстваВключены' },
  isSeveralAccount: { label: 'Ознака наявності декількох рахунків', name: 'ЕстьНесколькоСчетов' },
  AccTypeTable: { label: 'ТипСчетаТЧ', name: 'ТипСчетаТЧ' },
  useFOAccounts: { label: 'КотловыеДляРаспорядителей', name: 'КотловыеДляРаспорядителей' },
  elementKFB: { label: 'Елемент КФБ', name: 'ЭлементКФБ' },
  elementKFBcred: { label: 'Елемент КФБ(кред)', name: 'ЭлКФБ_кред' },
  codeKFBcred: { label: 'КФБ(кред)', name: 'КодКФБ_кред' },
  codeKFB: { label: 'КФБ(борг)', name: 'КодКФБ' },
};

const tables = {
  general: {
    name: 'Основной',
    label: 'Основне',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      CSUAccount: { label: 'Рахунок розп./отр. (на:)', name: 'РСРаспорядителя', visible: false },
      CSUAccountNo: { label: 'Ном.рахунку', name: 'НомерРСРаспорядителя', visible: false },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      elementKFB: { label: 'Елемент КФБ', name: 'ЭлементКФБ' },
      elementKFBcred: { label: 'Елемент КФБ(кред)', name: 'ЭлКФБ_кред' },
      codeKFB: { label: 'КФБ(борг)', name: 'КодКФБ' },
      codeKFBcred: { label: 'КФБ(кред)', name: 'КодКФБ_кред' },
      Sum: { label: 'Сума', name: 'Сумма' },
      KPKVCode: { label: 'КПКВ ', name: 'КодКПКВ' },
      KEKVCode: { label: 'КЕКВ', name: 'КодКЕКВ' },
      KVKCode: { label: 'КВК', name: 'КодКВК' },
      KFBCode: { label: 'Код КФБ', name: 'КодКФБ' },
      DocFinancingRequest: { label: 'Заявка', name: 'ДокументЗаявка' },
      SumPayment: { label: 'Сума оплата', name: 'СуммаОплата' },
      SumTotal: { label: 'Оплата + Перераховано', name: 'ИтСумма' },
      Balance: { label: 'Залишок  асигнувань', name: 'Ост' },
      NoteStr: { label: 'Вид платежу', name: 'ПримечаниеСпр' },
      Note: { label: 'Примітка', name: 'Примечание' },
      NoteDoc: { label: 'Шаблон примітки', name: 'ПримечанияДок' },
      FinObligation: { label: 'Фін. зобов\'язання', name: 'ФинОбязательства' },
      WriteOff: { label: 'Списати', name: 'флСписатьСоСчета' },
      DateTreas: { label: 'Дата', name: 'ДатаКазна' },
      NumberOfPayment: { label: 'Номер платіжного доручення', name: 'НомерПлатежногоПорученя' },
      FinnanceObligation: { label: 'Фінансове зобов\'язання', name: 'ФинансовоеОбязательство' },
      R3262x: { label: 'Рах: 3262х', name: 'Сч3261' },
      R3261x: { label: 'Рах: 3261х', name: 'Сч3262' },
      Contragent: { label: 'Постачальник', name: 'КонтрагентЗаявка' },
      BuildObgect: { label: 'Об\'єкт будівництва/ремонту', name: 'ОбъектСтроительства' },
      TypeWork: { label: 'Вид робіт', name: 'ВидРабот' },
      IsReturn: { label: 'Повернення', name: 'ЭтоВозврат' },
      MounthReturn: { label: 'Місяць повернення', name: 'МесяцВозврата' },
      AnalyticalAccount: { label: 'Аналітичний рахунок(надходжень)', name: 'РСАналитический' },
      AnalyticalAccLabel: { label: '№ рах.(надходжень)', name: 'БухСчет' },
      AnalyticalAccountCosts: { label: 'Аналітичний рахунок(витрат)', name: 'РСАналитический_витрат' },
      AnalyticalAccLabelCosts: { label: '№ рах.(витрат)', name: 'БухСчет_витрат' },
    },
  },
  descendantsDocuments: {
    name: 'ЗаполненоИзДок',
    label: 'Заповнено з док.',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      document: { label: 'Документ', name: 'Документ' },
      IsApproved: { label: 'Затверджений', name: 'ДокументУтвержден' },
      Author: { label: 'Автор', name: 'ДокументАвтор' },
      sum: { label: 'Сума', name: 'СуммаДокумента' },
      NumberDoc: { label: 'Номер', name: 'ДокументНомер' },
      DateDoc: { label: 'Дата', name: 'ДатаДокумента' },
      Note: { label: 'Опис', name: 'АвтПримечание' },
    },
  },
};

const definition = {
  label: 'Фінансування',
  name: 'financing',
  backendName: 'Финансирование',
  columns,
  frontend: 'doc/financing',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'RNo',
      'Sum',
      'FondObject',
      'Author',
      'AuthorCh',
      'Tags',
      'Note',
      'Code',
      'FIAccount',
      'DateTreas',
      'Budget',
      'FI',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
