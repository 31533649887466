import React from 'react';
import PropTypes from 'prop-types';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';

const SaveNExitButton = ({ disabled, onClick, label }) => (
  <DefaultCPButton
    onClick={onClick}
    label={label}
    disabled={disabled}
  />
);

SaveNExitButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

SaveNExitButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Зберегти та закрити',
};

export default SaveNExitButton;
