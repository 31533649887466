import React, {
  memo, useMemo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput, StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/calcNightAndHoliday';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';

const tablename = 'general';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, activeCol, readOnly, highlights, FI, onChange, onSR, numDayVisible, h50Visible,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onCalcChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.stateUnit.name]: v });
      await onSR('CHANGE_STATE_UNIT_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol} noGutters>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col
            className="d-flex"
          >
            <Col>
              <TableRowCell column="category" highlighted={highlights.includes('category')}>
                <ItemPicker
                  value={row[tableMD.columns.category.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.category.name]: v })}
                  modelType="cat"
                  modelName="category"
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="numPostUse" highlighted={highlights.includes('numPostUse')}>
                <NumberInput
                  value={row[tableMD.columns.numPostUse.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.numPostUse.name]: v })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="numPostBase" highlighted={highlights.includes('numPostBase')}>
                <NumberInput
                  value={row[tableMD.columns.numPostBase.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.numPostBase.name]: v })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="numStateUse" highlighted={highlights.includes('numStateUse')}>
                <NumberInput
                  value={row[tableMD.columns.numStateUse.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.numStateUse.name]: v })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="numStateBase" highlighted={highlights.includes('numStateBase')}>
                <NumberInput
                  value={row[tableMD.columns.numStateBase.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.numStateBase.name]: v })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            {(numDayVisible) && (
              <Col>
                <TableRowCell column="numDay" highlighted={highlights.includes('numDay')}>
                  <NumberInput
                    value={row[tableMD.columns.numDay.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.numDay.name]: v })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            )}
            <Col>
              <TableRowCell column="hTotal" highlighted={highlights.includes('hTotal')}>
                <NumberInput
                  value={row[tableMD.columns.hTotal.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.hTotal.name]: v })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            {(h50Visible) && (
              <Col>
                <TableRowCell column="h50" highlighted={highlights.includes('h50')}>
                  <NumberInput
                    value={row[tableMD.columns.h50.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.h50.name]: v })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            )}
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.category.name]: referencePropType,
    [tableMD.columns.h50.name]: PropTypes.number,
    [tableMD.columns.hTotal.name]: PropTypes.number,
    [tableMD.columns.numDay.name]: PropTypes.number,
    [tableMD.columns.numStateBase.name]: PropTypes.number,
    [tableMD.columns.numStateUse.name]: PropTypes.number,
    [tableMD.columns.numPostBase.name]: PropTypes.number,
    [tableMD.columns.numPostUse.name]: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  FI: referencePropType.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  numDayVisible: PropTypes.bool,
  h50Visible: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  numDayVisible: false,
  h50Visible: false,
};

export default memo(TPRow);
