import React, {
  memo, useMemo,useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/catalogs/post';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';

const tablename = 'solaryes';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, activeCol, readOnly, highlights, onChange,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol} noGutters>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col
            className="d-flex"
          >
            <Col>
              <TableRowCell column="minS" highlighted={highlights.includes('minS')}>
                <NumberInput
                    value={row[tableMD.columns.minS.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.minS.name]: v })}
                    precision={2}
                    readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="maxS" highlighted={highlights.includes('maxS')}>
                <NumberInput
                    value={row[tableMD.columns.maxS.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.maxS.name]: v })}
                    precision={2}
                    readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="basis" highlighted={highlights.includes('basis')}>
                <ItemPicker
                    value={row[tableMD.columns.basis.name]}
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.basis.name]: v })}
                    modelType="cat"
                    modelName="baseDoc"
                    readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.basis.name]: referencePropType,
    [tableMD.columns.maxS.name]: PropTypes.number,
    [tableMD.columns.minS.name]: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
