import React from 'react';

const PrintIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.5 7.5H5.5V2.5H11.5L14.5 5.5V7.5Z" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 14.5H3.5C2.9 14.5 2.5 14.1 2.5 13.5V8.5C2.5 7.9 2.9 7.5 3.5 7.5H16.5C17.1 7.5 17.5 7.9 17.5 8.5V13.5C17.5 14.1 17.1 14.5 16.5 14.5H14.5" stroke="#4281C9" strokeMiterlimit="10" />
    <path d="M4.5 11.5H15.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.5 9.5H6.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.5 11.5H5.5V17.5H14.5V11.5Z" stroke="#4281C9" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M7.5 15.5H12.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5 13.5H12.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.3 5.5H11.5V2.8" stroke="#4281C9" strokeMiterlimit="10" strokeLinejoin="round" />
  </svg>
);

export default PrintIcon;
