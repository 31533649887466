import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTableComponent } from './tableComponent/hook';
import ListerContext from './context';

const ListerFactory = ({
  modelType, modelName, viewType, callback = null,
}) => (TableLister) => {
  ListerContext.displayName = `${viewType}  ${modelType}  lister context of ${modelName}`;

  const Lister = ({
    filter, noHierarchy, value, onSelect, onChoice, overridePermissions,
    params,
  }) => {
    const options = useMemo(
      () => ({
        initialFilter: filter,
        initialValue: value,
        noHierarchy,
        onSelect,
        onChoice,
        params,
        // initialValue: value,
      }),
      [filter, noHierarchy, onChoice, onSelect, params, value],
    );
    const {
      visibleColumns, items, openedItems, selectedRows, onSetOrder, order, loading, err,
      showDeleted, searchString, period, maxItemLevel, localFilter,
      onReload,
      permissions,
      actions,
      columnSizes, onResizeColumn, onResetColumnSize,
      ctxMenu, onShowCtxMenu, onHideCtxMenu,
      tableRef, commandPanelRef, onRowFocusHandler, onTableScroll, areaSize, approveStatus,
      messages,
    } = useTableComponent(modelType, modelName, viewType, options);

    const customItems = callback ? callback(items) : items;

    const perms = useMemo(
      () => ({
        ...permissions,
        ...overridePermissions,
      }),
      [overridePermissions, permissions],
    );

    // При необходимости переместить одно ищ указанныъ нижу свойств в
    // context - удалять из свойств. Т.е. свойство должно быть или в
    // props или в context, но никак и там и там.
    const ctxValue = useMemo(
      () => ({
        permissions: perms,
        actions,
        showDeleted,
        searchString,
        period,
        selectedRows,
        items: customItems,
        localFilter,
        ctxMenu,
        onShowCtxMenu,
        onHideCtxMenu,
        modelType,
        modelName,
        viewType,
        approveStatus,
        messages,
      }),
      [actions, approveStatus, ctxMenu, customItems, localFilter, messages,
        onHideCtxMenu, onShowCtxMenu, period, perms, searchString, selectedRows,
        showDeleted],
    );

    return (
      <ListerContext.Provider value={ctxValue}>
        <TableLister
          ref={tableRef}
          commandPanelRef={commandPanelRef}
          visibleColumns={visibleColumns}
          openedItems={openedItems}
          onRowFocus={onRowFocusHandler}
          onSetOrder={onSetOrder}
          order={order}
          loading={loading}
          err={err}
          onReoload={onReload}
          onScroll={onTableScroll}
          areaSize={areaSize}
          columnSizes={columnSizes}
          onResizeColumn={onResizeColumn}
          onResetColumnSize={onResetColumnSize}
          maxItemLevel={maxItemLevel}
        />
      </ListerContext.Provider>
    );
  };
  Lister.propTypes = {
    filter: PropTypes.arrayOf(PropTypes.shape({
    })),
    overridePermissions: PropTypes.shape({}),
    params: PropTypes.arrayOf(PropTypes.shape({})),
    noHierarchy: PropTypes.bool,
    value: PropTypes.instanceOf({}),
    onSelect: PropTypes.func,
    onChoice: PropTypes.func,
  };
  Lister.defaultProps = {
    filter: [],
    params: [],
    overridePermissions: {},
    noHierarchy: false,
    onSelect: null,
    onChoice: null,
    value: null,
  };
  return Lister;
};

export default ListerFactory;
