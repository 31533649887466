import React from 'react';
import PropTypes from 'prop-types';
import Box from './box';
import { BoxMenuContainer } from '../instancesForBoxMenu/index';

const Boxes = ({ items }) => (
  <BoxMenuContainer>
    {items.map((item) => (
      <Box
        key={item.name}
        label={item.label}
        items={item.items}
        mainItems={item.mainItems}
        group={item.group}
      />
    ))}
  </BoxMenuContainer>
);

Boxes.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Boxes;
