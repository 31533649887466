import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import Loader from '../../components/styledWrappedLoader';
import {
  tryLogin,
  tryLogout,
} from '../../api/auth';
import Login from '../authorization/login';
import Layout from '../../components/layout';
import { proxyApi, dbOptions } from '../../constants/misc';
import { unregister } from '../../registerServiceWorker';
import { AppContext } from '../../providers/authProvider';

const TICKET_STR = 'ticket';

const LoginManager = () => {
  const [loading, setLoading] = useState(false);

  const {
    accessToken,
    sessionOptions,
    setSessionParams,
    setTokens,
  } = useContext(AppContext);

  const logedIn = !!accessToken;

  // Спрашивать "Закрыть сайт" только на prod
  if (process.env.NODE_ENV === 'production') {
    window.onbeforeunload = () => {
      unregister();
      localStorage.removeItem('logikaTicket');
      setTokens({
        accessToken: null,
        refreshToken: null,
      });
      setSessionParams({
        sessionOptions: null,
        AP: null,
      });
    };
  }

  const handleCreateNewSession = useCallback(
    /**
     * @desc Creating new session & write them into localStorage
     * @func
     * @param d {object}
     * @param d.session_params {object}
     * @param d.access_token {string}
     * @param d.refresh_token {string}
     * @param d.AP {string}
     * @param d.login {string}
     * @param d.passwd {string}
     */
    (d) => {
      setSessionParams({
        sessionOptions: d.session_params,
        AP: d.AP,
      });

      setTokens({
        accessToken: d.access_token,
        refreshToken: d.refresh_token,
      });

      if (process.env.NODE_ENV === 'development') {
        window.localStorage.setItem('refreshToken', d.refresh_token);
        window.localStorage.setItem('accessToken', d.access_token);
        window.localStorage.setItem('AP', d.AP);
        window.localStorage.setItem('sessionOptions', JSON.stringify(d.session_params));
      }
      setLoading(false);
    },
    [setSessionParams],
  );

  const restoreDevSession = useCallback(
    () => {
      const oldRefreshToken = window.localStorage.getItem('refreshToken');
      const oldAccessToken = window.localStorage.getItem('accessToken');
      const oldAP = window.localStorage.getItem('AP');
      let oldSessionOptions;
      try {
        oldSessionOptions = JSON.parse(window.localStorage.getItem('sessionOptions'));
      } catch (e) {
        oldSessionOptions = null;
      }
      if (oldRefreshToken && oldAccessToken && oldAP && oldSessionOptions) {
        setTokens({
          accessToken: oldAccessToken,
          refreshToken: oldRefreshToken,
        });

        setSessionParams({
          sessionOptions: oldSessionOptions,
          AP: oldAP,
        });
      }
    },
    [setSessionParams],
  );

  const checkTicket = useCallback(
    async (ticket) => {
      const str = decodeURIComponent(window.escape(atob(ticket)));
      const p = JSON.parse(str.substr(1));
      const cAP = dbOptions.filter((d) => d.value === p.db)
        .reduce((R, r) => r.AP, null);

      const r = await tryLogin({
        srv: p.db,
        login: p.user,
        pwd: p.passwd,
        backEnd: proxyApi[cAP],
      });
      if (r.ok) {
        const d = await r.json();
        handleCreateNewSession({
          ...d,
          AP: cAP,
          login: p.user,
          passwd: p.passwd,
        });
        return true;
      }
      return false;
    },
    [handleCreateNewSession],
  );

  useEffect(
    () => {
      const ticket = window.location.search.substr(1)
        .split('&')
        // eslint-disable-next-line no-confusing-arrow
        .reduce((R, r) => r.substr(0, TICKET_STR.length) === TICKET_STR
          ? decodeURIComponent(r.substr(TICKET_STR.length + 1))
          : R, null);

      if (ticket) {
        checkTicket(ticket);
      } else if (process.env.NODE_ENV === 'development') {
        restoreDevSession();
      }
    },
    [checkTicket, restoreDevSession],
  );

  const handleLogout = useCallback(
    async () => {
      setLoading(false);
      await tryLogout({
        backEnd: proxyApi[sessionOptions],
        accessToken,
      });

      setTokens({
        accessToken: null,
        refreshToken: null,
      });

      setSessionParams({
        sessionOptions: null,
        AP: '',
      });

      if (process.env.NODE_ENV === 'development') {
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('sessionOptions');
        window.localStorage.removeItem('minfinTokens');
        window.localStorage.removeItem('minfinUser');
      }

      window.location.replace('/');
    },
    [accessToken],
  );

  return (
    <Loader isLoading={loading} style={{ height: '100vh' }}>
      {logedIn ? (
        <Layout handleLogout={handleLogout} />
      ) : (
        <Login onLogin={handleCreateNewSession} />
      )}
    </Loader>
  );
};

export default LoginManager;
