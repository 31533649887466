import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/documents/indicatorsStartYear';

import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { AppContext } from '../../../../providers/authProvider';

const IndicatorsStartYearEditor = ({ data, actions, permissions }) => {
  const appContext = useContext(AppContext);
  const { sessionOptions } = appContext;
  const FI = data[md.columns.CSU.name];
  const FiOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const readOnly = !permissions.canChange || !data[md.columns.formAvailable.name];
  return (
    <EditorCollapse>
      {sessionOptions.is_admin && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.number.name]: value,
            })}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.units.label}
            value={data[md.columns.units.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.units.name]: value,
            })}
            modelType="cat"
            modelName="units"
            readOnly={readOnly}
            filter={FiOwnerFilter}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.branches.label}
            value={data[md.columns.branches.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.branches.name]: value,
            })}
            modelType="cat"
            modelName="branches"
            readOnly={readOnly}
            filter={FiOwnerFilter}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.kbp.label}
            value={data[md.columns.kbp.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.kbp.name]: value,
              });
              actions.onSR('KBP_ON_CHANGE');
            }}
            modelType="cat"
            modelName="kbpClassifier"
            readOnly={readOnly || data[md.columns.kbpReadOnly.name]}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.signVar.label}
            value={data[md.columns.signVar.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.signVar.name]: value,
            })}
            modelType="cat"
            modelName="signatureVariants"
            readOnly={readOnly}
            filter={FiOwnerFilter}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
};

IndicatorsStartYearEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default IndicatorsStartYearEditor;
