import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  sort: { label: '№ п/п', name: 'НомерПоПорядку', visible: true },
  typeSort: { label: 'Порядок сортування', name: 'ПорядокСортирвки', visible: true },
  owner: { label: 'Власник', name: 'Владелец', visible: false },

};

/**
 * @const
 */
export const firstIndicators = {
  label: 'Показники на початок навчального року',
  name: 'firstIndicators',
  backendName: 'ПоказателиНаНачалоУчебногоГода',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  frontend: 'cat/firstIndicators',

  // Remove duplicates
  listColumns: [...new Set([
    ...reqListerColumns,
    'owner',
  ])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  // foldersOnTop: true,
};

export default firstIndicators;
