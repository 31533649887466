import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';
import {
  BoxLink,
  BoxMenuHeader,
  BoxMenuLineHr,
  BoxMenuPlaceholder,
  ElementsListContainer,
} from '../instancesForBoxMenu/index';
import { MyContainerFormWithNColumns } from '../../Form/styledForm';
import {
  ArrowRightIcon,
  FolderOpenBlueIcon,
  FolderCloseBlueIcon,
  TriangleIcon as ArrowDown,
} from '../../../assets/icons';
import { AppContext } from '../../../providers/authProvider';

const ReversedArrow = styled.img`
  transform: rotate(180deg);
`;
const ButtonDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    cursor: pointer;
    bottom: 12px;
    right: 16px;
      &:hover{
        text-decoration: underline;
        color: #386EAB;
      };
`;
const ButtonName = styled.span`
    font-size: 12px;
    color: #386EAB;
    margin-right: 3px;
`;

const BoxContent = styled.div`
  min-height: 100px;
  max-height: ${(props) => (props.extended || props.showNav) && 'fit-content'}
`;

const GroupContainer = styled.div`
`;

const activeHeader = `
  border: 1px solid lightblue;
  border-radius: 4px 4px 0 0;
  background: #ecf2f9;
  border-bottom: none;
  font-weight: 600;
`;

const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 3px 5px;
  width: fit-content;
  ${({ active }) => active
    ? 'color: #4183c4;' : 'color: #4262a1'};
  ${({ active }) => active
  && `${activeHeader}`
};
`;

const SubMenu = styled(MyContainerFormWithNColumns)`
  grid-row-gap: 0;
  align-items: start;
  padding: 5px 10px;
  margin: 0;
  background: #4281c91a;
  border: 1px solid lightblue;
  border-radius: 0 4px 4px;
  ${({ active }) => !active
  && 'display: none;'};
`;

const ArrowIcon = styled(ArrowRightIcon)`
  ${({ active }) => active
  && 'transform: rotate(90deg);'};
`;
const FolderIm = styled.img`
  width: 20px;
  margin-right: 5px;
`;

const Box = ({
  items, label, group, mainItems,
}) => {
  const [extended, setExtended] = useState(false);
  const [activeMainItem, setActiveMainItem] = useState('first');
  const [showNav, setShowNav] = useState(true);

  const {
    sessionOptions,
  } = useContext(AppContext);

  const showMore = useCallback(() => setExtended((prev) => !prev), []);
  const activeItem = false;

  return (
    <BoxMenuPlaceholder
      showNav={group && showNav}
    >
      <BoxContent extended={extended} showNav={group && showNav}>
        <BoxMenuHeader>{label}</BoxMenuHeader>
        <BoxMenuLineHr />
        {group
          ? (
            <GroupContainer>
              {mainItems
                .map((mainItem) => (
                  <div key={mainItem.name} style={{ margin: '5px 0' }}>
                    <HeaderGroup
                      active={mainItem.name === activeMainItem}
                      onClick={() => {
                        if (mainItem.name === activeMainItem && showNav) {
                          setShowNav(false);
                          setActiveMainItem((prev) => !prev);
                        } else {
                          setShowNav(true);
                          setActiveMainItem(mainItem.name);
                        }
                      }}
                    >
                      {mainItem.name === activeMainItem
                        ? <FolderIm src={FolderOpenBlueIcon} alt="FolderOpenBlueIcon" />
                        : <FolderIm src={FolderCloseBlueIcon} alt="FolderCloseBlueIcon" />}
                      {mainItem.label || 'нэма'}
                      <ArrowIcon
                        active={mainItem.name === activeMainItem}
                      />
                    </HeaderGroup>
                    <SubMenu
                      columns={mainItem.num}
                      active={mainItem.name === activeMainItem}
                    >
                      {items
                        .filter((item) => item.parent === mainItem.name === !activeItem)
                        .map((item) => (
                          <div
                            key={item.def.name}
                            style={{ display: 'flex' }}
                          >
                            <BoxLink
                              line={item.line}
                              key={item.def.name}
                              to={item.def.frontend}
                            >
                              {item.def.label || 'нэма'}
                            </BoxLink>
                          </div>
                        ))}
                    </SubMenu>
                  </div>
                ))}
            </GroupContainer>
          )
          : (
            <ElementsListContainer extended={extended}>
              {items.filter((item) => (!item.showTrigger
              || item.showTrigger(sessionOptions)) && (item.primary))
                .map((item) => (
                  <div
                    key={item.def.name}
                    style={{ display: 'flex' }}
                  >
                    <BoxLink
                      line={item.line}
                      key={item.def.name}
                      to={item.def.frontend}
                    >
                      {item.def.label || 'нэма'}
                    </BoxLink>
                  </div>
                ))}
            </ElementsListContainer>
          )}
      </BoxContent>
      {!group
            && (
              <Accordion>
                <Accordion.Collapse eventKey="0">
                  <ElementsListContainer extended={extended}>
                    {items.filter((item) => (!item.showTrigger
                  || item.showTrigger(sessionOptions)) && (!item.primary))
                      .map((item) => (
                        <div
                          key={item.def.name}
                          style={{ display: 'flex' }}
                        >
                          <BoxLink
                            line={item.line}
                            key={item.def.name}
                            to={item.def.frontend}
                          >
                            {item.def.label || 'нэма'}
                          </BoxLink>
                        </div>
                      ))}
                  </ElementsListContainer>
                </Accordion.Collapse>
                <Accordion defaultActiveKey="0" as={ButtonDiv} variant="link" eventKey="0">
                  <ButtonName>{extended ? 'Меньше' : 'Бiльше'}</ButtonName>
                  {extended ? <ReversedArrow onClick={showMore} src={ArrowDown} alt="arrow" aria-hidden="true" />
                    : <img onClick={showMore} src={ArrowDown} alt="arrow" aria-hidden="true" />}
                </Accordion>
              </Accordion>
            )}
    </BoxMenuPlaceholder>
  );
};

Box.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  label: PropTypes.string.isRequired,
  group: PropTypes.bool,
  mainItems: PropTypes.arrayOf(PropTypes.shape({})),
};

Box.defaultProps = {
  group: false,
  mainItems: [],
};

export default Box;
