import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  csu: { label: 'Розпорядник', name: 'Распорядитель' },
  dDate: { label: 'станом на:', name: 'НаДату' },
  repForm: { label: 'Форма звіту', name: 'ФормаОтчета' },
  changesDet: { label: 'Деталізація змін', name: 'ДетализацияИзменений' },
  branches: { label: 'Галузь', name: 'Отрасль' },
  signatureVariants: { label: 'Варіант підпису', name: 'Подпись' },
  fond: { label: 'Фонд', name: 'КлассификаторФонд' },
  in1000: { label: 'У тис.грн.', name: 'фл1000' },
  isRound: { label: 'Заокруглити', name: 'флОкр' },
  year: { label: 'Рік', name: 'текГод' },
  fromFolder: { label: 'Заповнити з папки', name: 'ПапкаОснований' },
  basisDocs: { label: 'Підстава для змін', name: 'ОснованияДок' },
  chOn: { label: 'Зі змінами на ', name: 'СИзменениямиНа' },
  writeSt: { label: 'Кількість шт.од.на початок періоду', name: 'ВыводитьКоличествоНаНачалоПериода' },
  writeEnd: { label: 'Кількість шт.од.на кінець періоду', name: 'ВыводитьКоличествоНаКонецПериода' },
  writeMiddle: { label: 'Середня кількість шт.од.', name: 'ВыводитьСреднегодовоеКоличество' },
  period: { label: 'Период', name: 'Период' },
  orderText: { label: 'Текст наказу', name: 'ТекстПриказа' },
  colRepeat: { label: 'Повторювати при друку колонки', name: 'ПовторятьПриПечатиКолонки' },
  onlyZero: { label: 'Не виводити у звіт колонки без даних', name: 'ТолькоНеНулевыеКолонки' },
  onlyAppr: { label: 'Тількі по затвердженим тарифікаційним спискам', name: 'ТолькоПоУтвержденным' },
  kbp: { label: 'КБП', name: 'КБП' },
};

const tables = {
  tpBasis: {
    name: 'тчОснований',
    label: 'Підстави',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      baseDoc: { label: 'Підстава', name: 'Основание' },
      month: { label: 'Місяць', name: 'НомМес' },
    },
  },
};

const definition = {
  label: 'Звіт за рік_',
  name: 'applicateYearNew',
  columns,
  tables,
  frontend: 'rep/applicateYearNew',
  backendName: 'ПриложенияГодНовый',
  resultColumns: baseResultColumns,
};

export default definition;
