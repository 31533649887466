import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  ButtonGroup, Button,
  Card, Dropdown, Form, FormControl, InputGroup, Spinner, Alert,
} from 'react-bootstrap';

import { dbOptions, proxyApi } from '../../constants/misc';
import { tryLogin } from '../../api/auth';

import { UseIcon, PasswordIcon, SettingsIcon } from '../../assets/icons';
import {
  ImgInput,
} from './styles';

const Login = ({ onLogin }) => {
  const [srv, setSrv] = useState(() => {
    const c = localStorage.getItem('value');
    if (c) return JSON.parse(c);
    return null;
  });
  const [login, setLogin] = useState('');
  const [passwd, setPasswd] = useState('');
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);

  const handleSubmitForm = useCallback(async ({ pwd, lgn }) => {

    setLoading(true);
    const { AP } = dbOptions.filter((d) => d.value === srv)[0];

    const r = await tryLogin({
      login: lgn,
      pwd,
      srv,
      backEnd: proxyApi[AP],
    });
    setLoading(false);
    if (r.ok) {
      const d = await r.json();

      onLogin({
        ...d,
        AP,
        login,
        passwd,
      });
    } else {
      console.error('Error @ Login component');
      setErr(`${r.status} ${r.statusText}`);
    }
  }, []);

  const handleLoginChange = useCallback(
    (e) => {
      setLogin(e.target.value);
    },
    [],
  );

  const handlePasswordChange = useCallback(
    (e) => setPasswd(e.target.value),
    [],
  );

  const handleServerChange = useCallback(
    (value) => {
      setSrv(value);
      localStorage.setItem('value', JSON.stringify(value));
    },
    [],
  );

  const sendAuthRequest = useCallback(
    ({ pwd, lgn }) => {
      setLoading(true);
      setErr(null);
      handleSubmitForm({ pwd, lgn });
    },
    [handleSubmitForm],
  );

  const {
    srvName,
    srvSelected,
  } = useMemo(
    () => dbOptions.filter((vv) => (vv.value === srv))
      .reduce((R, v) => ({
        srvName: v.text,
        srvSelected: true,
      }), {
        srvName: 'Налаштування підключення',
        srvSelected: false,
      }),
    [srv],
  );
  const okDisabled = useMemo(
    () => !srvSelected || loading,
    [loading, srvSelected],
  );
  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !okDisabled) {
        e.preventDefault();
        e.stopPropagation();
        sendAuthRequest(e);
      }
    },
    [okDisabled, sendAuthRequest],
  );

  return (
    <div className="d-flex justify-content-center h-100 pt-5" onKeyDown={onKeyDown}>
      {/* <Container className="w-25 sm-w-100"> */}
      <Card className="mt-5" style={{ minWidth: '320px' }}>
        <Card.Header>
          <Card.Title>
            Вхід у систему
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup>
            <InputGroup.Text className="bg-white">
              <ImgInput src={UseIcon} alt="UseIcon" />
            </InputGroup.Text>
            <FormControl
              name="username"
              value={login}
              required
              onChange={handleLoginChange}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text className="bg-white">
              <ImgInput src={PasswordIcon} alt="PasswordIcon" />
            </InputGroup.Text>
            <Form.Control
              type="password"
              name="password"
              value={passwd}
              required
              onChange={handlePasswordChange}
            />
          </InputGroup>

        </Card.Body>
        <Card.Footer>
          <Dropdown
            as={ButtonGroup}
            onSelect={(eventKey) => handleServerChange(eventKey)}
            className="w-100"
          >
            <Button
              variant="success"
              onClick={() => sendAuthRequest({ pwd: passwd, lgn: login })}
              className="flex-grow-1"
              disabled={okDisabled}
              type="submit"
            >
              Увійти
              {loading && (
                <Spinner animation="border" size="sm" className="ml-2" />
              )}
            </Button>
            <Dropdown.Toggle split variant="outline-secondary" className="flex-grow-0">
              <SettingsIcon />
              <span className="mr-1 d-none d-sm-inline">
                {srvName}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '25vh' }} className="overflow-auto">
              {dbOptions.map((vv) => (
                <Dropdown.Item active={vv.value === srv} eventKey={vv.value} key={vv.value}>
                  {vv.text}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {err && (
            <Alert variant="danger">
              {err}
            </Alert>
          )}
        </Card.Footer>
      </Card>
      {/* </Container> */}
    </div>
  );
};
Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default Login;
