import styled from 'styled-components';

const StyledButton = styled.button`
  margin: 1em 0;
  background: linear-gradient(0deg,#ff2828,#990707,#f73030);
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 14px;
  min-width: 4rem;
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin-left: 5px;
  border-radius: 0.5rem;
  border: none;
  &:hover {
    background: linear-gradient(0deg,#9d3232,#c60000,#8e3e3e);
  }
}  
`;

export default StyledButton;
