import styled from 'styled-components';

const BoxMenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: row-wrap;
  margin-top: 40px;
  align-content: flex-start;
  max-width: 100%;
  @-moz-document url-prefix()
  {
    display: inline-block;
    vertical-align: top;
    margin-top: 40px;
    width: 100%;
  }
`;

export default BoxMenuContainer;
