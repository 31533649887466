import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  mode: { label: 'Режим', name: 'Режим', visible: false },
};

const footer = { ...columns };
/**
 * @const
 */
export const budget = {
  label: 'Варіанти титульного листа',
  name: 'titleSheetVariant',
  backendName: 'ВариантыТитульногоЛиста ',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/titleSheetVariant',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: false,
};

export default budget;
