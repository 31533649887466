import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

import { AddFolderIcon } from '../../assets/icons';

const NewFolderButton = ({ text, onClick, ...args }) => (
  <Button onClick={onClick} {...args} title={text}>
    <AddFolderIcon />
  </Button>
);

NewFolderButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

NewFolderButton.defaultProps = {
  text: 'Додати',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default NewFolderButton;
