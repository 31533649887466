import React from 'react';
import PropTypes from 'prop-types';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

const ShowDeletedToggler = ({
  disabled, value, onChange, label,
}) => (
  <DefaultCPButton
    onClick={(e) => onChange(e, !value)}
    label={label}
    disabled={disabled}
    variant={value ? 'outline-info' : 'info'}
    icon={value ? faEye : faEyeSlash}
  />
);

ShowDeletedToggler.propTypes = {
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

ShowDeletedToggler.defaultProps = {
  disabled: false,
  onChange: null,
  label: 'Показувати видаленні',
};

export default ShowDeletedToggler;
