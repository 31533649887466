import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  dateBase: { label: 'Дата підстави', name: 'ДатаОснования', visible: true },
  fullName: { label: 'Повне найменування', name: 'ПолНаименование', visible: true },
  inActive: { label: 'Не враховувати в розрахунках', name: 'флНедействующий', visible: true },
  isFact: { label: 'Це факт', name: 'ЭтоФакт', visible: true },

};

/**
 * @const
 */
export const baseDoc = {
  label: 'Підстава',
  name: 'baseDoc',
  backendName: 'ОснованиеПриказа',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  frontend: 'cat/baseDoc',

  // Remove duplicates
  listColumns: [...new Set([
    ...reqListerColumns,
  ])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  // foldersOnTop: true,
};

export default baseDoc;
