import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  category: { label: 'Категорія', name: 'Категория', visible: true },
  sort: { label: 'Для сортування', name: 'Сортировка', visible: false },
  branches: {
    name: 'Отрасль',
    label: 'Галузь',
    visible: true,
  },
  postClassifier: { label: 'Посада', name: 'КлассификаторДолжностей', visible: true },
  flLimit: { label: 'Обмежити доступні розряди', name: 'флОграничитьДоступныеРазряды', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  flUvisible: { label: 'Не показувати у списку вибору документа', name: 'флНеПоказыватьВСпискеВыбора', visible: false },
  flUseble: { label: 'Обмежити доступні види нарахувань', name: 'флДоступныВсеНачисления', visible: false },
  flNumber: { label: 'Кількість ставок дорівює 1', name: 'флКоличествоСтавокРавноОдин', visible: false },
  flNoHours: { label: 'Заборонити погодинну оплату', name: 'флЗапретитьПочасовку', visible: false },
  flFixed: { label: 'Фіксований оклад', name: 'флФиксированныйОклад', visible: false },
  minimal: { label: 'Мінімальный оклад', name: 'МинимальныйОклад', visible: false },
  maximal: { label: 'Максимальний оклад', name: 'МаксимальныйОклад', visible: false },
  reWrite: { label: 'Розписувати педагогічне навантаження', name: 'РасписыватьПедагогичесскуюНагрузку', visible: false },
  notTeachers: { label: 'Не вчителя', name: 'НеУчителя', visible: false },
  limmitedCounts: { label: 'Обмежити недоступні види нарахувань', name: 'ОграничитьНедосутпныеВидыНачислений', visible: false },
  postNoRounds: { label: 'Посада без округлення', name: 'ДолжностьБезОкругления', visible: false },
  kpCode: { label: 'Код КП', name: 'КодКП', visible: false },
  formReadOnly: { label: 'ЭтаФормаТолькоПросмотр', name: 'ЭтаФормаТолькоПросмотр', visible: false },
  tarifsVisible: { label: 'ЭлементытчДоступныхРазрядовВидимость', name: 'ЭлементытчДоступныхРазрядовВидимость', visible: false },
  addsVisible: { label: 'ЭлементытчНадбавкиВидимость', name: 'ЭлементытчНадбавкиВидимость', visible: false },
  solaryesCloseVisible: { label: 'ЭлементыНедосупныеВидыНачисленийВидимость', name: 'ЭлементыНедосупныеВидыНачисленийВидимость', visible: false },
  grSolaryesVisible: { label: 'ЭлементыгрФиксированныйОкладВидимость', name: 'ЭлементыгрФиксированныйОкладВидимость', visible: false },
  solaryesVisible: { label: 'ЭлементыФиксированныеОкладыВидимость', name: 'ЭлементыФиксированныеОкладыВидимость', visible: false },

};

const tables = {
  tarifs: {
    label: 'Доступні тарифікаційні розряди',
    name: 'тчДоступныхРазрядов',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      tar: { label: 'Тарифний розряд', name: 'Разряд' },
      grp: { label: 'Група оплати праці', name: 'ГруппаОтлатыТруда' },
      tar1: { label: 'Тарифний розряд1', name: 'Разряд1' },
    },
  },
  adds: {
    label: 'Доступні види нарахувань',
    name: 'тчНадбавки',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      calc: { label: 'Види нарахувань', name: 'Начисления' },
    },
  },
  usuals: {
    label: 'Обов\'язкові види розрахунків',
    name: 'тчОбязательныхВР',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      calcType: { label: 'Вид Нарахування', name: 'ВидРасчета' },
      sizeR: { label: 'Розмір', name: 'Размер' },
    },
  },
  solaryes: {
    label: 'Фіксовані оклади',
    name: 'ФиксированныеОклады',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      minS: { label: 'МінімальнийОклад', name: 'МинимальныйОклад' },
      maxS: { label: 'Максимальний оклад', name: 'МаксимальныйОклад' },
      basis: { label: 'Підстава', name: 'Основание' },
    },
  },
  solaryesClose: {
    label: 'Недоступні види нарахувань',
    name: 'НедосупныеВидыНачислений',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      calc: { label: 'Види нарахувань', name: 'Начисления' },
    },
  },
};

const footer = { ...columns };
/**
 * @const
 */
export const post = {
  label: 'Посади',
  name: 'post',
  backendName: 'Должности',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables,
  footer,
  frontend: 'cat/post',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
        'category',
        'postClassifier',
        'note',
        'branches',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default post;
