import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/changeToStaffList';
import useTablePart from '../../../../../newEditor/hook/tablepart';

const tableMeta = md.tables.main;

const MainTP = ({ data, actions, readOnly }) => {
  const FI = data[md.columns.csu.name];
  const tableData = data[tableMeta.name] || [];

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  return (
      <Card className="border-0">
        <TableHeader pinHeader={pinHeader}>
          <TPCommandPanel
              tableActions={tableActions}
              readOnly={readOnly}
              activeRow={activeRow}
              pinHeader={pinHeader}
              showFooter={showFooter}
          />
          <TPHeader
              activeCol={activeCol}
              highlights={highlights}
              onToggleHighlght={tableActions.onToggleHighlightColumn}
          />
        </TableHeader>
        <Card.Body className="p-0" ref={tableContainerRef}>
          {tableData.map((row, index) => (
              <TPRow
                  key={`row-${index}`}
                  row={row}
                  rowIndex={index}
                  active={activeRow === index}
                  activeCol={activeRow === index ? activeCol : null}
                  readOnly={readOnly}
                  highlights={highlights}
                  onChange={tableActions.onCellChange}
                  onSR={actions.onSR}
                  FI={FI}
              />
          ))}
          <Placeholder onFocus={(e) => tableActions.onAddRow(e)} />
        </Card.Body>
      </Card>
  );
};

MainTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.csu.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

MainTP.defaultProps = {
  readOnly: false,
};

export default memo(MainTP);
