import React from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import { signatureVariants as md } from '../../../../constants/meta/catalogs/signatureVariants';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { VariantOfSignatureTP } from './tabs';

const SignatureVariants = ({ data, actions, permissions }) => {
    const readOnly = !permissions.canChange;

  return (
    <Container fluid>
        <Row>
            <Col>
                <EditorControls.ItemPicker
                    label={md.columns.owner.label}
                    value={data[md.columns.owner.name]}
                    modelName="csu"
                    modelType="cat"
                    onChange={(e, value) => actions.onChange({
                        [md.columns.owner.name]: value,
                    })}
                    readOnly={readOnly}
                />
            </Col>
            <Col>
                <EditorControls.StringInput
                    maxLength={150}
                    required
                    label={md.columns.name.label}
                    value={data[md.columns.name.name]}
                    onChange={(e, value) => actions.onChange({
                        [md.columns.name.name]: value,
                    })}
                    readOnly={readOnly}
                />
            </Col>
        </Row>
        <VariantOfSignatureTP data={data} actions={actions} readOnly={readOnly} />
    </Container>
  );
};

SignatureVariants.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SignatureVariants;
