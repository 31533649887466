import assigmentPrinting from './assigmentPrinting';
import docSelection from './docSelection';
import docSelectionUpLevel from './docSelectionUpLevel';
import setWorkDate from './setWorkDate';

const dataPropcessors = {
  assigmentPrinting,
  docSelection,
  docSelectionUpLevel,
  setWorkDate,
};

export default dataPropcessors;
