import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  ffile: { label: 'Файл', name: 'Файл', visible: false },
  ffileName: { label: 'Назва файлу', name: 'ИмяФайла', visible: false },
  incl: { label: 'Увімкнено', name: 'Включен', visible: false },

};

const tables = {
  tp: {
    label: 'ТЧ',
    name: 'ТабличнаяЧасть1',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      ffile: { label: 'Файл', name: 'Файл', visible: false },
      ffileName: { label: 'Назва файлу', name: 'ИмяФайла', visible: false },
      incl: { label: 'Увімкнено', name: 'Включен', visible: false },
    },
  },
};

const footer = { ...columns };
/**
 * @const
 */
export const externalReports = {
  label: 'Зовнішні звіти',
  name: 'externalReports',
  backendName: 'ВнешниеОтчеты',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables,
  footer,
  frontend: 'cat/externalReports',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default externalReports;
