import React from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, Container, Col,
} from 'react-bootstrap';
import md from '../../../../constants/meta/documents/personCard';
import {
  MainTP, GeneralTP, PedLoadTP, PedLoad14TP, PedLoad59TP, PedLoad1011TP,
} from './tabs';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { CommandPanelColor, StyledLabel } from '../../../../components/Form/styledForm';

const PersonCardTabs = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange || !data[md.columns.tableAvialable.name];
  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        <CommandPanelColor
          label="Додати види обрахунку (декілька)"
          disabled={readOnly}
          onClick={() => actions.onSR('ADD_GROUP')}
        />
        <CommandPanelColor
          label="Перерахувати значення показників (за вислугу років)"
          disabled={readOnly}
          onClick={() => actions.onSR('RECOUNT')}
        />
        <GeneralTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      {/* <Tab title={md.tables.pedLoad.label} eventKey="pedLoad"> */}
      {/*      <PedLoadTP data={data} actions={actions} readOnly={readOnly} /> */}
      {/* </Tab> */}
      {(data[md.columns.pages14Visible.name]) && (
        <Tab title={md.tables.pedLoad14.label} eventKey="pedLoad14">
          <PedLoad14TP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
      )}
      {(data[md.columns.pages59Visible.name]) && (
        <Tab title={md.tables.pedLoad59.label} eventKey="pedLoad59">
          <PedLoad59TP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
      )}
      {(data[md.columns.pages1011Visible.name]) && (
        <Tab title={md.tables.pedLoad1011.label} eventKey="pedLoad1011">
          <PedLoad1011TP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
      )}
      <Tab title={md.tables.main.label} eventKey="main">
        <Col>
          <EditorControls.CheckboxInput
            controlId={`flNoRound-${data.id}`}
            label={md.columns.flNoRound.label}
            value={data[md.columns.flNoRound.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.flNoRound.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <MainTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title="Примітка" eventKey="notes">
        <Container fluid className="mx-1 my-2">
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.note.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />
          <EditorControls.TextInput
            label={md.columns.reasons.label}
            value={data[md.columns.reasons.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.reasons.name]: value,
              });
              actions.onSR('REASONS_ON_CHANGE');
            }}
            readOnly={!data[md.columns.reasonsAvailable.name] || readOnly}
            rows={5}
          />
        </Container>
      </Tab>
    </Tabs>
  );
};

PersonCardTabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default PersonCardTabs;
