import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  name: { label: 'Найменування', name: 'Наименование', visible: true },
};

const footer = { ...columns };
/**
 * @const
 */
export const kvkClassifier = {
  label: 'Класифікація відомча (КВК)',
  name: 'kvkClassifier',
  backendName: 'КлассификаторКВК',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/kvkClassifier',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default kvkClassifier;
