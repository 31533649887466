import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  industry: { label: 'Галузь', name: 'Отрасль' },
  csu: { label: 'Розпорядник', name: 'Распорядитель' },
  kbpClassifier: { label: 'КБП', name: 'КБП' },
  endDate: { label: 'станом на:', name: 'НаДату' },
  units: { label: 'Структурні підрозділи', name: 'Подразделения' },

};

const definition = {
  label: 'Звіт по вислузі років',
  name: 'seniority',
  columns,
  frontend: 'rep/seniority',
  backendName: 'ОтчетОВыслугеЛет',
  resultColumns: baseResultColumns,
};

export default definition;
